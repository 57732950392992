<template>
    <section id="hometwo_clinet_area" class="section_padding">
        <div class="container">
            <div class="client_two_area_bg">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="two_client_area_left">
                            <h2>{{$t("thesedeals")}}</h2>
                           <!-- <div class="sm_call_area">
                                <img src="../../assets/img/icon/sm-call.png" alt="icon">
                                <div class="sm_call_number">
                                    <h5>Call now</h5>
                                    <h2><a href="tel:+01-234-567-890">+01 234 567 890</a></h2>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="home_client_wrpper">
                            <h4>{{$t("testimonials")}}</h4>
                            <h2>{{$t("whattheysay")}}</h2>
                            <div class="home-two-quote">
                              <h2>&nbsp;...</h2>  
                                <img src="../../assets/img/icon/quote.png" alt="img">              
                            </div>
                            <div class="home_two_client_slider  owl-theme owl-carousel">
                                <swiper :slides-per-view="1" :pagination="{ clickable: true }">
                                    <swiper-slide>
                                        <div class="client_two_content">
                                            <p>
                                                {{$t("person1")}}
                                            </p>
                                            <div class="client_two_author">
                                                <h5>Jashan H.</h5>
                                               <!-- <h6>Businessman</h6> -->
                                            </div>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide>
                                        <div class="client_two_content">
                                            <p>
                                                {{$t("person2")}}
                                            </p>
                                            <div class="client_two_author">
                                                <h5>G. Gal</h5>
                                              <!-- <h6>Businessman</h6> -->
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <div class="client_two_content">
                                            <p>
                                               {{$t("person3")}}
                                            </p>
                                            <div class="client_two_author">
                                                <h5>Pwarda</h5>
                                              <!-- <h6>Businessman</h6> -->
                                            </div>
                                        </div>
                                    </swiper-slide>

                                    <swiper-slide>
                                        <div class="client_two_content">
                                            <p>
                                                {{$t("person4")}}
                                            </p>
                                            <div class="client_two_author">
                                                <h5>E. Wells</h5>
                                              <!-- <h6>Businessman</h6> -->
                                            </div>
                                        </div>
                                    </swiper-slide>

                                  <swiper-slide>
                                    <div class="client_two_content">
                                      <p>
                                        {{$t("person5")}}
                                      </p>
                                      <div class="client_two_author">
                                        <h5>A. Rodriquez</h5>
                                        <!-- <h6>Businessman</h6> -->
                                      </div>
                                    </div>
                                  </swiper-slide>

                                  <swiper-slide>
                                    <div class="client_two_content">
                                      <p>
                                        {{$t("person6")}}
                                      </p>
                                      <div class="client_two_author">
                                        <h5>Brenda D.</h5>
                                        <!-- <h6>Businessman</h6> -->
                                      </div>
                                    </div>
                                  </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

export default {
    name: "ClientSays",

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiper: null,
        };
    }
};
</script>