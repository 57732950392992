<template>
  <div :class="transition" >
    <div v-if="show" class="cookie-consent" role="dialog" >
      <slot name="message">
        <span class="cookie-consent-message">
          {{ $t('cookietext') }}
          <slot name="link" >
            <a v-bind="{ href, target, rel }" class="cookie-consent-link" role="button">
              <u>{{ $t('cookieLearn') }}</u>
            </a>
          </slot>
        </span>
      </slot>
      <section @click="dismiss">
        <slot name="button">
          <button class="cookie-consent-compliance" >
            {{ $t('cookieGotit') }}
          </button>
        </slot>
      </section>
    </div>
  </div>
</template>

<script>

  import store from '@/store'

  export default {
    name: 'consent',
    props: {
      // default
      transition: {
        type: String,
        default: 'cookie-consent-transition'
      },

      href: {
        type: String,
        default: '/info/privacy'
      },
      target: {
        type: String,
        default: '_self'
      },
      rel: {
        type: String,
        default: 'noopener'
      },
      // cookie
      cookieName: {
        type: String,
        default: 'cookieconsent_status'
      },
      cookiePath: {
        type: String,
        default: '/'
      },
      cookieDomain: {
        type: String,
        default: ''
      },
      cookieExpiryDays: {
        type: Number,
        default: 365
      }
    },
    data() {
      return {
        show: undefined
      }
    },
    computed: {
      cookie() {
        return !store.getters.cookie
      }
    },
    beforeMount() {
      this.show = this.cookie
    },
    methods: {
      dismiss() {
        this.show = false
  //      this.setCookie(this.cookieName, 1, this.cookieExpiryDays, this.cookieDomain, this.cookiePath)
      },
      /*   getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)

        return parts.length !== 2 ?
          undefined :
          parts.pop().split(';').shift()
      },
     setCookie(name, value, expiryDays, domain, path) {
        const exdate = new Date()
        exdate.setDate(exdate.getDate() + (expiryDays || 365))

        const cookie = [
          `${name}=${value}`,
          `expires=${exdate.toUTCString()}`,
          `path=${(path || '/')}`
        ]

        if (domain) {
          cookie.push(`domain=${domain}`)
        }

        document.cookie = cookie.join(';')
      }*/
    }
  }
</script>
  <style > 
  $cookieconstent-padding: 1rem 0 !default;
    $cookieconstent-space: .5rem 1rem !default;
    $cookieconstent-position: fixed !default;
   $cookieconstent-background: #ced4da !default;
    $cookieconstent-z-index: 9999 !default;
    $cookieconstent-width: 100% !default;

    $cookieconstent-compliance-padding: .5rem 2rem !default;
    $cookieconstent-compliance-color: #fff !default;
    $cookieconstent-compliance-background: #237afc !default;
    $cookieconstent-compliance-border: 2px solid currentColor !default;

    .cookie-consent {
      left: 0;
      right: 0;
      bottom: 0;
     }
/*   .cookie-consent-transition {
  &-leave-active {
    transition: transform .75s;
    transition-timing-function: cubic-bezier(.75,0,0,1);
  }
  &-leave-to {
    transform: translateY(100%);
  }
}*/
     .cookie-consent-transition {
     
     /* transform: translateY(100%);*/
 
    } 
  /*   .cookie-consent-transition {
        transition: transform .75s;
        transition-timing-function: cubic-bezier(.75,0,0,1);
      }*/

  .cookie-consent {
    position: fixed !important;
    width: 100% !important;
    bottom: 0 !important;
    z-index: 9999 !important;
    width: 100% !important;
    padding: 1rem 0 !important;
     display: flex; 
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ced4da !important;
  }
  .cookie-consent-message {
        margin: .5rem 1rem !important;
      }

  .cookie-consent-compliance {
        margin: .5rem 1rem !important;
        cursor: pointer;
        color: #fff !important;
        padding:  .5rem 2rem !important;
        background: #74be40 !important;
        border: 2px solid currentColor !important;
      }
    
</style>