<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                      <ul>
                        <li>{{pageinfo}}</li>
                      </ul>
                        <h1 class="honeoverride">{{location}}</h1>
                        <ul>
                            <li>{{$t("tourresult")}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
 </template>
 <script>
 
 import store from '@/store'

 export default {
   name: "TourBanner",
   data() {
     return {
       location : store.getters.tsearchname,
       pageinfo : store.getters.tsearchpagetitle,
     }
   },
   watch: {
     '$store.getters.tsearchname' : function () {
       this.location = (store.getters.tsearchname != null) ? store.getters.tsearchname : this.location
     },
     '$store.getters.tsearchpagetitle' : function () {
       this.pageinfo = (store.getters.tsearchpagetitle != null) ? store.getters.tsearchpagetitle : this.pageinfo
     }
   }
 };
 </script>