<template>
    <section id="top_service_andtour" class="section_padding_top">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{$t("topservice")}}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-1.png" alt="icon">
                        <h3>{{$t("massive")}}</h3>
                        <p>{{$t("massivetext")}}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-2.png" alt="icon">
                        <h3>{{$t("firstclass")}}</h3>
                        <p>{{$t("firstclasstext")}}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-3.png" alt="icon">
                        <h3>{{$t("bestprice")}}</h3>
                        <p>{{$t("bestpricetext")}}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-4.png" alt="icon">
                        <h3>{{$t("noblackout")}}</h3>
                        <p>{{$t("noblackouttext")}}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-5.png" alt="icon">
                        <h3>{{$t("languages")}}</h3>
                        <p>{{$t("languagestext")}}
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-6.png" alt="icon">
                        <h3>{{$t("fullsupport")}}</h3>
                        <p>{{$t("fullsupporttext")}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "TopService"
};
</script>