import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import UrlRequest from './services/requests'
import cookies from "vue-cookies";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Main CSS Add
import './assets/app.css'


export const app = createApp(App)
export const head = createHead()


app.use(head)
app.use(cookies)
app.config.globalProperties.$cookie = app.$cookies
app.use(store)

const urlRequest = UrlRequest.install(app, '')
app.config.globalProperties.$urlRequest = urlRequest
//app.config.globalProperties.$userAgent = navigator.userAgent

//app.use(urlRequest)
app.use(i18n)
app.use(router)
router.isReady().then(() => app.mount('#app'))

//createApp(App).use(cookies).use(store).use(urlRequest).use(i18n).use(router).mount('#app');