<template>
    <section id="theme_search_form">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="theme_search_form_tabbtn">
                            <ul class="nav nav-tabs" role="tablist">
                             <!--   <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i class="fas fa-plane-departure"></i>Flights</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                            class="fas fa-globe"></i>Tours</button>
                                </li> -->
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="hotels-tab" data-bs-toggle="tab"
                                        data-bs-target="#hotels" type="button" role="tab" aria-controls="hotels"
                                        aria-selected="false"><i class="fas fa-hotel"></i>{{$t("hotels")}}</button>
                                </li>
                            </ul>
                        </div>

                        <div class="tab-content" id="myTabContent">
                       
                      
                            <div class="tab-pane show active" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form v-on:submit.prevent="beginSearch($event)"> 
                                                <div class="row">
                                                    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                                                      
                                                        <div class="flight_Search_boxed">
                                                            <p>{{$t("wheretogo")}}</p>
                                                            <input type="text"  v-on:input="delaySearch" :placeholder="$t('startsearch')" v-model="dest">
                                                           <!-- <span>Where would you like to go?</span> -->

                                                          <ul class="dropdown-autosearch"  v-show="isOpen">
                                                            <li v-for="(name, i) in data" :key="i" @click="select(name.name, name.id, name.lat, name.long)" >
                                                              <a class="dropdown-item">{{name.name}}</a>
                                                            </li>
                                                            
                                                          </ul>

                                                        </div>
                                                      <span class="error_red" v-show="isErrLoc">{{$t("errorLocation")}}</span>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"> <!--https://innologica.github.io/vue2-daterange-picker/#usage-->
                                                        <div class="flight_Search_boxed">
                                                        <!--  <div class="flight_Search_boxed date_flex_area"> -->
                                                            <div class="room_details_facilities">
                                                            <span class="Journey_date ">
                                                              <p>{{$t("checkin")}}&nbsp;&nbsp;-&nbsp;&nbsp;{{$t("checkout")}}</p>

                                                              <!--   <input type="date"  onclick="this.showPicker()" v-model="indate">  -->                                                                <!-- <span>Thursday</span> -->
                                                            </span>
                                                            <span class="Journey_date ">
                                                              <p>{{$t("resetDate")}}</p>
                                                              <!--   <input type="date"  onclick="this.showPicker()" v-model="outdate" ref="outButton">
      <span>Thursday</span> -->
                                                            </span>
                                                              </div>
                                                            <div>
                                                              <VueDatePicker onmousedown="return false" onselectstart="return false" v-model="date"
                                                                             :teleport-center="pickerPos"
                                                                             range
                                                                             multi-calendars
                                                                             auto-apply
                                                                             prevent-min-max-navigation
                                                                             hide-input-icon
                                                                             :enable-time-picker="false"
                                                                             format="MM/dd/yyyy"
                                                                             :min-date="getDate(-1)" /> 
                                                             </div>

                                                          
                                                              </div>
                                                         
                                                      <span class="error_red" v-show="isErrDate">{{$t("errorDate")}}</span>
                                                    </div>
                                                    <CountDropdownHotel ref="countDropdown"/>
                                                    <div class="top_form_search_button">
                                                        <button :class="['btn', 'btn_theme', 'btn_md', {inbtnpause : isButtonPressed}]"> {{$t("search")}} </button>
                                                    </div>
                                                    <p style="text-align:center" v-show="promo"> {{$t("redeempromo")}}</p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

//import CountDropdown from '@/components/home/CountDropdown.vue'
//import CountDropdownRound from '@/components/home/CountDropdownRound.vue'
//import CountDropdownMulti from '@/components/home/CountDropdownMulti.vue'
  //import CountDropdownTour from '@/components/home/CountDropdownTour.vue'

import CountDropdownHotel from '@/components/home/CountDropdownHotel.vue'
import store from '@/store'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
 
 
 
export default {
    name: "Form",

    components: {
      //      CountDropdown, CountDropdownRound, CountDropdownMulti, CountDropdownTour, 
      CountDropdownHotel,
      VueDatePicker
    },   
    data() { 
      var ind = new Date(); // https://vue3datepicker.com/installation/
      ind.setHours(0,0,0,0); // make it midnight local time...
      var outd = new Date(new Date().setDate(ind.getDate() + 1));
      return {
        promo : (store.getters.promo != null && store.getters.promo.length>0),
        indate: this.getDate(0),
        outdate: this.getDate(1),
        date: [ind, outd], 
        ttlValue : 0,
        ttlLocal : 0,
        fetchTimeout : null, 
        lettersType : 0,
        destSelected : 0,
        pickerPos: false,
        dest: '',
        destLat: '',
        destLong: '',
        setDest: '',
        destId : '',
        data :[], 
        errors : [],
        isButtonPressed : false,
        isOpen: false,
        isErrLoc: false,
        isErrDate: false, 
       // inDatePrev : new Date().toISOString().substr(0,10),
        rowData: [{ name: '' }]
      };
    },
 
  watch: {
    '$store.getters.promo' : function () {
      this.promo = (store.getters.promo != null && store.getters.promo.length>0)
    },
    'date' : function() {
      console.log(this.date)
      if (this.date == null)
      {
        this.indate = this.getDate(0);
        this.outdate = this.getDate(1);

        var today = new Date()
        today.setHours(0,0,0,0);
        this.date = [today , new Date(new Date().setDate(today.getDate() + 1))]
      }
      else
      {
        var d = new Date(this.date[0].getTime() - this.date[0].getTimezoneOffset()*60000);
        this.indate = d.toISOString().substr(0,10)
        d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
        this.outdate = d.toISOString().substr(0,10)

        if(this.indate == this.outdate)
        {
          this.date[1] = new Date(this.date[1].setDate(this.date[1].getDate() + 1));
          d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
          this.outdate = d.toISOString().substr(0,10)
        }
      }
      
      console.log("Dates ----")
      console.log(this.indate)
      console.log(this.outdate)
    },
  /*   'indate' : function () {
        
      let inDate = new Date(this.indate).getTime()
      let outDate = new Date(this.outdate).getTime()
      console.log("TESTING " + outDate + " " + inDate)
      if (outDate <= inDate)
      {
        let inDateStamp = new Date(this.indate)
        inDateStamp.setDate(inDateStamp.getDate() + 1)
        this.outdate = inDateStamp.toISOString().substr(0,10)
      }
      else if ((outDate > 4294967295 && outDate  > (inDate + 86400000*28)) ||
                (outDate < 4294967295 && outDate > (86400*28 + inDate)))
      {
        let inDateStamp = new Date(this.indate)
        inDateStamp.setDate(inDateStamp.getDate() + 28)
        this.outdate = inDateStamp.toISOString().substr(0,10)
      }
      console.log("IN DATE ========= " + this.indate)
      var newDate = this.indate.split('-')
      var oldDate = this.inDatePrev.split('-')
      if (newDate[2] != oldDate[2])
      {
        this.inDatePrev = this.indate
        const elem = this.$refs.outButton
        elem.click()
      }
      
     
    },*/
     // I want to wait to make sure that the header gets all the info it needs before we continue...
    '$store.getters.loginStatus' : function () {
      console.log("LOGIN STATUS " + store.getters.loginStatus)
      console.log("LOGIN PROMO " + store.getters.promo)
      console.log("LOGIN TYPE " + this.$route.query.type)
      if ((store.getters.loginStatus == 'in' || (store.getters.promo!=null && store.getters.promo.length>0)) && this.$route.query.type) // if we are logged in
      { // search = (search != null) ? atob(search) : '';
          var type = this.$route.query.type
          this.destId = this.$route.query[type]  // get property or city ID

          if (type == 'city')  // if we are a city 
          {
            var todayStr = this.$route.query.checkIn // get start date
            console.log("TODAY STRING" + todayStr)
            var today = new Date(todayStr).getTime()

            todayStr = new Date(today).toISOString().substr(0,10) 
            if (today > 4294967295) today += 86400000 * this.$route.query.nights
            else today += 86400 * this.$route.query.nights
            let outDate = new Date(today).toISOString().substr(0,10)

            console.log("other values " +this.destId+outDate)
            this.setDest = 'Direct Search'
            this.destLat = ''
            this.destLong = ''
            this.indate = todayStr
            this.outdate = outDate
            this.startSearchPush()

          }
          else if (type == 'property')// we should be property
          {
            //var path = { k : value,
            //  s : this.$route.query.s,
            //  i : this.$route.query.i,
            //  q : this.$route.query.o
           // }
           // this.$router.push({ path: '/hotel-details', query : path })
            console.log("PROPERTY " + this.$route.query.property)
            console.log("S " + this.$route.query.s)
            console.log("I " + this.$route.query.i)
            console.log("O " + this.$route.query.o)
            var path = '/hotel-details?k=' + this.$route.query.property
            path += '&s=' + this.$route.query.s
            path += '&i=' + this.$route.query.i
            path += '&o=' + this.$route.query.o

            console.log( path)

            window.location.href =  path
          }
        } // endif change in login status
      }
    },
  
  methods: { 
 
 
    getDate: function(adjDate) {
      var d = new Date() // set local time
      d.setHours(0,0,0,0); // make it midnight...
      if (adjDate != 0) d.setDate(d.getDate() + adjDate)

      var newd = new Date(d.getTime() - d.getTimezoneOffset()*60000);
      var outdate = newd.toISOString().substr(0,10)
      return outdate
    }, 
 
    beginSearch(event) //https://stackoverflow.com/questions/42511311/vuejs-on-input-run-a-function-but-with-a-delay
    {
   
      var todayStr = new Date().toISOString().substr(0,10)
      var today = new Date(todayStr).getTime()
      if (today > 4294967295) today -= 86400000
      else today -= 86400 
      let inDate = new Date(this.indate).getTime()
      let outDate = new Date(this.outdate).getTime()
 
      this.isErrLoc = (this.destId.length == 0) ? true : false
      this.isErrDate = (inDate >= today && outDate > inDate) ? false : true

      

      if (this.isErrLoc == false && this.isErrDate == false)
      {
        event.target.disabled = true
        this.isButtonPressed = true
        this.startSearchPush()
      }
 
    },
    startSearchPush()
    {
      console.log(this.indate)
      console.log(this.outdate)
      this.$refs.countDropdown.savedata() // save the adults/children/rooms
      store.dispatch('UPDATE_USER', {
        searchname: this.setDest,
        searchlat: this.destLat,
        searchlong: this.destLong,
        searchid: this.destId,
        searchin: this.indate,
        searchout: this.outdate,
        hotdest : '',  // this is for hotels list to details page...
        sortby : 'S',
        stars : [false,false,false,false,false],
        rating : [false,false,false,false,false],
        ammen :{'fb':false,'sw':false,'fw':false,'fp':false,'pa':false,'ns':false,'as':false,'fc':false,'ac':false,'re':false,'ki':false}
      })
      this.$urlRequest.cookies()
      this.$router.push({ path: '/hotel-search' })
    },

    select(name, id, lat, long)
    {
      console.log("what is ID " + id)
      this.dest = name
      this.destLat = lat
      this.destLong = long
      this.setDest = name
      this.destId = id
      this.isOpen = false
      this.destSelected = this.lettersType
    },

    delaySearch()
    {
      this.lettersType ++
      
     // console.log("START TYPEING")
      let newDate = Date.now()
      let diffDate = newDate - this.ttlLocal
      if ((newDate > 4294967295 && diffDate < 600000) || (newDate <= 4294967295 && diffDate < 600))
      {
      //  console.log("CLEAR SEARCH")
        clearTimeout(this.fetchTimeout)
      }
      this.ttlLocal = newDate
     // console.log("BEGIN TIMEOUT")
      this.fetchTimeout = setTimeout(() => {
       console.log("--------------------->START SEARCH")
        this.fetchSearch() // method to call when user is done typing
      },600)

    },

    fetchSearch()
    {
      let arr = {'text' : this.dest};
      arr['type'] = 'hotels';   

      if (this.dest.length > 2)
      {
     
        this.$urlRequest.post('fetch', 'search', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            if (this.ttlValue <= response.ttl)
            {
              this.data = response.data
              this.ttlValue = response.ttl
            }
            if (response.data.length > 0 && this.lettersType > this.destSelected)
              this.isOpen = true

            if (response.priceAccess != "fsg" || response.accountType != "fsg")
            {
              store.dispatch('UPDATE_USER', {
                permissions: response.accountType,
                priceAccess: response.priceAccess,
              })
            }

            console.log(this.data)
            // this.$router.push('/login')
          })
          .catch((error) => {
            //this.loading = false
            // this.$nprogress.done()
            //this.$notification['warning']({
            // message: error.code,
            //  description: error.message
            //})
          })
      }
      else
        this.isOpen=false
    },
   /*     addRow: function () {

            let a = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

            if (document.querySelectorAll('.multi_city_form').length === 5) {
                alert("Max Citry Limit Reached!!")
                return;
            }

            this.isHidden = true

            this.rowData.push({ name: '' })
        },
        deleteRow(index) {
            this.rowData.splice(index, 1);
        }*/

    },
    mounted() {

      //this.items = data.hotelData
      let size = window.innerWidth 
      this.mapHeight = window.innerHeight
      this.pickerPos = (size >= 992) ? false : true

      window.addEventListener('resize', () => {
        let size = window.innerWidth 
        this.mapHeight = window.innerHeight
        this.pickerPos = (size >= 992) ? false : true
      })
    },
};
</script>