<template>
   <div  v-if="active"> <!-- class="alert"-->
    <div class="alert-content"><div  v-html="data"></div>
     </div> 
  </div> 
</template>

<script>

  export default {
  name: 'Alert',
  props: {
    active : Boolean,
    data: String
  },
  watch: {
    'active' : function() {
      if (this.active == true)
      {
        window.setTimeout(() => {
          this.$emit('closing', false)
        }, 4000) 
      }  // endif active
    } // endif watch function
  } // end watch

}
</script>

<style scoped>
   /* The Modal (background) */


/* Modal Content/Box */
.alert-content {
  text-align : center;
  color : #fff;
  background: #74be40 !important;
 /* margin: 15% auto;  15% from the top and centered */
  padding: 20px 0px 20px 0px;
/*  border: 1px solid #888;*/
   position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 9999 !important;
}
 
</style>