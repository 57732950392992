<template>
  <div v-if="active" class="loader-wrapper">
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p v-if="message" >{{ displayText }}</p>
  </div>
</template>
<script>

  const texts = [
    "spinnermsg",
    "spinnermsg2",
    "spinnermsg3",
    "spinnermsg4",
    "spinnermsg5",
  ]

export default {
  name: 'Loader',
  props: {
    active: Boolean,
    message : {
      type: Boolean,
      required: false,
      default: false 
    }
    //message: String
  },
  data () {
    return {
      texts,
      interval: null,
      textIndex: 0,
      displayText: '',
    }//this.$t("spinnermsg")
  },
  created() {
    console.log("WHAT IS " + this.message)
    this.interval = setInterval(() => this.changeText(), 5200);
  },
  methods: {
    changeText()
    {
        this.textIndex ++;
        if (this.textIndex >= this.texts.length)
          this.textIndex = 0;
        this.displayText = this.$t(this.texts[this.textIndex])
    },
  },
  beforeDestroy() {
    clearInterval()
  },
}
</script>
<style scoped>
  p {
    font-size: 1.1em;
    font-weight: 300;
    margin-top: 5px;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
  }

  .loader-wrapper {
    
    z-index: 99999999999;
    position:fixed;
    padding:0;
    margin:0;
    display:inline-block;

    text-align: center;
    top:0;
    left:0;

    width: 100%;
    height: 100%;
    background-color: rgba(80, 80, 80, .6)
  }

  .loader {
    display: inline-block;
    position: relative;
    align-items: center;
    margin: 40px;
    width: 80px;
    height: 80px;
  }

    .loader div {
 
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #74be40;
      border-radius: 50%;
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #74be40 transparent transparent transparent;
    }

      .loader div:nth-child(1) {
        animation-delay: -0.45s;
      }

      .loader div:nth-child(2) {
        animation-delay: -0.3s;
      }

      .loader div:nth-child(3) {
        animation-delay: -0.15s;
      }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
