<!--onmousedown="return false" onselectstart="return false"-->
<template>
    <section id="explore_area" class="section_padding">
      <Loader :active="loaderActive" message/>
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{items.length}} {{$t("hotelsfound")}}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-12">
                    <div class="left_side_search_area">
                      <div class="left_side_search_heading">
                        <h5>{{$t("dateOcc")}}<a class="apply" @click="changeDate()" href="#">{{$t("change")}}</a></h5>
                      </div>
                      <div class="left_side_search_boxed">
                        
                        <div class="tour_search_type" >
                     
                          <div class="flight_Search_boxed">
                   
                            <input type="text" v-on:input="delaySearch" :placeholder="searchName" v-model="dest">
                            <!-- <span>Where would you like to go?</span> -->
                            <ul class="dropdown-autosearch" v-show="isOpen">
                              <li v-for="(name, i) in data" :key="i" @click="select(name.name, name.id, name.lat, name.long)">
                                <a class="dropdown-item">{{name.name}}</a>
                              </li>

                            </ul>

                          </div>
                          <div class="room_details_facilities"><span>{{$t("checkin")}}&nbsp;&nbsp;-&nbsp;&nbsp;{{$t("checkout")}}</span><span> </span></div>
                          <div>
                            <VueDatePicker onmousedown="return false" onselectstart="return false" v-model="date"
                                           :teleport-center="pickerPos"
                                           range
                                           multi-calendars
                                           auto-apply
                                           prevent-min-max-navigation
                                           hide-input-icon
                                           :enable-time-picker="false"
                                           format="MM/dd/yyyy"
                                           :min-date="getDate(-1)" />
                          </div>
                       <!--   <input type="date" onclick="this.showPicker()" v-model="checkin">><br />


                            <input type="date" onclick="this.showPicker()" v-model="checkout" ref="outButton"><br /> -->

                            <CountDropdownHotel ref="countDropdown" />
                            <p v-if="isErrDate" class="error_red">{{$t("errorDate")}}</p>
                            <p v-else>{{$t("occMsg")}}</p>

                          </div>
                      </div>

               

                      <div class="left_side_search_heading">
                        <h5 class="accordion-header">
                          <span v-show="!showMap" class="accordian-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">{{$t("sortby")}} ^</span>
                          <span v-show="showMap">{{$t("sortby")}}</span>
                          <a class="apply" @click="changeSort()" href="#">{{$t("apply")}}</a>
                        </h5>
                      </div>
                      <div id="collapseOne" class="accordion-collapse collapse left_side_search_boxed show">

                        <select v-model="sortby" class="dropdown-list">
                          <option value="S" selected>{{$t("largesaving")}}</option>
                          <option value="P">{{$t("Lowprice")}}</option>
                          <option value="A">{{$t("alpha")}}</option>
                          <option value="D">{{$t("dist")}}</option> 
                        </select>
                         
                        <br />
                        <input type="text" :placeholder="$t('hotelName')" v-model="hotdest" />
                      </div>
                     
                       <br />
                      <div class="left_side_search_heading">
                          <h5 class="accordion-header">
                            <span v-show="!showMap" class="accordian-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">{{$t("filtStars")}} ^</span>
                            <span v-show="showMap">{{$t("filtStars")}}</span>
                          <a class="apply" @click="changeSort()" href="#">{{$t("apply")}}</a></h5>
                      </div>
                        <div id="collapseTwo" class="accordion-collapse collapse left_side_search_boxed">
                          
                          <div class="filter_review">
                            <form class="review_star">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="stars[4]" id="flexCheckDefaulta">
                                <label class="form-check-label" for="flexCheckDefaulta">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="stars[3]"
                                       id="flexCheckDefaulf21">
                                <label class="form-check-label" for="flexCheckDefaulf21">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="stars[2]"
                                       id="flexCheckDefaultf3">
                                <label class="form-check-label" for="flexCheckDefaultf3">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="stars[1]"
                                       id="flexCheckDefaultf4">
                                <label class="form-check-label" for="flexCheckDefaultf4">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="stars[0]"
                                       id="flexCheckDefaultf5">
                                <label class="form-check-label" for="flexCheckDefaultf5">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                            </form>
                          </div>

                        </div>
                      <br />
                      <div class="left_side_search_heading">
                        <h5 class="accordion-header">
                          <span v-show="!showMap" class="accordian-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">{{$t("filtRate")}} ^</span>
                          <span v-show="showMap">{{$t("filtRate")}}</span>
                        
                        <a class="apply" @click="changeSort()" href="#">{{$t("apply")}}</a></h5>
                      </div>
                        <div id="collapseThree" class="accordion-collapse collapse left_side_search_boxed">
                          
                          <div class="filter_review">
                            <form class="review_star">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="rating[4]" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="rating[3]" id="flexCheckDefault1">
                                <label class="form-check-label" for="flexCheckDefault1">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="rating[2]" id="flexCheckDefault2">
                                <label class="form-check-label" for="flexCheckDefault2">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="rating[1]" id="flexCheckDefault3">
                                <label class="form-check-label" for="flexCheckDefault3">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" v-model="rating[0]" id="flexCheckDefault5">
                                <label class="form-check-label" for="flexCheckDefault5">
                                  <i class="fas fa-star color_theme"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                  <i class="fas fa-star color_asse"></i>
                                </label>
                              </div>
                            </form>
                          </div>


                        </div>
                      <br />
                      <div class="left_side_search_heading">
                        <h5 class="accordion-header">
                          <span v-show="!showMap" class="accordian-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">{{$t("amenities")}} ^</span>
                          <span v-show="showMap">{{$t("amenities")}}</span>
                        <a class="apply" @click="changeSort()" href="#">{{$t("apply")}}</a></h5>
                      </div>
                        <div id="collapseFour" class="accordion-collapse collapse left_side_search_boxed">
                          
                          <div class="tour_search_type">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['fb']" id="flexCheckDefaultf1">
                              <label class="form-check-label" for="flexCheckDefaultf1">
                                <span class="area_flex_one">
                                  <span>{{$t("freeBreak")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['sw']" id="flexCheckDefaultf2">
                              <label class="form-check-label" for="flexCheckDefaultf2">
                                <span class="area_flex_one">
                                  <span>{{$t("pool")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['fw']" id="flexCheckDefaultaf3">
                              <label class="form-check-label" for="flexCheckDefaultaf3">
                                <span class="area_flex_one">
                                  <span>{{$t("freeWifi")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['fp']" id="flexCheckDefaultaf4">
                              <label class="form-check-label" for="flexCheckDefaultaf4">
                                <span class="area_flex_one">
                                  <span>{{$t("FreePark")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['pa']" id="flexCheckDefaultaf5">
                              <label class="form-check-label" for="flexCheckDefaultaf5">
                                <span class="area_flex_one">
                                  <span>{{$t("pets")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['ns']" id="flexCheckDefaultaf6">
                              <label class="form-check-label" for="flexCheckDefaultaf6">
                                <span class="area_flex_one">
                                  <span>{{$t("nonSmoke")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['as']" id="flexCheckDefaultaf7">
                              <label class="form-check-label" for="flexCheckDefaultaf7">
                                <span class="area_flex_one">
                                  <span>{{$t("shuttle")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['fc']" id="flexCheckDefaultaf8">
                              <label class="form-check-label" for="flexCheckDefaultaf8">
                                <span class="area_flex_one">
                                  <span>{{$t("fitness")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['ac']" id="flexCheckDefaultaf9">
                              <label class="form-check-label" for="flexCheckDefaultaf9">
                                <span class="area_flex_one">
                                  <span>{{$t("access")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['re']" id="flexCheckDefaultaf10">
                              <label class="form-check-label" for="flexCheckDefaultaf10">
                                <span class="area_flex_one">
                                  <span>{{$t("foodOnsite")}}</span>

                                </span>
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" v-model="ammen['ki']" id="flexCheckDefaultaf11">
                              <label class="form-check-label" for="flexCheckDefaultaf11">
                                <span class="area_flex_one">
                                  <span>{{$t("kitchen")}}</span>

                                </span>
                              </label>
                            </div>
                          </div>


                        </div>
                        <br id="topmap" />
                      </div>
                </div>
              <!-- "col-lg-9">  class="col-lg-4 col-md-6 col-sm-6 col-12">  numofitems -->
              <div v-show="(!isFullMap || showMap) && isNoHotels!=null" class="col-lg-4 col-md-12 col-sm-12 col-12">
                <h4 class="error_red">{{$t("nohotels")}}</h4><p>{{isNoHotels}}</p>
              </div>
              <div v-show="(!isFullMap || showMap) && isNoHotels==null" class="col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="row">
                  <div v-for='(hotelInfo, index) in items' v-bind:id="hotelInfo.hotelId" v-bind:key="index" class="col-lg-12 col-md-6 col-sm-6 col-12">
                    <div :class="['theme_common_box_two',{inbtnpause : hotelInfo.pausedFor5seconds}]">
                      <div class="theme_two_box_img  img_hover" v-on:click="mouseOver(hotelInfo.hotelId,hotelInfo.latitude,hotelInfo.longitude)">
                        <!--<a v-bind:href="passURIRoute(hotelInfo.rates,hotelInfo.hotelId)" > @mouseover="mouseOver(hotelInfo.hotelId,hotelInfo.latitude,hotelInfo.longitude)" @mouseleave="mouseLeave(hotelInfo.hotelId)"   v-on:click="PauseButton(index, $event)" v-bind:target="urlDirct(hotelInfo.rates)"-->
                          <img v-bind:src="`${hotelInfo.thumbnail}`"
                                alt="Hotel Image" />  
                       <!-- </a> -->

                        <p><i class="fas fa-map-marker-alt"></i>{{ hotelInfo.address }}</p>
                      </div>
                      <div class="theme_two_box_content">
                        <p v-html="getRankStars(hotelInfo.stars)"></p>
                        <h4>
                          <!--<a v-bind:href="passURIRoute(hotelInfo.rates,hotelInfo.hotelId)" v-on:click="PauseButton(index, $event)" v-bind:target="urlDirct(hotelInfo.rates)">-->{{ hotelInfo.name }}<!--</a>-->
                        </h4> <!--<img :src="`${hotelInfo.rateImageURL}`" alt="Tripadvisor" />-->
                        <p v-if="(hotelInfo.userCount!=0)">
                          <span class="circle_size" v-html="getRankCircle(hotelInfo.userRate)" v-if="(hotelInfo.rateImageURL!=null)"></span><span class="review_rating">{{ hotelInfo.userRate }} / 5</span> <span class="review_count">({{ hotelInfo.userCount }} {{$t("reviews")}})</span>
                        </p>
                        <p class="price_align_justify" v-if="(hotelInfo.discount!=0)">
                          <span class="discount" v-bind:key="currpermission">{{displayMemberText()}} {{info.displayCurrency}}{{hotelInfo.discount}}</span>
                          <span class="retail_price" v-if="(hotelInfo.retailPrice!=0)"> {{info.displayCurrency}}{{hotelInfo.retailPrice}}</span>
                        </p>
                        <p class="price_align" v-on:click="PauseButton(index, $event)" v-html="showPrice(hotelInfo.rates, index)"></p>

                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-12">
            <div class="pagination_area">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                        </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div> -->
                </div>
              </div>
              <div class="col-lg-5 col-11" v-show="showMap || isFullMap" id="botmap">
                <div class="map-sticky" v-bind:style="imageStyle" > <!-- v-bind:key="mapChange"  -->
                  <div class="right_side_search_boxed" v-on:click="fetchLatLong"><i class="fa fa-map-marker"></i>&nbsp; {{$t('mapSearch')}} &nbsp;<i class="fa fa-map-marker"></i></div>
                  <!--<iframe class="map-sticky" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"></iframe> -->
                  <MapContainer ref="mapContainer" v-bind:key="mapChange"></MapContainer>
                </div>

              </div>
              <div class="col-1" id="tileBackground" v-show="showMap || isFullMap">
              </div>
            </div>
          
        </div>
    </section>
</template>
<script>
//import Slider from '@vueform/slider'
//import data from '../../data'
import store from '@/store'
import Loader from '@/components/Loader.vue'
import CountDropdownHotel from '@/components/hotel/CountDropdownHotel.vue'
import MapContainer from '@/components/MapContainer.vue'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: "SearchResult",
    components: {
  //    Slider,
      Loader, 
      CountDropdownHotel,
      MapContainer,
      VueDatePicker
    },
    data() {
  
        console.log("date " +store.getters.searchin + " " + store.getters.searchout)
        var ind = new Date(store.getters.searchin + " 00:00:00"); //https://vue3datepicker.com/installation/
        var outd = new Date(store.getters.searchout + " 00:00:00");
        console.log(ind)
        console.log(outd)
    
      return {
        isErrDate : false,
        isFirstOnPage : true,
        isSortByDist : false,
        date: [ind, outd],
        ttlValue : 0,
        ttlLocal : 0,
        fetchTimeout : null,
        searchName : store.getters.searchname,
        searchLat : store.getters.searchlat,
        searchLong : store.getters.searchlong,
        isOpen : false,
        lettersType : 0,
        destSelected : 0,
        dest: '',
        setDest: '',
        destId : '',
        data :[],
        currpermission : store.getters.permissions,
        isNotApp : store.getters.isNotApp,
        showMap : true,
        isNoHotels : null,
        isFullMap : false,
        mapHeight : 50,
        mapChange : 0,
        elementChange: 0,
        numofItems : 0,
        roomKey : null,
        loaderActive: false,
        checkin: store.getters.searchin,
        checkout: store.getters.searchout, 
        // filter stuff
        hotdest: store.getters.hotdest,    
        sortby : store.getters.sortby,
        stars : store.getters.stars,
        rating : store.getters.rating,
        ammen :store.getters.ammen,
        inDatePrev : new Date().toISOString().substr(0,10),
     /*   value: [0, 75], */
        info: {
         /* 'city' : store.getters.city,
          'cityName' : store.getters.cityName,
          'userCurrency' : store.getters.userCurrency,
          'userLanguage' : store.getters.userLanguage,*/
          'displayCurrency' : store.getters.displayCurrency
              },
         items: [],
         filterItems: [],
         restoreArray : {},
         pickerPos: false,
         
        }
    },
  computed: {
    imageStyle() {
      return {
        height: (this.mapHeight-73) + "px"
      }
    }
  },
  watch: {
    '$store.getters.isFullMap' : function() {
      this.isFullMap = store.getters.isFullMap
    },
    '$store.getters.isNotApp' : function () {
      this.isNotApp = store.getters.isNotApp
    },
    '$store.getters.permissions' : function () {
     
      if (store.getters.permissions == 'pro' && this.currpermission != 'pro')
      {
        console.log("did we get in")
        this.currpermission = 'pro'
        this.changeDate()
      }
    },
     
    'date' : function() {
      console.log("NOW WE RUN THE DATE")
      console.log(this.date)
      if (this.date == null)
      {
        this.checkin = store.getters.searchin;
        this.checkout = store.getters.searchout;

        this.date = [new Date(store.getters.searchin + " 00:00:00") , new Date(store.getters.searchout + " 00:00:00")]
      }
      else
      {
        var d = new Date(this.date[0].getTime() - this.date[0].getTimezoneOffset()*60000);
        this.checkin = d.toISOString().substr(0,10)
        d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
        this.checkout = d.toISOString().substr(0,10)

        if(this.checkin == this.checkout)
        {
          this.date[1] = new Date(this.date[1].setDate(this.date[1].getDate() + 1));
          d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
          this.checkout = d.toISOString().substr(0,10)
        }
      }
      console.log("Dates ----")
      console.log(this.checkin)
      console.log(this.checkout)
    },
 /*   'checkin' : function () {
        
      let inDate = new Date(this.checkin).getTime()
      let outDate = new Date(this.checkout).getTime()
      console.log("TESTING " + outDate + " " + inDate)
      if (outDate <= inDate)
      {
        let inDateStamp = new Date(this.checkin)
        inDateStamp.setDate(inDateStamp.getDate() + 1)
        this.checkout = inDateStamp.toISOString().substr(0,10)
      }
      else if ((outDate > 4294967295 && outDate  > (inDate + 86400000*28)) ||
                (outDate < 4294967295 && outDate > (86400*28 + inDate)))
      {
        let inDateStamp = new Date(this.checkin)
        inDateStamp.setDate(inDateStamp.getDate() + 28)
        this.checkout = inDateStamp.toISOString().substr(0,10)
      }
      console.log("IN DATE ========= " + this.checkin)
      var newDate = this.checkin.split('-')
      var oldDate = this.inDatePrev.split('-')
      if (newDate[2] != oldDate[2])
      {
        this.inDatePrev = this.checkin
        const elem = this.$refs.outButton
        elem.click()
      }
    },*/
    /*,
    watch: {
      '$store.getters.searchin' : function() {
        this.checkin = store.getters.searchin
      },
    '$store.getters.searchout' : function() {
      this.checkout = store.getters.searchout
    }
    },*/
  },
  methods: {
    getDate: function(adjDate) {
      var d = new Date()
      d.setHours(0,0,0,0); // make it midnight...
      if (adjDate != 0) d.setDate(d.getDate() + adjDate)

      var newd = new Date(d.getTime() - d.getTimezoneOffset()*60000);
      var outdate = newd.toISOString().substr(0,10)
      return outdate
    },
    select(name, id, lat, long)
    {
      console.log("what is ID " + id + " " + name + lat + long)
      this.dest = name
      this.setDest = name
      this.searchLat = lat
      this.searchLong = long
      this.destId = id
      this.isOpen = false
      this.destSelected = this.lettersType
    },
    fetchLatLong()
    {
      // get current lat long from map
      var longLat = this.$refs.mapContainer.getCurrentLongLat()

      let arr = {'long' : longLat[0], 'lat' : longLat[1]};
      arr['type'] = 'hotels';

      this.$urlRequest.post('fetch', 'search', arr)    // {'text' : this.dest, 'type' : 'hotels'}
        .then((response) => {

          console.log(response)
          //this.data = response.data
          if (response.data.length > 0 )
          {
            // first select the new location
            this.select(response.data[0].name, response.data[0].id,response.data[0].lat,response.data[0].long)
            // then fetch it...
            this.changeDate()
          }
           // this.isOpen = true

         // console.log(this.data)
  
        })
        .catch((error) => {
          
        })

    },
    delaySearch()
    {
      this.lettersType ++
      
      // console.log("START TYPEING")
      let newDate = Date.now()
      let diffDate = newDate - this.ttlLocal
      if ((newDate > 4294967295 && diffDate < 600000) || (newDate <= 4294967295 && diffDate < 600))
      {
        //  console.log("CLEAR SEARCH")
        clearTimeout(this.fetchTimeout)
      }
      this.ttlLocal = newDate
     // console.log("BEGIN TIMEOUT")
      this.fetchTimeout = setTimeout(() => {
           console.log("--------------------->START SEARCH")
        this.fetchSearch() // method to call when user is done typing
      },600)

    },
    fetchSearch()
    {
      let arr = {'text' : this.dest};
      arr['type'] = 'hotels';

      this.lettersType ++

      if (this.dest.length > 2)
      {
     
        this.$urlRequest.post('fetch', 'search', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
  
            if (this.ttlValue <= response.ttl)
            {
              this.data = response.data
              this.ttlValue = response.ttl
            }
            if (response.data.length > 0 && this.lettersType > this.destSelected)
              this.isOpen = true

            console.log(this.data)
  
          })
          .catch((error) => {

          })
      }
      else
        this.isOpen=false
    },
    displayMemberText()
    {
     // console.log("what is permission " + store.getters.permissions)
      var str = (store.getters.permissions == 'pro') ? this.$t("totalsavings") : this.$t("nonmembersavings")
      return str
    },
    mouseOver(key, lat, long) // key = hotelId
    {
      var showTheMap = this.showMap;
      var showHotelOnMap = false;

      //window.scrollTo(0,0);
      // we are in mobile, scroll to the top of the map
      if (!showTheMap && !this.isFullMap)
      {
        this.isFullMap = true; 
        store.dispatch('UPDATE_USER', {
          isFullMap : this.isFullMap,
          fullMapId: key
        })
        showTheMap = true;
        showHotelOnMap = true;

        console.log("SCROLL TO")
              
        var ref = document.getElementById("topmap");
        setTimeout(function () {
          console.log(ref)
          ref.scrollIntoView({
            behavior: "instant", //"smooth",
            block: "start", //"start" "center"
          });
        }, 100);
        
      }

      if (showTheMap)
      {
        console.log("This is the key... " + key)
        this.$refs.mapContainer.setMarker(key, true)
        
        this.$refs.mapContainer.updatePos(lat, long, -1)
        if (showHotelOnMap) this.$refs.mapContainer.showMapHtml(key)
      }
    },
    mouseLeave(key)
    {
      if (this.showMap)
        this.$refs.mapContainer.unsetMarker(key)
    },
    changeDate()
    {
      var todayStr = new Date().toISOString().substr(0,10)
      var today = new Date(todayStr).getTime()
      if (today > 4294967295) today -= 86400000
      else today -= 86400 
      let inDate = new Date(this.checkin).getTime()
      let outDate = new Date(this.checkout).getTime()

      this.isErrDate = (inDate >= today && outDate > inDate) ? false : true
      if ( this.isErrDate == false)
      {
        this.$refs.countDropdown.savedata() // save the adults/children/rooms
        if (this.destId != '' && this.dest != '')
        {
          store.dispatch('UPDATE_USER', {
            searchname: this.dest,
            searchlat: this.searchLat,
            searchlong: this.searchLong,
            searchid: this.destId,
          })
     
          this.searchName = this.dest
          this.destId = ''
          this.setDest = ''  
          this.dest = ''
          
        }

        store.dispatch('UPDATE_USER', {

          searchin : this.checkin,
          searchout : this.checkout,
        })
        this.$urlRequest.cookies()
        this.fetchList()
      }
      else
      {
        this.checkin = this.restoreArray.checkin
        this.checkout = this.restoreArray.checkout
        store.dispatch('UPDATE_USER', {
       //   searchin : this.restoreArray.checkin,
       //  searchout : this.restoreArray.checkout,
          searchadult : this.restoreArray.adults,
          searchchild : this.restoreArray.childern,
          searchroom : this.restoreArray.rooms          
        })
      }
    },
    // routine to handle any LOCAL change to the display of hotels on this page..
    changeSort()
    {
      var rawList = store.getters.hotelList
      var rawCount = store.getters.hotelCount
      console.log("what is hotel count " +rawCount)
      var setKey = []
      // S=savings A=alpha P=price D=distance
      var sortKey = null
      var item = null
      var starCheck = false
      var ratingCheck = false
      var ammenCheck = 0
      var newDest = this.hotdest.toLowerCase().replace(" ", "")

      if (this.isFirstOnPage && this.isSortByDist)
      {
       /* if (newDest.length > 0)*/ this.sortby = 'D'
        this.isFirstOnPage = false
      }
      
      this.items = []
      for(var i=0; i<5; i++)
      {
        if (this.stars[i]) starCheck = true
        if (this.rating[i]) ratingCheck = true
      }

      Object.keys(this.ammen).forEach(key => {
        if (this.ammen[key]) ammenCheck++
      });

      while (rawCount > 0)
      {
        sortKey = null

        switch (this.sortby)
        {
          case 'S':
            item = -1
            break;
          case 'A':
            item = 'zzzzzzzzzz'
            break;
          case 'P':
            item = 9999999999
            break;
          default:
            item = 9999999999.9
            break;
        }
        // looping
        Object.keys(rawList).forEach(key => {

          if (!setKey.hasOwnProperty(key))
          {
            
            switch (this.sortby)
            {
              case 'S':  // savings
               
                if (item < rawList[key].discount && (rawList[key].rates.hasOwnProperty("fsg") || rawList[key].rates.hasOwnProperty("pro")))
                {
                  item = rawList[key].discount
                  sortKey = key
                }
                break;
              case 'A': // alpha
                if (item > rawList[key].name)
                {
                  item = rawList[key].name
                  sortKey = key
                }
                break;
              case 'P':  // price
                var disc = rawList[key].retailPrice-rawList[key].discount / rawList[key].nights
                if (item > disc && (rawList[key].rates.hasOwnProperty("fsg") || rawList[key].rates.hasOwnProperty("pro")))
                {
                  item = disc
                  sortKey = key
                }
                break;
              default:    // distance
                if (item > rawList[key].distance)
                {
                  item = rawList[key].distance
                  sortKey = key
                }
                break; 
            }
             
          }
        });

        if (sortKey !== null)     // && (rawList[key].rates.hasOwnProperty("fsg") || rawList[key].rates.hasOwnProperty("pro"))
        {
          var sGoIn = true
          if (starCheck)
          {
            sGoIn = false
            var strs = Math.floor(rawList[sortKey].stars)
            for(i=0;i<5;i++)
              if (this.stars[i] && strs==(i+1)) sGoIn = true;
          }
          var rGoIn = true
          if (ratingCheck)
          {
            rGoIn = false
            var rat = Math.floor(rawList[sortKey].userRate)
            for(i=0;i<5;i++)
              if (this.rating[i] && rat==(i+1)) rGoIn = true;
          }

          var aGoIn = true
          if (ammenCheck)
          {
            var aInit = 0            
            Object.keys(this.ammen).forEach(key => {
    
              if (this.ammen[key] &&  rawList[sortKey].amenities.hasOwnProperty(key) ) aInit++
            })
            if (aInit < ammenCheck) aGoIn = false
          }

          var hGoIn = true
          if (newDest != '')
          {
            if (rawList[sortKey].name.toLowerCase().replace(" ", "").indexOf(newDest) == -1)
              hGoIn = false;
          }
          if (this.items.hasOwnProperty(sortKey) ) console.log("We have a duplicate -- " + sortKey)
          if (sGoIn && rGoIn && aGoIn && hGoIn)
          {
            rawList[sortKey].pausedFor5seconds = false
            this.items.push (rawList[sortKey])
          }
          
          setKey[sortKey] = sortKey 
          //delete  rawList[sortKey]
          rawCount --
        }
        else
          rawCount = 0
      }
      console.log("UMMM not saved? " + this.hotdest)
      console.log(setKey)
    //  console.log(this.items)
      store.dispatch('UPDATE_USER', {
        hotdest: this.hotdest,
        sortby: this.sortby,
        stars : this.stars,
        rating : this.rating,
        ammen : this.ammen
      })
     this.$urlRequest.cookies()


      if(this.items.length == 0)
        this.isNoHotels = "INFO: Filter settings resulted in no hotels."
      else
        this.isNoHotels = null

      this.modifyMapPins()
    
      this.elementChange = (this.elementChange + 1) & 511

    },
    modifyMapPins()
    {
      this.$refs.mapContainer.clearPins()
      // #  this.items[key].hotelId
      Object.keys(this.items).forEach(key => {
        var output = "<a class='ol-theme_common_box_two' href='" + this.passURIRoute(this.items[key].rates,this.items[key].hotelId) + "' target='" + this.urlDirct(this.items[key].rates) + "'><div class='ol-theme_two_box_img ol-img_hover'><img src='" + this.items[key].thumbnail + "' alt='Hotel Image' />"
        output += "</div></a><a  ol-class='theme_two_box_content' href='" + this.passURIRoute(this.items[key].rates,this.items[key].hotelId) + "' target='" + this.urlDirct(this.items[key].rates) + "'><h4>" + this.items[key].name +"</h4></a>"
        output += "<div  class='theme_two_box_content'><p class='price_align'>" + this.showPrice(this.items[key].rates) + "</p></div>"
    
     //   if (this.items[key].rates.hasOwnProperty('fsg') || this.items[key].rates.hasOwnProperty('pro') )
        this.$refs.mapContainer.addPin(this.items[key].latitude, this.items[key].longitude, this.items[key].hotelId,output, this.getLowPrice(this.items[key].rates) )
      })
      var lat = (store.getters.searchlat != '' && store.getters.searchlat != null) ? store.getters.searchlat : store.getters.cityLat;
      var long = (store.getters.searchlong != '' && store.getters.searchlong != null) ? store.getters.searchlong : store.getters.cityLong;
       


      //console.log("WHAT IS LAL AND LONG -- " + lat + " " + long + store.getters.searchlat + store.getters.searchlong + store.getters.cityLat + store.getters.cityLong)
      //var zoom = (this.sortKey == 'D' || this.sortKey == 'A') ? 14 : 12;\
      // set max/min zoom is not needed as we are going from 0 to 17
      this.$refs.mapContainer.updatePos(lat, long, 12)
      this.$refs.mapContainer.addPin(lat, long, "", "", "xxx")
     // this.$refs.mapContainer.SetupMap()
      //this.$refs.mapContainer.savePins()
      this.$nextTick(() => {    
        
        this.mapChange = ((this.mapChange+1) & 511)
      })
    },
    PauseButton(key, event)
    {
      var cursor = event.target.style.cursor
    //  console.log(event.target.style)
      if (this.items[key].pausedFor5seconds == false)
      {
        this.items[key].pausedFor5seconds = true
        setTimeout(() => this.items[key].pausedFor5seconds = false, 5000);
      }
    },
    urlDirct(priceArr)
    {
      var target = (this.showMap) ? '_blank' : '_self'

 /*     if (this.showMap)
        return ""
  '_blank' */
       var isSelf = true
      if ((typeof priceArr.pro != "undefined") && (priceArr['pro'].price || priceArr['pro'].firstNight) && store.getters.priceAccess == 'pro' && (typeof priceArr.pro.site !== "undefined"))
        isSelf = false
      else if ((typeof priceArr.fsg != "undefined") && (priceArr['fsg'].price || priceArr['fsg'].firstNight) && (typeof priceArr.fsg.site !== "undefined"))
        isSelf = false
      else if ((typeof priceArr.fsg != "undefined") && priceArr['fsg'].ultPrice)
        isSelf = false
        
      if (isSelf) 
        target = '_self' 

      return target
    },
    passURIRoute(priceArr, key)
    {
      var output = ""/*"{name:'/hotel-details',query:"{k:'" + this.roomKey + "', s:'"*/
      var permission = store.getters.priceAccess  //store.getters.permissions

    //  if ((typeof priceArr))

      if (key != 'pro' && key != 'fsg' && key != 'mem')
      {
        //if (!this.showMap)
        //{
          if ((typeof priceArr.pro != "undefined") && (priceArr['pro'].price || priceArr['pro'].firstNight) && permission == 'pro'   && priceArr['pro'].site && priceArr['pro'].siteId )
            key = 'pro'
          else if ((typeof priceArr.fsg != "undefined") && (priceArr['fsg'].price || priceArr['fsg'].firstNight)  && priceArr['fsg'].site && priceArr['fsg'].siteId )
            key = 'fsg'
          else if ((typeof priceArr.fsg != "undefined") && priceArr['fsg'].ultPrice)
            key = 'mem'
        //}
      }
      if (key == 'mem')
      {
        if (this.isNotApp)
        {
          output = "/pro#/register"
          if (store.getters.promo != null) output += "?promo=" + store.getters.promo
        }
        else output = "javascript:void(0)"
      }
      else if (key != 'pro' && key != 'fsg')// && key != 'mem')
        output = "#" + key

        //  else if (this.showMap)
        //  {
        //<router-link :to="{ name: 'fooRoute',params:{param_var:'id_parameter'},query:{query_var:'query_params'}}" target="_blank"> 
        //   output = "{k:'" + this.roomKey + "', s:'"
        //  output += priceArr[key].site + "',i:'" + priceArr[key].siteId + "',o:'" +priceArr[key].otaId  + "'}"  //}"
        // }
     
      else
      {
        // check to see if we have a price link...
        if (key == 'fsg' && (typeof priceArr.fsg.site === "undefined"))
          output = "javascript:void(0)"
        else if (key == 'pro' && (typeof priceArr.pro.site === "undefined"))
          output = "javascript:void(0)"
        else
        {
          output = "/hotel-details?k=" + this.roomKey + "&s="
          output += priceArr[key].site + "&i=" + priceArr[key].siteId + "&o=" +priceArr[key].otaId
        }
      }

     // console.log(output)
      return output
    },
    /*onClick(event, route, object){
      if (!this.showMap)
        event.preventDefault();

      var output = this.passURIRoute(object)
      if (!this.showMap)
        this.$router.push({ path: route, params: output })  
    },*/
    getLowPrice(priceArr)
    {
      var retPrice = '-'
      var permission = store.getters.priceAccess  // store.getters.permissions
      var fsg = (typeof priceArr.fsg != "undefined") ? priceArr.fsg : null
      var pro = (typeof priceArr.pro != "undefined") ? priceArr.pro : null
      if (permission == 'pro' && pro != null)
      {
        if (pro.price || pro.firstNight)
          return (pro.price) ? this.info.displayCurrency + pro.price : this.info.displayCurrency + pro.firstNight
      }
      else if (fsg != null)
      {
        if(fsg.price || fsg.firstNight)
          return (fsg.price) ? this.info.displayCurrency + fsg.price : this.info.displayCurrency + fsg.firstNight
      }

      return '-'
    },
    showPrice(priceArr, index)
    {
   
      var output=""
      var permission = store.getters.priceAccess  // store.getters.permissions
      var fsg = (typeof priceArr.fsg != "undefined") ? priceArr.fsg : null
      var pro = (typeof priceArr.pro != "undefined") ? priceArr.pro : null
      if (permission == 'pro' && pro != null)
      {
        
        var nightCost = 'pernight'
        if(pro.price || pro.firstNight)
        { 
          var nightCost = (pro.price) ? 'pernight' : 'firstnight'
          var cost = (pro.price) ? pro.price : pro.firstNight
          output += "<img src='./pro.png'/><a href=\"" + this.passURIRoute(priceArr,'pro') + "\" class='inbtnfull' target='" + (this.showMap ? '_blank' : '_self') + "'>" + this.$t(nightCost) + "<span class='inprice'>"
          output += this.info.displayCurrency + cost + "</span></a><br />"
 
        }
        

      }
      //<a href="" class="inbtn">what<h3 ></h3></a>
      if (fsg != null)
      {
        var nightCost = 'pernight'
        

        if (fsg.ultPrice && permission == 'fsg')
        {
          var isNotApp = (this.isNotApp || (permission=='fsg' && store.getters.permissions=='pro')) ? true : false;
          if (isNotApp)
          {
            if (store.getters.guid == null || store.getters.username == null)
            {
              //  isNotApp = this.isNotApp
           
              output += "<a href='/pro#/register"
              if (store.getters.promo != null) output += "?promo=" + store.getters.promo
              output += "' class='inbtn'>"
            }
            else
              output += "<a href='/pro#/login' class='inbtn'>"
          }
        
          if (fsg.ultPrice == "membersOnly")
          {
            if (store.getters.permissions=='pro')
              output += this.$t("renewmember")
            else
              output += this.$t("becomemember")
          }
          else if (fsg.ultPrice > 0)
            output += this.$t("membersprice") + "<span class='inprice'>"+this.info.displayCurrency + fsg.ultPrice + "</span><br />"

          if (isNotApp) output += "</a>"
          output += "<br />"
       
        } // endif ultimate price for non members

        if(fsg.price || fsg.firstNight)
        { 
          var target = (this.showMap) ? '_blank' : '_self'
          if ((typeof priceArr.fsg.site === "undefined"))
            target = "_self"

          var nightCost = (fsg.price) ? 'pernight' : 'firstnight'
          var cost = (fsg.price) ? fsg.price : fsg.firstNight
          if (fsg.ultPrice && permission == 'fsg')
            output += "<span>" + this.$t("nonmember") + " </span>"
          output += "<a href=\"" + this.passURIRoute(priceArr,'fsg') + "\" class='inbtnfull' target='" + target + "'>" + this.$t(nightCost) + "<span class='inprice'>"
          output += this.info.displayCurrency + cost + "</span></a><br />"
 
        }

      } // endif pricing
      

      if (fsg == null && pro == null) // setup a message
        output += "<p class='error_red'>" + this.$t("norates") + "</p>"
 
    /*
    <div class="top_form_search_button">
        <button class="btn btn_theme btn_md"> {{$t("search")}} </button>
    </div>
    */


    return output
  },

    getRankCircle(rank){

      // Round down to get whole stars:
      var wStars = Math.floor(rank);
      // Check if whole is less than rank.
      // If less than rank, a half star is needed:
      var halfStars = (wStars < rank);

      var output="";
      //Loop through five stars:
      for(let i=1;i<=5;i++){
        //Less than or equal to stars, display a solid star:
        if(i<=wStars){
          output+="</i><i class='fas fa-circle' style='color:#007ca0'></i>";

          //If interation is after a whole star and a half star is needed, display half star:
        }else if( i==wStars+1 && halfStars==true ){
          output+="<i class='fas fa-adjust' style='color:#007ca0'></i>";

          //Otherwise, display a gray empty star:
        }else{
          output+="<i class='far fa-circle' style='color:#bfbfbf'></i>";
        }
      }
      return output;
    },

    getRankStars(rank){

      // Round down to get whole stars:
      var wStars = Math.floor(rank);
      // Check if whole is less than rank.
      // If less than rank, a half star is needed:
      var halfStars = (wStars < rank);
   
      var output="";
      //Loop through five stars:
      for(let i=1;i<=5;i++){
        //Less than or equal to stars, display a solid star:
        if(i<=wStars){
          output+="</i><i class='fas fa-star' style='color:#fbcc05'></i>";

          //If interation is after a whole star and a half star is needed, display half star:
        }else if( i==wStars+1 && halfStars==true ){
          output+="<i class='fas fa-star-half-alt' style='color:#fbcc05'></i>";

          //Otherwise, display a gray empty star:
        }else{
          output+="<i class='far fa-star' style='color:#bfbfbf'></i>";
        }
      }
      return output;
    },

    fetchList()
    {
      if (store.getters.searchid == null)
        this.$router.push('/')

      let arr = {'id' : store.getters.searchid}
      arr['checkin'] = store.getters.searchin
      arr['checkout'] = store.getters.searchout
      arr['childern'] = store.getters.searchchild
      arr['adults'] = store.getters.searchadult
      arr['rooms'] = store.getters.searchroom
      arr['userLanguage'] = store.getters.userLanguage
      arr['userCurrency'] = store.getters.userCurrency
      arr['promo'] = store.getters.promo

      this.isNoHotels = null
//      arr['userCurrency'] = store.getters.searchin;
      console.log(arr)
      this.loaderActive = true;

      //if (this.dest.length > 2)
     // {
     
        this.$urlRequest.post('fetch', 'hotelList', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            console.log(response)
            this.date = null
            this.numofItems = (response.hasOwnProperty('info')) ? response.info.hotelCount : 0
            if (this.numofItems == 0)  // we have an error, notify the user of it...
            {
              this.isNoHotels = "ERROR: No hotels found."
            }
            else
            {
              this.roomKey = response.info.key         
              //    if (response.info.userCurrency) this.info.userCurrency = response.info.userCurrency
              //  if (response.info.userLanguage) this.info.userLanguage = response.info.userLanguage
              if (response.info.displayCurrency) this.info.displayCurrency = response.info.displayCurrency

              // when we fail a date change, return data to older valid values.
              this.restoreArray = arr
              
              // calcluate distance.
              var lat = (store.getters.searchlat != '' || store.getters.searchlat != null) ? store.getters.searchlat : response.info.latitude;
              var long = (store.getters.searchlong != '' || store.getters.searchlong != null) ? store.getters.searchlong : response.info.longitude;
              var deg2Rag = Math.PI / 180
              var rad2Deg = 180 / Math.PI

              Object.keys(response.hotels).forEach(key => {
                var dist = 0.0
                var theta = long - response.hotels[key].longitude

                if (lat == response.hotels[key].latitude && long == response.hotels[key].longitude)
                  response.hotels[key].distance = 0.0
                else
                {
                  dist = Math.sin(lat * deg2Rag) * Math.sin(response.hotels[key].latitude * deg2Rag) + Math.cos(lat * deg2Rag) * Math.cos(response.hotels[key].latitude * deg2Rag) * Math.cos(theta * deg2Rag)
                  if (dist > 1.0) dist = 1.0
                  dist = Math.acos(dist) * rad2Deg
                  response.hotels[key].distance = dist * (60.0 * 1.1515);  // miles
                }            
              })

              store.dispatch('UPDATE_USER', {
                hotelList: response.hotels,
                hotelCount: this.numofItems,
                city : response.info.city,
                cityName : response.info.cityName,
                cityLat : response.info.latitude,
                cityLong : response.info.longitude,
                searchid : arr['id'].replace(/-.*$/, ''), // we only want hotel name, once...
                //    userCurrency : response.info.userCurrency,
                //  userLanguage : response.info.userLanguage,
                displayCurrency : response.info.displayCurrency
              })


              if (response.info.isHotelSearch || response.info.isPOISearch)
                this.isSortByDist = true

             /* if (response.info.isHotelSearch == true)
                this.hotdest = store.getters.searchname.replace(/(&|-|,).*$/, '')//this.sortby = 'A'           
              else if(this.isFirstOnPage && response.info.isPOISearch)
                this.sortby = 'D';*/
              //}

              console.log("CALL again???")
              this.changeSort()
            }   

            this.loaderActive = false;

          

            //console.log(this.items)
            // this.$router.push('/login')
          })
          .catch((error) => {
            this.loaderActive = false;
            console.log(error)
            this.isNoHotels = error.message
            //this.loading = false
            // this.$nprogress.done()
            //this.$notification['warning']({
            // message: error.code,
            //  description: error.message
            //})
          })
     // }
    //  else
     //   this.isOpen=false
    },
  },

    created(){
      if (store.getters.tsearchid)
        this.loaderActive = true;

     
    },
 /*   updated()
    {
      //console.log(this.date)
      if (this.date.length < 2 || !(this.date[0] instanceof Date))
      {
        this.date = null
      }
      console.log("Updated +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")

    },*/
    mounted() {
     
 
      window.onpageshow = function(event) {
        if (event.persisted) {
          window.location.reload();
        }
      };
      
     
      //this.items = data.hotelData
      let size = window.innerWidth 
      this.mapHeight = window.innerHeight
      this.pickerPos = (size >= 992) ? false : true
      this.showMap = (size >= 992) ? true : false

      window.addEventListener('resize', () => {
        let size = window.innerWidth 
        this.mapHeight = window.innerHeight
        this.pickerPos = (size >= 992) ? false : true
        this.showMap = (size >= 992) ? true : false
      })
     
      this.$nextTick(() => {    
        store.dispatch('UPDATE_USER', {
          mapMinZoom : 0,
          mapMaxZoom : 17,
        })
      }) 
      
      this.fetchList()
    },

};
</script>
 