<template>
    <section id="home_one_banner_tour">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="banner_one_text">
                        <h1>{{$t("attractions")}}</h1>
                        <h3>{{$t("attractSub")}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "BannerTour"
};
</script>