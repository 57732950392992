<template>

    <!-- Common Banner Area -->
    <POIpageBanner />

    <!-- Privacy Policy Areas -->
    <POIpageContent />

    <!-- Cta Area 
    <Cta />-->

</template>
<script>
import POIpageBanner from '@/components/pages/POIpageBanner.vue'
import POIpageContent from '@/components/pages/POIpageContent.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
  name: "POIpageView",
    components: {
      POIpageBanner, POIpageContent//, Cta
    }
};
</script>
