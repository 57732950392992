<template>
     <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                      <div class="terms_item">
                        <h2>{{$t("terms")}}</h2>
                        <p>{{$t("onlyenglish")}}</p><br />

                        <h4>1.  Introduction</h4>
                        <p >
                          Thank you for visiting flystaygo and, if applicable, choosing to download and/or install our App. Please read this Terms of Service and our Privacy Policy carefully as you must agree to both in order to have permission to use our Service.
                        </p>
                     </div>
                      <div class="terms_item">
                       
                          <h4>2.  Definitions</h4>
                        <p>
                          Throughout this Agreement, we may use certain words or phrases, and it is important that you understand the meaning of them. The list is not all-encompassing and no definition should be considered binding to the point that it renders this Agreement nonsensical:
                          <br>
                          <li>
                            "Agreement" refers to these Terms of Service;
                            <br>
                          </li>
                          <li>
                            "App" refers to our mobile software application;
                            <br>
                          </li>
                          <li>
                            Flystaygo and Flystaygo-pro refers to our brand and is trademarked by "Major Coup, LLC"; our Site; our App; our Service; or a combination of all or some of the preceding definitions, depending on the context in which the word is used;
                            <br>
                          </li>
                          <li>
                            "Service" refers to the services that we provide, including our App and our Site;
                            <br>
                          </li>
                          <li>
                            "Site" refers to our website, www.flystaygo.com;
                            <br>
                          </li>
                          <li>
                            "User" refers to anyone who uses our Service, including users of our App and general visitors to our Site;
                            <br>
                          </li>
                          <li>
                            "You" refers to you, the person who is governed by these terms.

                          </li>
                        </p>
                      </div>
                      <div class="terms_item">
                       
                          <h4>3.  What Flystaygo Offers</h4>
                        <p>
                         Flystaygo, flystaygo-pro is a mobile application ("App") for iOS and Android platforms, and a web site which facilitates hotel, flights, and car rental reservations for our User. We are an aggregate and source our rates from a variety of partners including Booking Holdings, Inc., Expedia Group, and The Travel Network. We act as an agent for these partners and source the best price for you.  Payment facilitates the reservation and sends credit card and consumer details in a PCI compliant format through our partners.
                        </p>
                      </div>
                      <div class="terms_item">
                      
                          <h4 >4.  Eligibility</h4>
                        <p>
                          In order to use our Service, you must meet a number of conditions, including but not limited to:
                          <li>You must not be in violation of any embargoes, export controls, or other laws of the United States or other countries having jurisdiction over this Agreement, flystaygo, and yourself. For example, if the Office of Foreign Assets Control prohibits conducting financial transactions with nationals, residents, or banks of your country, you must not use our Service.</li>
                          <li>You must be the minimum age required to enter into a contract in the area in which you reside, and, in any event, must not be less than 18 years of age.</li>
                          <li>You must not use our Service on behalf of a natural person other than yourself.</li>
                          <li>You must, if signing up on behalf of a legal person, be authorized by that legal person to bind it to this Agreement, and you hereby agree that you are doing so and that both you and it shall be jointly and severally liable for the fulfillment of any obligations imposed hereunder upon either one of you or any breaches of this Agreement by either one of you.</li>
                          <li>You must provide us with personal information, payment information, and other information that we deem necessary to provide you with our Service.</li>
                          <li>You must properly report all income and contracts as required to the Internal Revenue Service and any other governmental entity that may require it. You acknowledge that flystaygo is not required to conduct your recordkeeping for you or to report any income information to the tax authorities.</li>
                        </p>
                      </div>
                      <div class="terms_item">
                     
                          <h4>5.  Disclaimer</h4>
                        <p class="aboutUsDesc">
                         Flystaygo facilitates the reservations of hotels, flights and car rentals but makes no representations about either consumers or our service. You agree that flystaygo is not responsible for the actions or omissions of any other User in relation to you, whether they may result in liability out of tort, breach of contract, or otherwise.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>6.  Rules of Use</h4>
                        <p>
                          You must not:
                          <li>Violate the laws of the United States, its states, or any foreign political entity having jurisdiction over this Agreement, whether or not the foreign political entity is a country or a subdivision (such as a state or province) or municipality (such as a city, town, county, or region) of a foreign country.</li>
                          <li>Facilitate transactions for hotels which operate without the required licenses or meeting other applicable regulatory burdens.</li>
                          <li>Discriminate against any User on prohibited grounds.</li>
                          <li>Infringe on anyone's intellectual property rights, defame anyone, impersonate anyone, or otherwise violate the rights of a third party.</li>
                          <li>Hack, crack, phish, SQL inject, or otherwise compromise the security or integrity of the flystaygo Site, Service, or its Users' computers.</li>
                          <li>Do anything else which could bring flystaygo into disrepute or violate the rights of any person.</li>
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>7.  Payment and Refunds</h4>
                        <p class="aboutUsDesc">
                          Payment may be made by means of MasterCard, Visa, or American Express and is collected by our partners. We do not store or have access to credit card information. Unless otherwise stated, all prices are listed in United States dollars. Additional terms of payment and refunds may be listed through our Service by flystaygo or the applicable service and are hereby incorporated into this Agreement by reference.
                          <br>Hotels, Airlines, and Car Rental Companies are responsible for setting their own refund policies (including policies on refunds in the event of cancellations by consumers) and you agree that flystaygo is not responsible for any breach of contract by a service, nor responsible for refunding you in the event that your service is not delivered in a timely manner or at all.
                          <br>Please be advised that payment information is not used by flystaygo but rather passed to the hotel with which you which to enter into a transaction, and you hereby agree to release flystaygo from any liability relating to what the hotel, airline or rental company does with such information. Disputes about improper billing should be addressed to them directly.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>8.  Data Charges</h4>
                        <p>
                          The downloading, updating, and use of the flystaygo App may involve the transmission of data over your mobile provider's network. Such data transmission may be subject to fees, for which you hereby agree flystaygo is not liable. You are advised to review your contract with your mobile provider to ensure that you do not incur any unexpected expenses from using flystaygo.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>9.  Limited License</h4>
                        <p>
                          The flystaygo App, and the web site service is offered on a licensed basis. By downloading and/or installing our App or using our web site, you are agreeing to be granted a revocable license to use our Service for your own personal purposes. You agree not to attempt to reverse engineer, resell, copy, or otherwise tamper with or distribute our Service.
                          <br>We may revoke this license to you at any time without compensation. You agree that upon receiving notice of such revocation, you will make all reasonable efforts to remove any of our proprietary files from your hardware or any hardware under your control.
                        </p>
                      </div>
                      <div class="terms_item">
                      
                          <h4>10. Our Copyright</h4>
                        <p>
                          Flystaygo copyright and trademark is important to us as it distinguishes us from our third party competitors. You agree not to copy, distribute, display, disseminate, or otherwise reproduce any of the information on the Site, including copyrighted material submitted by third parties to flystaygo, without receiving our prior written permission.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>11. Your Copyright</h4>
                        <p>
                          Flystaygo must be assured that it has the right to use the content that is published through its Service by its Users. Such content may include, but is not limited to, photographs, videos, text, audio, and other materials. Whenever submitting content through our Service, you agree that you are granting us a non-exclusive, universal, perpetual, irrevocable, sub licensable, commercial and non-commercial right to use, distribute, sell, publish, and otherwise make use of the content that you submit to us. You warrant to us that you have the right to grant us this right over the content, and that you will indemnify us for any loss resulting from a breach of this warranty and defend us against claims regarding the same.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>12.  Trademarks</h4>
                        <p>
                          Flystaygo is a trademark and brand used by us, Major Coup, LLC., to uniquely identify our Site, Service, and business. You agree not to use this phrase anywhere without our prior written consent. Additionally, you agree not to use our trade dress, or copy the look and feel of our website or its design, without our prior written consent. You agree that this paragraph goes beyond the governing law on intellectual property law, and includes prohibitions on any competition that violates the provisions of this paragraph, including starting your own competing service.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>13.  Revocation of Consent</h4>
                        <p>
                          We may revoke our consent for your use of our intellectual property, or any other permission granted to you under this Agreement, at any time. You agree that if we so request, you must take immediate action to remove any usage of our intellectual property that you may have engaged in, even if it would cause a loss to you.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>14.  Copyright & Trademark Infringement</h4>
                        <p>
                          We take copyright infringement very seriously, and we have registered a Copyright Agent with the United States Copyright Office, which limits our liability under the Digital Millennium Copyright Act. If you believe that your copyright has been infringed, please send us a message which contains:
                          <br>
                          <li>Your name.</li>
                          <li>The name of the party whose copyright has been infringed, if different from your name.</li>
                          <li>The name and description of the work that is being infringed.</li>
                          <li>The location on our website of the infringing copy.</li>
                          <li>A statement that you have a good faith belief that use of the copyrighted work described above is not authorized by the copyright owner (or by a third party who is legally entitled to do so on behalf of the copyright owner) and is not otherwise permitted by law.</li>
                          <li>A statement that you swear, under penalty of perjury, that the information contained in this notification is accurate and that you are the copyright owner or have an exclusive right in law to bring infringement proceedings with respect to its use.</li>
                          </p><p>
                          You must sign this notification electronically and send it to our Copyright Agent at CustomerCare@flystaygo.com.
                          <br>
                          <br>Although U.S. law does not provide for a similar procedure for trademark infringement, we recommend that you send us similar information to that above in regards to any allegation of trademark infringement, and we will address it as soon as practicable.

                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>15.  Communications Decency Act</h4>
                        <p>
                          <br>Similar to the DMCA provisions above, United States law-specifically Section 230 of the Communications Decency Act-creates a defense for us for the actions of third parties in regards to any defamatory content published through our Service. Although we are not liable for defamatory words published through our Service by our Users even if given notice, we do prohibit defamation under this Agreement and we may, if we believe the situation warrants it, take action against the offending User. Please notify us at CustomerCare@flystaygo.com if any of our Users have posted anything that you believe is defamatory.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>16.  Representations & Warranties</h4>
                        <p>
                          <br>WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE MERCHANTABILITY OF OUR SERVICE OR FITNESS FOR ANY PARTICULAR PURPOSE. YOU AGREE THAT YOU ARE RELEASING US FROM ANY LIABILITY THAT WE MAY OTHERWISE HAVE TO YOU IN RELATION TO OR ARISING FROM THIS AGREEMENT OR OUR SERVICES, FOR REASONS INCLUDING, BUT NOT LIMITED TO, FAILURE OF OUR SERVICE, NEGLIGENCE, OR ANY OTHER TORT. TO THE EXTENT THAT APPLICABLE LAW RESTRICTS THIS RELEASE OF LIABILITY, YOU AGREE THAT WE ARE ONLY LIABLE TO YOU FOR THE MINIMUM AMOUNT OF DAMAGES THAT THE LAW RESTRICTS OUR LIABILITY TO, IF SUCH A MINIMUM EXISTS.
                          <br>
                          <br>YOU AGREE THAT WE ARE NOT RESPONSIBLE IN ANY WAY FOR DAMAGES CAUSED BY THIRD PARTIES WHO MAY USE OUR SERVICES, INCLUDING BUT NOT LIMITED TO PEOPLE WHO COMMIT INTELLECTUAL PROPERTY INFRINGEMENT, DEFAMATION, TORTIOUS INTERFERENCE WITH ECONOMIC RELATIONS, OR ANY OTHER ACTIONABLE CONDUCT TOWARDS YOU.
                          <br>
                          <br>YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY FAILURE OF A CONSUMER TO MAKE THE APPROPRIATE PAYMENT TO YOU IF YOU ARE A HOTEL (INCLUDING AS THE RESULT OF CONDUCTING A CHARGEBACK AGAINST YOU AFTER YOUR SERVICES HAVE BEEN DELIVERED), OR OF A HOTEL TO RENDER THE REQUIRED SERVICES TO YOU IF YOU ARE A CONSUMER.
                          <br>
                          <br>YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY FAILURE ON THE PART OF A PAYMENT PROCESSOR TO DIRECT PAYMENTS TO THE CORRECT DESTINATION, OR ANY ACTIONS ON THEIR PART IN PLACING A HOLD ON YOUR FUNDS.
                          <br>
                          <br>YOU AGREE THAT WE ARE NOT LIABLE FOR ANY FAILURE OF THE GOODS OR SERVICES OF OUR COMPANY OR A THIRD PARTY, INCLUDING ANY FAILURES OR DISRUPTIONS, UNTIMELY DELIVERY, SCHEDULED OR UNSCHEDULED, INTENTIONAL OR UNINTENTIONAL, ON OUR WEBSITE WHICH PREVENT ACCESS TO OUR WEBSITE TEMPORARILY OR PERMANENTLY.
                          <br>
                          <br>THE PROVISION OF OUR SERVICE TO YOU IS CONTINGENT ON YOUR AGREEMENT WITH THIS AND ALL OTHER SECTIONS OF THIS AGREEMENT. NOTHING IN THE PROVISIONS OF THIS "REPRESENTATIONS & WARRANTIES" SECTION SHALL BE CONSTRUED TO LIMIT THE GENERALITY OF THE FIRST PARAGRAPH OF THIS SECTION.
                          <br>
                          <br>
                          <li>For Jurisdictions that do not allow us to limit our liability: Notwithstanding any provision of these Terms, if your jurisdiction has provisions specific to waiver or liability that conflict with the above then our liability is limited to the smallest extent possible by law. Specifically, in those jurisdictions not allowed, we do not disclaim liability for: (a) death or personal injury caused by its negligence or that of any of its officers, employees or agents; or (b) fraudulent misrepresentation; or (c) any liability which it is not lawful to exclude either now or in the future.</li>
                        </p><p>
                          IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT REGARDING RELEASE THEN THE FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR." YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL CODE. YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED RELEASE.
                          <br>
                          <br>
                        </p>
                      </div>
                      <div class="terms_item">                      
                          <h4>17.  Indemnity</h4>
                        <p>
                         You agree to indemnify and hold us harmless for any claims by you or any third party which may arise from or relate to this Agreement or the provision of our service to you, including any damages caused by your use of our Service. You also agree that you have a duty to defend us against such claims and we may require you to pay for an attorney(s) of our choice in such cases. You agree that this indemnity extends to requiring you to pay for our reasonable attorneys' fees, court costs, and disbursements. In the event of a claim such as one described in this paragraph, we may elect to settle with the party/parties making the claim, and you shall be liable for the damages as though we had proceeded with a trial.
                        </p>
                      </div>
                      <div class="terms_item">      
                          <h4>18.  Choice of Law</h4>
                        <p>
                          <br>This Agreement shall be governed by the laws in force in the State of Nevada. The offer and acceptance of this contract are deemed to have occurred in the State of Nevada.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>19.  Forum of Dispute</h4>
                        <p>
                          You agree that any dispute arising from or relating to this Agreement will be heard solely by a court of competent jurisdiction in the State of Nevada. Specifically, where the subject matter of a dispute is eligible for it, you agree that any disputes shall be heard solely before a Small Claims Referee in the Las Vegas Township Justice Court in the State of Nevada ("Small Claims Court") in accordance with Chapter 73 of the Nevada Revised Statutes and all other applicable legislation.
                          <br>
                          <br>If a dispute claims multiple claims and one or more of those claims would be eligible to be heard by the Small Claims Court, you agree not to bring the other claims against us and to instead proceed within the Small Claims Court.
                          <br>
                          <br>If you would be entitled in a dispute to an amount exceeding the monetary jurisdiction of the Small Claims Court, you agree to waive your right to collect any damages in excess of the monetary jurisdiction and instead still bring your claim within the Small Claims Court. At the time of drafting this Agreement, the monetary jurisdiction of the Small Claims Court is $7,500.
                          <br>
                          <br>You agree that if a dispute is eligible to be heard in Small Claims Court but you would be entitled to an additional or alternative remedy in a higher court, such as injunctive relief, you will waive your right to that remedy and still bring the dispute within the Small Claims Court.
                          <br>
                          <br>If you bring a dispute in a manner other than in accordance with this section, you agree that we may move to have it dismissed, and that you will be responsible for our reasonable attorneys' fees, court costs, and disbursements in doing so.
                          <br>
                          <br>You agree that the unsuccessful party in any dispute arising from or relating to this Agreement will be responsible for the reimbursement of the successful party's reasonable attorneys' fees, court costs, and disbursements.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>20.  Force Majeure</h4>
                        <p>
                          You agree that we are not responsible to you for anything that we may otherwise be responsible for, if it is the result of events beyond our control, including, but not limited to, acts of God, war, insurrection, riots, terrorism, crime, labor shortages (including lawful and unlawful strikes), embargoes, postal disruption, communication disruption, unavailability of payment processors, failure or shortage of infrastructure, shortage of materials, or any other event beyond our control.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>21.  Severability</h4>
                        <p>
                          <br>In the event that a provision of this Agreement is found to be unlawful, conflicting with another provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force as though it had been entered into without that unenforceable provision being included in it.
                          <br>If two or more provisions of this Agreement are deemed to conflict with each other's operation, flystaygo shall have the sole right to elect which provision remains in force.

                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4 class="underline">22.  Non-Waiver</h4>
                        <p class="aboutUsDesc">
                          Flystaygo reserves all rights afforded to us under this Agreement as well as under the provisions of any applicable law. Our non-enforcement of any particular provision or provisions of this Agreement or any applicable law should not be construed as our waiver of the right to enforce that same provision under the same or different circumstances at any time in the future.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>23.  Termination & Cancellation</h4>
                        <p>
                          We may terminate your access to our Site and Service at our discretion without explanation, though we will strive to provide a timely explanation in most cases. Our liability for refunding you, if you have paid anything to us, will be limited to the amount you paid for goods or services which have not yet been and will not be delivered, except in cases where the termination or cancellation was due to your breach of this Agreement, in which case you agree that we are not required to provide any refund or other compensation whatsoever.
                          <br>Under no circumstances, including termination or cancellation of our Service to you, will we be liable for any losses related to actions of other Users.
                        </p>
                      </div>
                      <div class="terms_item">                    
                          <h4>24.  Assignment of Rights</h4>
                        <p>
                          You may not assign your rights and/or obligations under this Agreement to any other party without our prior written consent. We may assign our rights and/or obligations under this Agreement to any other party at our discretion.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>25.  Amendments</h4>
                        <p>
                          We may amend this Agreement from time to time. When we amend this Agreement, we will update this page accordingly. You must read this page each time you access our Service, and your continued use of our Service shall constitute your acceptance of any such amendments.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4 class="underline">26.  Corporate Information</h4>
                        <p class="aboutUsDesc">
                          Flystaygo is owned and operated by Major Coup, LLC., a Limited Liability Company formed lawfully and doing business in the State of Nevada. Major Coup, LLC. is entered into the records of the Secretary of State for the State of Nevada's commercial recording office under Certificate Number C20190523-0874 and operates under the Nevada Business License ID Number NV20191389977. Any service of process or other legal documentation should, unless otherwise required or permitted by this Agreement, our Privacy Policy, or any applicable law, be sent to our Registered Agent. Our Registered Agent may change from time to time, and you are advised that you may determine our Registered Agent's most current information by visiting http://nvsos.gov/sosentitysearch and querying the above details about our entity. If you are unable to do this, please contact info@flystaygo.com and we will be able to provide you with further assistance.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>27.  California Users and Residents</h4>
                        <p>
                          Flystaygo permits residents of the State of California to use its Service. Pursuant to California Civil Code Section 1789.3, any questions about pricing, complaints, or inquiries about flystaygo must be addressed to our agent for notice and sent via certified mail to that agent. For our agent's most current contact information, please send a request to CustomerCare@flystaygo.com.
                          <br>
                          <br>Lastly, California users are also entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
                        </p><br />
                        <p>Last Modified: July 21, 2023</p>
                      </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
  name: "TermsServiceContent"
};
</script>