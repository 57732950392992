<template>
    <!-- Common Banner Area -->
    <BannerPages />

    <!-- Form Area 
    <Form />-->

    <!-- Hotel Search Areas -->
    <SearchResultPages />


</template>
  
<script>
// @ is an alias to /src
import BannerPages from '@/components/hotel/BannerPages.vue'
//import Form from '@/components/hotel/Form.vue'
import SearchResultPages from '@/components/hotel/SearchResultPages.vue'
//import Cta from '@/components/home/Cta.vue'
export default {
    name: 'HotelPageView',
    components: {
      BannerPages,  SearchResultPages 
    } 
}
</script>