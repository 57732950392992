<template>

    <!-- Common Banner Area--> 
   <TourBanner />

    <!-- Form Area 
    <TourForm />-->

    <!-- Tour Search Areas -->
    <TourSearch v-bind:pageProp="pageProp"/>


</template>
<script>
import TourBanner from '@/components/tour/TourBanner.vue'
//import TourForm from '@/components/tour/TourForm.vue'
import TourSearch from '@/components/tour/TourSearchResult.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
    name: "TourSearchView",
    components: {
        TourBanner,    TourSearch 
    },
    data() {
      return {
        pageProp : {
          isInPage : true,
        }
      }
    }
};
</script>
