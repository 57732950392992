<template>
    <section id="dashboard_main_arae" class="section_padding">
      <modal :active="modalVisible" @closing="(i) => modalVisible = i" :data="modalData" :confirm="modalConfirm"/>
      <Loader :active="loaderActive" />
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="dashboard_sidebar">
                        <div class="dashboard_sidebar_user">
                            <i class="fas fa-tachometer-alt fa-9x"></i>
                          <h3>{{memberInfo.firstName}} {{memberInfo.lastName}}</h3>
                          <p>{{memberInfo.phone}}</p>
                          <p>{{memberInfo.email}}</p>
                        </div>
                        <div class="dashboard_menu_area">
                            <ul>
                              <li>
                                <router-link to="/my-profile">
                                  <i class="fas fa-user-circle"></i>{{$t('myprofile')}}
                                </router-link>
                              </li>
                                <li><router-link to="/dashboard" class="active"><i
                                            class="fas fa-tachometer-alt"></i>{{$t('hotelbook')}}</router-link></li>
                               <!-- <MyBookingOption />-->
                                
                             <!--   <li><router-link to="/wallet"><i class="fas fa-wallet"></i>Wallet</router-link>
                                </li>
                                <li><router-link to="/notification"><i
                                            class="fas fa-bell"></i>Notifications</router-link></li> -->
                                <LogoutBtn />
                            </ul>
                        </div>
                    </div>
                </div>
               
                <div v-if="active" class="modal-backdrop fade show"></div>
                <div class="col-lg-8">
                    <div class="dashboard_main_top">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="dashboard_top_boxed">
                                    <div class="dashboard_top_icon">
                                        <i class="fas fa-shopping-bag"></i>
                                    </div>
                                    <div class="dashboard_top_text">
                                        <h3>{{$t('totalbook')}}</h3>
                                        <h1>{{numOfBook}}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="dashboard_top_boxed">
                                    <div class="dashboard_top_icon">
                                        <i class="fas fa-sync"></i>
                                    </div>
                                    <div class="dashboard_top_text">
                                        <h3>{{$t('pendbook')}}</h3>
                                        <h1>{{numOfPend}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard_common_table">
                        <h3>{{$t('hotelbook')}}</h3>
                        <div class="table-responsive-lg table_common_area">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{$t('bookid')}}</th>
                                        <th>{{$t('checkin')}}</th>
                                        <th>{{$t('totalsm')}}</th>
                                        <th>{{$t('status')}}</th>
                                        <th>{{$t('action')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,key) in memberInfo.booking">
                                        <td>{{item.confCode}}</td>
                                        <td>{{item.checkin}}</td>
                                        <td>{{item.total}}</td>
                                        <td :class="getStatusColor(item)">{{getStatus(item)}}</td>
                                        <td><span v-show="item.cancel!='' && item.cancel!=null" v-on:click="setCancel(item.cancel)"><i class="fas fa-times-circle"></i><i class="fas fa-sync"></i></span>
                                      &nbsp;<i @click="getBooking(item)" class="fas fa-eye"></i></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                  <!--  <div class="pagination_area">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">«</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">»</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import store from '@/store'
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'
import Loader from '@/components/Loader.vue'
import modal from '@/components/Modal.vue'
//import MyBookingOption from '@/components/dashboard/MyBookingOption.vue'
export default {
    name: "DashboardArea",
    components: {
      LogoutBtn, //MyBookingOption
      Loader,
      modal
    },
    data() {
      return {
        showMap : false,
        modalVisible : false,
        modalData: null,
        modalConfirm : null,
        loaderActive : false,
        memberInfo : {
          'firstName': '',
          'middleName' : '',
          'lastName' :'',
          'address' : '',
          'city' : '',
          'state' : '',
          'zip' :'',
          'language' : '',
          'phone' : '',
          'booking' : {}
        },
        numOfBook : 0,
        numOfPend : 0
      }
    },
 created() {
     this.loaderActive = true; 
     if (store.getters.memberInfo.hasOwnProperty('status'))
     {
       this.memberInfo = store.getters.memberInfo
       this.calcBook()
     }
    },
    mounted() {

      //this.items = data.hotelData
      let size = window.innerWidth 
      this.showMap = (size >= 992) ? true : false

      window.addEventListener('resize', () => {
        let size = window.innerWidth 
        this.showMap = (size >= 992) ? true : false
      })

      // functions
      console.log(this.memberInfo)
      if (!this.memberInfo.hasOwnProperty('status'))
      {
        this.fetchData()
      }
      else
        this.loaderActive = false; 

    },
    methods : {
      setCancel(cancel)
      {
        if (cancel.substring(0,4) == 'http')
        {
          window.open(
             cancel,
             (this.showMap) ? '_blank' : '_self' // <- This is what makes it open in a new window.
          );
        }
        else // pop up model  
        {
          var output = "<p>" + this.$t('phonemsg') + "</p>"
          output += "<p><a href='tel:" + cancel.replace(/\D/g, "") + "'>" + cancel + "</a></p><br>"
          this.modalData = output
          this.modalConfirm = this.$t('ok')
          this.modalVisible = true
        }
      },
      getBooking(item)
      {
        var output = ""

        // now we need data here...
        output+= "<p style='font-weight: 600;'>"+item.hotelName+"</p><br>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('date')+"</span><span>"+item.resDate+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('yourname')+"</span><span>"+item.name+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('emailsm')+"</span><span>"+item.email+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('checkin')+"</span><span>"+item.checkin+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('checkout')+"</span><span>"+item.checkout+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('totalsm')+"</span><span>"+item.total+"</span></p>"
        output += "<p style='display: flex; justify-content: space-between;'><span style='font-weight: 600;'>"+this.$t('status')+"</span><span>"+this.getStatus(item)+"</span></p>"

        output += "<p style='font-weight: 600;'>"+item.confName+"</p><p>"+item.confCode+"</p>"
        output += "<p style='font-weight: 600;'>"+item.resName+"</p><p>"+item.resCode+"</p><br>"

        this.modalData = output
        this.modalConfirm = this.$t('ok')
        this.modalVisible = true
      },
      getStatus(item)
      {
        var retData = this.$t('complete')
        if (item.dateCancel != null)
          retData = this.$t('cancel')
        else if (item.cancel != null && item.cancel != '')
        retData = this.$t('pending')

        return retData
      },
      getStatusColor(item)
      {
        var retData = "complete"
        if (item.dateCancel != null)
          retData = "cancele"
        else if (item.cancel != null && item.cancel != '')
          retData = ""

        return retData
      },
      updateData()
      {

      },
      fetchData()
      {
        var arr = {}
        this.$urlRequest.post('accounts', 'memberInfo', arr)    // {'text' : this.dest, 'type' : 'hotels'}
         .then((response) => {
           console.log(response)
           this.loaderActive = false
           this.memberInfo = response
           this.calcBook()
           store.dispatch('UPDATE_USER', {
             memberInfo : response
           })

           //  this.loaderActive = false;
         })
         .catch((error) => {
           var status = error.status
           this.loaderActive = false
           console.log(error)
           if (status == 0x80000003) 
             this.$router.push('/')

         })
      },
      calcBook()
      {
        this.numOfBook = 0
        this.numOfPend = 0
        Object.keys(this.memberInfo.booking).forEach(key => {
          this.numOfBook ++
          if (this.memberInfo.booking[key].cancel != null &&
            this.memberInfo.booking[key].cancel != '')
            this.numOfPend ++
        })
      }
    }
};
</script>