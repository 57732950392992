<template>
   <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h1 class="honeoverride">{{hotelname}}</h1>
                        <ul>
                          <li>{{city}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import store from '@/store'

export default {
  name: "HotelDetailBanner",
  data() {
    return {
      hotelname : store.getters.hotelname,
      city : store.getters.cityName,
    }
  },
  watch: {
    '$store.getters.hotelname' : function() {
      this.hotelname = store.getters.hotelname
      this.city = store.getters.cityName
    }
  }
}
</script>