<template>
    <div class="col-xl-6  col-lg-6  col-md-6 col-sm-12 col-12">
        <div class="flight_Search_boxed dropdown_passenger_area">
          <div class="row">
            <div class="col-4">
              <p>{{$t("adult")}}</p>
            </div>
            <div class="col-4">
              <p>{{$t("child")}}</p>
            </div>
            <div class="col-4">
              <p>{{$t("room")}}</p>
            </div>
          </div>
            <div class="dropdown">
              <button class="dropdown-toggle final-count" data-toggle="dropdown" type="button"
                      id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="width:100%">
                <div class="row">
                  <div class="col-4">
                    {{ pnumber }}
                  </div>
                  <div class="col-4">
                    {{ cnumber }}
                  </div>
                  <div class="col-4">
                    {{ rnumber }}
                  </div>
                </div>
           
              </button>

              <div class="dropdown-menu dropdown_passenger_info" aria-labelledby="dropdownMenuButton1">
                <div class="traveller-calulate-persons">
                  <div class="passengers">
                    <h6>{{$t("travelers")}}</h6>
                    <div class="passengers-types">
                      <div class="passengers-type">
                        <div class="text">
                          <span class="count pcount">
                            {{pnumber}}
                          </span>
                          <div class="type-label">
                            <p>{{$t("adult")}}</p>

                          </div>
                        </div>
                        <div class="button-set">
                          <button type="button" class="btn-add" @click.stop="increment">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button type="button" class="btn-subtract" @click.stop="decrement">
                            <i class="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="passengers-type">
                        <div class="text">
                          <span class="count ccount">
                            {{cnumber}}
                          </span>
                          <div class="type-label">
                            <p class="fz14 mb-xs-0">
                              {{$t("child")}}
                            </p>
                          </div>
                        </div>
                        <div class="button-set">
                          <button type="button" class="btn-add-c" @click.stop="incrementc">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button type="button" class="btn-subtract-c" @click.stop="decrementc">
                            <i class="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="passengers-type">
                        <div class="text">
                          <span class="count incount">
                            {{rnumber}}
                          </span>
                          <div class="type-label">
                            <p class="fz14 mb-xs-0">
                              {{$t("room")}}
                            </p>
                          </div>
                        </div>
                        <div class="button-set">
                          <button type="button" class="btn-add-in" @click.stop="incrementi">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button type="button" class="btn-subtract-in" @click.stop="decrementi">
                            <i class="fas fa-minus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="cabin-selection">
               <h6>Cabin Class</h6>
               <div class="cabin-list">
                   <button type="button" class="label-select-btn">
                       <span class="muiButton-label">Economy
                       </span>
                   </button>
                   <button type="button" class="label-select-btn active">
                       <span class="muiButton-label">
                           Business
                       </span>
                   </button>
                   <button type="button" class="label-select-btn">
                       <span class="MuiButton-label">First
                           Class </span>
                   </button>
               </div>
           </div> -->
                </div>
              </div>
            </div>
            <!--   <span>Business</span>-->
          </div>
    </div>
</template>
<script>
  import store from '@/store'

export default {
    name: "CountDropdownHotel",
    data() {
        return {
            number: 0,
            pnumber: store.getters.roomadult,
            cnumber: store.getters.roomchild,
            rnumber: store.getters.roomroom,
        };
    },
  watch: {
    '$store.getters.roomadult' : function() {
      this.pnumber = store.getters.roomadult
    },
    '$store.getters.roomchild' : function() {
      this.cnumber = store.getters.roomchild
    },
    '$store.getters.roomroom' : function() {
      this.rnumber = store.getters.roomroom
    }
  },
  methods: {
    savedata ()
    {
      store.dispatch('UPDATE_USER', {
        roomadult: this.pnumber,
        roomchild: this.cnumber,
        roomroom: this.rnumber,
      })
    },

        increment() {
          //   this.number++;
          if(this.pnumber === 36) return;
          if ((this.pnumber + this.cnumber ) >= this.rnumber * 4) return;
          this.pnumber++; 
          this.savedata();
        },

        decrement() {
           // if (this.number === 0) return
           // this.number -= 
          if (this.pnumber === 1) return  
          this.pnumber -= 1
          if (this.pnumber  < this.rnumber) this.rnumber = this.pnumber        
          if ((this.pnumber + this.cnumber ) > (this.rnumber * 4))
          {
            this.cnumber = (this.rnumber * 4) - this.pnumber;
            if (this.cnumber > 9) this.cnumber = 9
          }
          this.savedata();
        },

        incrementc() {
          // this.number++;
          if(this.cnumber === 9) return
          if ((this.pnumber + this.cnumber ) >= (this.rnumber * 4)) return
          if (this.rnumber*3 <= this.cnumber) return
          this.cnumber++; 
          this.savedata();
        },

        decrementc() {
          //  if (this.number === 0) return
          //  this.number -= 1
          if (this.cnumber === 0) return;     
          this.cnumber -= 1
          this.savedata();
        },

        incrementi() {
          //  this.number++; 
          if(this.rnumber === 9) return;   
          this.rnumber++;
          if (this.pnumber < this.rnumber) this.pnumber = this.rnumber   
          this.savedata();
        },

        decrementi() {
           // if (this.number === 0) return
          //  this.number -= 1
          if (this.rnumber === 1) return
          if ((this.pnumber + this.cnumber ) > (this.rnumber-1) * 4) return  
          this.rnumber -= 1
          this.savedata();
        },
    },
    mounted() {
      
    },
    expose: ["savedata"]
}
</script>