<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="tou_booking_form_Wrapper">
                        <div class="tour_booking_form_box mb-4">
                            <div class="booking_success_arae">
                                <div class="booking_success_img">
                                    <img src="../../assets/img/icon/right.png" alt="img">
                                </div>
                                <div class="booking_success_text">
                                    <h3>{{booking.name}}, {{$t('bookingcomp')}}</h3>
                                    <h6>{{$t('bookdetailseml')}} {{booking.email}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="booking_tour_form">
                            <h3 class="heading_theme">{{$t('bookdetails')}}</h3>
                            <div class="tour_booking_form_box">
                                <div class="your_info_arae">
                                    <ul>
                                        <li><span class="name_first">{{$t('yourname')}}</span> <span
                                                class="last_name">{{booking.name}}</span></li>
                                        <li><span class="name_first">{{$t('email')}}</span> <span
                                                class="last_name">{{booking.email}}</span></li>
                                        <li><span class="name_first">{{$t('bookhotelname')}}</span> <span
                                                class="last_name">{{booking.hotelName}}</span></li>
                                        <li><span class="name_first">{{$t('checkin')}}</span> <span class="last_name">{{booking.checkIn}}</span></li>
                                        <li><span class="name_first">{{$t('checkout')}}</span> <span
                                                class="last_name">{{booking.checkOut}}</span></li>
                                       <!-- <li><span class="name_first">{{$t('room')}}</span> <span class="last_name">{{booking.rooms}}</span>
                                        </li>-->
                                        <li><span class="name_first">{{booking.confirmationTitle}}</span> <span
                                                class="last_name">{{booking.confirmationId}}</span></li>
                                        <li><span class="name_first">{{booking.reservationTitle}}</span> <span v-for="(item,key) in booking.reservationId" class="last_name">{{item}}&nbsp;</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tour_details_right_sidebar_wrapper">
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>{{$t('booktotal')}}</h3>
                                </div>
                                <div class="tour_booking_amount_area">
                                    <ul>
                                        <li>{{$t("bookdate")}} <span>{{booking.date}}</span></li>
                                    </ul>
                                  
                                    <div class="total_subtotal_booking">
                                        <h6 class="remove_coupon_tour">{{$t("total")}} <span>{{booking.total}}</span> </h6>
                                    </div>
                                  <div class="tour_package_bar_price" v-if="(booking.isMemberFee)">
                                    <span class="name_first">+&nbsp;{{booking.memberPrice}}</span>  <span class="name_last" style="font-size:12px!important">{{$t("newmember")}}</span> 
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import store from '@/store'

export default {
  name: "TourBooking",
  data() {
    return {
      booking : {
        checkIn : '',
        checkOut : '',
        confirmationTitle : '',
        confirmationId : '',
        date : '',
        email : '',
        hotelName : '',
        name : '',
        reservationTitle : '',
        reservationId : [],
        total : '',
        isMemberFee : false,
        memberPrice : ''
      },
      isBookingComplete : true
    }
  },
  mounted() {

    var setBook = store.getters.bookComplete
    console.log(setBook)
    if (setBook.hasOwnProperty('checkIn')) this.booking['checkIn'] = setBook.checkIn
    if (setBook.hasOwnProperty('checkOut')) this.booking['checkOut'] = setBook.checkOut
    if (setBook.hasOwnProperty('confirmationTitle')) this.booking['confirmationTitle'] = setBook.confirmationTitle
    if (setBook.hasOwnProperty('confirmationId')) this.booking['confirmationId'] = setBook.confirmationId
    if (setBook.hasOwnProperty('date')) this.booking['date'] = setBook.date
    if (setBook.hasOwnProperty('email')) this.booking['email'] = setBook.email
    if (setBook.hasOwnProperty('hotelName')) this.booking['hotelName'] = setBook.hotelName
    if (setBook.hasOwnProperty('name')) this.booking['name'] = setBook.name
    if (setBook.hasOwnProperty('reservationTitle')) this.booking['reservationTitle'] = setBook.reservationTitle
    if (setBook.hasOwnProperty('reservationId')) this.booking['reservationId'] = setBook.reservationId
    if (setBook.hasOwnProperty('total')) this.booking['total'] = setBook.total
    if (setBook.hasOwnProperty('paymentCCAmount'))
    {
      this.booking['isMemberFee'] = true
      this.booking['memberPrice'] = setBook.paymentCCAmount
    }

    store.dispatch('UPDATE_USER', {
      isBookingComplete : this.isBookingComplete
    })
  }
};
</script>