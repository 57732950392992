<template>
    <section id="common_banner">
        <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="banner_one_text">
                  <h1> {{$t("upto")}} 75% {{$t("offhotels")}} </h1>
                  <h3>{{$t("over600")}}</h3>

                </div>
              </div>
          
                    <!--  <div class="common_bannner_text">
          <h2>Login</h2>
          <ul>
              <li><router-link to="/">Home</router-link></li>
              <li><span><i class="fas fa-circle"></i></span> Login</li>
          </ul>

      </div> -->
                  
              
            </div>
        </div>
    </section>
  <div class="row">
    <div class="col-lg-6">
      <section id="hometwo_clinet_area" class="section_padding">
        <div class="container">
          <vue-widget title="FlyStayGoHome" />
        </div>
      </section>
    </div>
    <div class="col-lg-6">
      <ClientSays />
    </div>
  </div>
</template>
<script>
  import ClientSays from '@/components/hometwo/ClientSays.vue'

export default { //vuidget/css/app.css"
  name: "LoginBanner",
  components: {
    ClientSays//, CommonAuthorFour
  },
  created()
  {
    var url = this.$route.fullPath
    var param = this.$route.params.headparam
 
    if (url.search("#") == -1 )
    {
      if ( param !== '')
        window.location.href = "/pro#/register?promo=" + param.toUpperCase()
      else
        window.location.href = "/pro#/register"
 
    }
    else if(url.search("/#/") != -1)
    {
      var str = url.split("/#/")
      window.location.href = /pro#/ + str[1]
    }
  },
   mounted() {

     
       const scripts = [
          "vuidget/js/app.js" 
       ];
       scripts.forEach(script => {
         let tag = document.head.querySelector(`[src="${ script }"`);
         if (!tag) {
           tag = document.createElement("script");
           tag.setAttribute("src", script);
           tag.setAttribute("type", 'text/javascript');
           document.head.appendChild(tag); 
         }
       }); 
   
     
   }
};
</script>
<style src="@/assets/css/app.css">

</style>

 