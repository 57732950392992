<template>

    <!-- Common Banner Area -->
    <POImainBanner />

    <!-- Privacy Policy Areas -->
    <POImainContent />

    <!-- Cta Area 
    <Cta />-->

</template>
<script>
import POImainBanner from '@/components/pages/POImainBanner.vue'
import POImainContent from '@/components/pages/POImainContent.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
  name: "POImainView",
    components: {
      POImainBanner, POImainContent//, Cta
    }
};
</script>
