<template>
     <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                      <div class="terms_item">
                        <h2>{{$t("review")}}</h2>
                        <br /><br />
                        <h4>{{$t("flypromsg")}}</h4>
                        <p>
                          {{$t("flyproasst")}}<br />
                          <li><u><a href="https://hotels.flystaygo.com/v6/cancel-or-resend">{{$t("clickortap")}}</a></u> {{$t("flyproOne")}}</li>
                          <li>{{$t("flyproTwo")}}</li>
                        </p> 

                      </div>
                      <div class="terms_item">

                        <h4>{{$t("tourmsg")}}</h4>
                        <p>
                          {{$t("tourasst")}}<br />
                          <li><u><a href="https://www.viator.com/itinerary-login">{{$t("clickortap")}}</a></u> {{$t("tourOne")}}</li>

                        </p>


                      </div>
                      <div class="terms_item">
                        
                        <h4>{{$t("flymsg")}}</h4>
                        <p>
                          {{$t("flyasst")}}<br />
                          <li><u><a href="https://secure.rezserver.com/hotels/help/review/?refid=7513&refclickid=NONE">{{$t("clickortap")}}</a></u> {{$t("flyOne")}}</li>
                          <li>{{$t("flyTwo")}}</li>
                        </p>


                      </div>  
                        <div class="terms_item">
                         <br /><br />
                          <h4>{{$t("custphone")}}</h4>
                          <p>
                            {{$t("custphoneinfo")}}<br />
                       
                          </p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
  name: "ReviewCancelContent"
};
</script>