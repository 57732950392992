<template>
 
    <section id="tour_details_main" class="section_padding">
      <modal :active="modalVisible" @closing="(i) => modalVisible = i" :data="modalData" :confirm="modalConfirm"/>

      <Loader :active="loaderActive" />
        <div class="container">
            <div class="row">
                
                <div class="col-lg-8 col-12">
                    <div class="tour_details_leftside_wrapper">
                        <div class="tour_details_heading_wrapper" v-show="isNoHotels==null">
                            <div class="tour_details_top_heading">
                                <h2>{{hotelname}}</h2>
                                <h5><a href="#map"><i class="fas fa-map-marker-alt"></i> {{address}}</a></h5>
                            </div>
                            <div class="tour_details_top_heading_right">
                                <p v-html="getRankStars(stars)"></p>
                              <span class="circle_size" v-html="getRankCircle(userRate)" v-show="(userCount)"></span><span class="review_rating">&nbsp; {{ userRate }} / 5</span>
                              <p v-show="userCount!=0">({{userCount}} {{$t("reviews")}})</p>
                            </div>
                        </div>
                        <ul class="tour_details_top_bottom" v-show="isNoHotels==null">
                            <li class="toru_details_top_bottom_item" v-show="amenities.fb">
                                <a href="#amenities" :title="$t('freeBreak')" class="tour_details_top_bottom_icon">
                                    <i class="fas fa-coffee"></i>
                                </a>
                           
                            </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.sw">
                            <a href="#amenities" :title="$t('pool')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-swimmer"></i>
                            </a>
                            
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.fw">
                            <a href="#amenities" :title="$t('freeWifi')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-wifi"></i>
                            </a>
                       
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.fp">
                            <a href="#amenities" :title="$t('FreePark')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-car"></i>
                            </a>
                         
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.pa">
                            <a href="#amenities" :title="$t('pets')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-paw"></i>
                            </a>
                           
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.ns">
                            <a href="#amenities" :title="$t('nonSmoke')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-smoking-ban"></i>
                            </a>
                          
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.as">
                            <a href="#amenities" :title="$t('shuttle')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-shuttle-van"></i>
                            </a>
                         
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.fc">
                            <a href="#amenities" :title="$t('fitness')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-dumbbell"></i>
                            </a>
                          
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.ac">
                            <a href="#amenities" :title="$t('access')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-wheelchair"></i>
                            </a>
                          
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.re">
                            <a href="#amenities" :title="$t('foodOnsite')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-utensils"></i>
                            </a>
                        
                          </li>
                          <li class="toru_details_top_bottom_item" v-show="amenities.ki">
                            <a href="#amenities" :title="$t('kitchen')"  class="tour_details_top_bottom_icon">
                              <i class="fas fa-blender"></i>
                            </a>
                          
                          </li>
                        </ul>
                        <div class="tour_details_img_wrapper" v-show="isNoHotels==null">
                            <div class="image__gallery">
                                <div class="gallery">
                                    <div class="gallery__slideshow">
                                        <div class="gallery__slides">
                                            <div class="gallery__slide">
                                                <img class="gallery__img" :src="slide.img" alt=""
                                                    v-for="(slide, index) in gallerySlides" :key="index" :style="
                                                        slideIndex === index
                                                            ? 'display:block;'
                                                            : 'display:none;'
                                                    " />

                                            </div>
                                        </div>
                                        <a @click.prevent="move(1)" class="gallery__prev">&#10095;</a>
                                        <a @click.prevent="move(-1)" class="gallery__next">&#10094;</a>
                                    </div>

                                    <div class="gallery__content">
                                        <div class="gallery__items">

                                            <div class="gallery__item" :class="{
                                                'gallery__item--is-acitve': slideIndex === index
                                            }" v-for="(slide, index) in gallerySlides" :key="`item-${index}`">
                                                <img :src="slide.img" @click="currentSlide(index)"
                                                    class="gallery__item-img" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tour_details_boxed" v-show="isNoHotels==null">
                            <h3 class="heading_theme">{{$t("hoteldec")}}</h3>
                            <div class="tour_details_boxed_inner">
                                <p>{{description}}</p>
                           
                            </div>
                        </div>
                        <div class="tour_details_boxed">
                            
                            <div class="room_select_area">
                               
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="room_booking_area">
                                          <div  v-show="isNoHotels!=null">
                                            <h4 class="error_red">{{$t("norooms")}}</h4>
                                            <p>{{isNoHotels}}</p>
                                            <p>{{isNoHotelsMess}}</p>
                                           </div>
                                            <div class="tour_search_form">
                                              <Form ref="formAccess" />
                                              <div class="top_form_search_button_nospace text-right">
                                                <button class="btn btn_theme btn_md" @click="prepDetails()" type="button">
                                                  {{$t("updateresults")}}
                                                </button>
                                                <p class="error_red" v-show="isErrDate">{{$t("errorDate")}}</p>
                                              </div>
                                            </div>
                                          <h3 class="heading_theme" id="roomstart">{{$t("chooseroom")}}</h3>
                                            <div v-for="(rate, index) in rates" class="room_book_item" v-show="isNoHotels==null">
                                               <!-- <div class="room_book_img">
                                                    <img src="../../assets/img/hotel/room-1.png" alt="img">
                                                </div> -->
                                                <div class="room_booking_right_side">
                                                  <div class="row">
                                                    <div class="col-lg-8 col-md-8 col-12 room_booking_heading">
                                                        <h3><router-link :class="['faux-a',{inbtnpause : rate.pausedFor5seconds}]" to="/room-booking"  v-on:click="setRate(rate, index)">{{rate.name}}</router-link></h3>
                                                        <p>{{rate.description}}</p>
                                                      <a href="javascript:void(0)" v-show="(rate.nightlyRate.length)" @click="openModal(rate.nightlyRate)" class="faux-b"><u>{{$t('dailyrates')}}</u></a>&nbsp;
                                                      <a href="javascript:void(0)" @click="openModalCancel(rate.cancelNote)" class="faux-b"><u>{{$t('cancellation')}}</u></a>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12 room_person_select">
                                                      <p class="discount" v-show="(rate.discount)">{{$t("totalsavings")}} {{displayCurrency}}{{rate.discount}}</p>
                                                      <p class="retail_price" v-show="(rate.retailPrice && rate.discount)">{{(rate.price) ? $t('pernight') : $t('firstnight') }} {{displayCurrency}}{{rate.retailPrice}}</p>
                                                      <img v-show="site=='arn'" src='@/assets/img/icon/pro.png' width="75" /><router-link to="/room-booking" v-on:click="setRate(rate, index)" :class="['inbtnfull',{inbtnpause : rate.pausedFor5seconds}]">{{(rate.price) ? $t('pernight') : $t('firstnight') }}
                                                        <span class='inprice'>{{displayCurrency}}{{(rate.price) ? rate.price : rate.firstNight}}</span>
                                                      </router-link>

                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                    
                                       
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                      
                        <div class="tour_details_boxed" id="map">
                            <h3 class="heading_theme">{{$t("hotelloc")}}</h3>
                          <div class="map-sticky"  style="" v-bind:key="mapChange">
                            <!--<iframe class="map-sticky" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"></iframe> -->
                            <MapContainer ref="mapContainer"></MapContainer>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <div class="tour_details_right_sidebar_wrapper" id="amenities">
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>{{$t("pricefrom")}} </h3>&nbsp;
                                  <a href="#roomstart" class='inbtnfull' target="_self" >{{$t("chooseroom")}}</a>
                                </div>
                              <p v-show="(firstRate.discount)" class="discount">{{$t("totalsavings")}} {{displayCurrency}}{{firstRate.discount}}</p>
                                <div class="tour_package_bar_price" v-show="Array.isArray(rates)">
                                  <span v-show="(firstRate.discount)"><del>{{displayCurrency}}{{firstRate.retailPrice}}</del></span>
                                  <h3 v-show="firstRate.firstNight">{{displayCurrency}}{{firstRate.firstNight}} <sub>{{$t("firstnight")}}</sub> </h3> 
                                  <h3 v-show="firstRate.price">{{displayCurrency}}{{firstRate.price}} <sub>{{$t("pernight")}}</sub> </h3> 
                                </div>

                                <div class="tour_package_details_bar_list">
                                    <h5>{{$t("amenities")}}</h5>
                                    <ul>
                                        <li v-for="(amenity, index) in amenityList" ><i class="fas fa-circle"></i>{{amenity}}</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>

import store from '@/store'
import Loader from '@/components/Loader.vue'
import MapContainer from '@/components/MapContainer.vue'
import modal from '@/components/Modal.vue'
import Form from '@/components/hotel/Form.vue'

export default {
    name: "HotelSearch",
    components: {
      Loader, 
      MapContainer,
      Form,
      modal
    },
    data() {
      return {
        modalVisible: false,
        modalData: null,
        modalConfirm : null,
          isErrDate : false,
          loaderActive: false,
          isNoHotels : null, 
          isNoHotelsMess : null,
          searchKey: store.getters.roomKey,
          site: store.getters.roomSite,
          siteId: store.getters.roomSiteId,
          otaId: store.getters.roomOtaId,
          hotelname: store.getters.hotelname,
          address: null,
          mapChange: 0,
          amenityList : [],
          amenities : [],
          stars : 0.0,
          userRate : 0.0,
          userCount : 0,
          description : null,
          rates : [],
          firstRate : [],
          restoreArray : {},
          displayCurrency : '$',

            gallerySlides: [
             
            ],

            slideIndex: 0,

         /*   slides: [
                { img: require('@/assets/img/hotel/small-1.png') },
                { img: require('@/assets/img/hotel/small-2.png') },
                { img: require('@/assets/img/hotel/small-3.png') },
                { img: require('@/assets/img/hotel/small-4.png') },
                { img: require('@/assets/img/hotel/small-5.png') },
                { img: require('@/assets/img/hotel/small-6.png') }
            ]*/

        }
    },
    created(){
      this.loaderActive = true
    },
    mounted() {

      window.onpageshow = function(event) {
        if (event.persisted) {
          window.location.reload();
        }
      };

 

      var searchActive = true
      
      this.$refs.mapContainer.clearPins()
      console.log(this.siteId)
      if (this.siteId == null)
      {
        if (this.$route.query.k)
          this.searchKey = this.$route.query.k
        else
          searchActive = false
        console.log("Do we have a query" + this.searchKey)

        if (this.$route.query.s)
          this.site = this.$route.query.s
        else
          searchActive = false  

        if (this.$route.query.o)
          this.otaId = this.$route.query.o
        else
          searchActive = false

        if (this.$route.query.i && searchActive)
          this.siteId = this.$route.query.i
        else
          searchActive = false    

        console.log("search active ?" + searchActive)
      }
      console.log("search active ?" + searchActive)
 
      this.$nextTick(() => {    
        store.dispatch('UPDATE_USER', {
          mapMinZoom : 0,
          mapMaxZoom : 17,
        })
      })

      if (searchActive)
        this.fetchDetails()
      else
        this.loaderActive = false
    },
    methods: {
      openModal(data) {
        var output = ""
        Object.keys(data).forEach(key => {
          if (data[key]) {
            output += "<p><b><u>"+ data[key].date + "</u></b><br></p>"
            output += "<span>" + this.displayCurrency + data[key].price + "</span><br>"
          //  if (data[key].discount) output += "<span class='discount'>" + this.$t("totalsavings") + " " + this.displayCurrency + data[key].discount + "</span><br>"
            output += "<br>"
            
          }
        });
        this.modalData = output
        this.modalConfirm = this.$t('ok')
        this.modalVisible = true
      },
      openModalCancel(data) {
        var output = ""
        if (data.length)
          output = data
        else 
          output = this.$t("cancelpolicy")
       
        this.modalData = output
        this.modalConfirm = this.$t('ok')
        this.modalVisible = true
      },
      setRate(rate, key) // store rate info for booking
      {
        if (this.rates[key].pausedFor5seconds == false)
        {
          this.rates[key].pausedFor5seconds = true
          setTimeout(() => this.rates[key].pausedFor5seconds = false, 5000);

          store.dispatch('UPDATE_USER', {
            bookRpCode: rate.rpCode,
            bookRtCode: rate.rtCode,
            bookMisc: rate.misc,
            isBookingComplete : false
          })
        }
      },
      prepDetails()  // prep information for a change to the date or occupancy
      {
        var todayStr = new Date()
        todayStr.setHours(0,0,0,0); // make it midnight...
        var today = todayStr.getTime()
        if (today > 4294967295) today -= 86400000
        else today -= 86400

        var ind = this.$refs.formAccess.getIndate()
        var outd = this.$refs.formAccess.getOutdate()
        console.log("FORM DATES -- " + ind + " " + outd)
        let inDate = new Date(ind + " 00:00:00").getTime()
        let outDate = new Date(outd + " 00:00:00").getTime()
        console.log("CHECK -- " + inDate + ">=" + today + " & " + outDate)
        this.isErrDate = (inDate >= today && outDate > inDate) ? false : true
        if (this.isErrDate == false)
        {
          
          this.$refs.formAccess.changeDate()
          this.$urlRequest.cookies()
          this.fetchDetails()
        }
        else // if there is an error, reset the dates back to a valid value..
        {

          store.dispatch('UPDATE_USER', {
            roomin : this.restoreArray.roomin,
            roomout : this.restoreArray.roomout,
            roomadult : this.restoreArray.roomadult,
            roomchild : this.restoreArray.roomchild,
            roomroom : this.restoreArray.roomroom,
           
          })
        }
      },
      fetchDetails()  // fetch info for the details page
      {
        var rooms = store.getters.roomroom
        console.log("WHAT IS ROOMS " + rooms)
        var arr = {'site' : this.site,
          'siteId' : this.siteId,
          'otaId' : this.otaId,
          'userLanguage' : store.getters.userLanguage,
          'userCurrency' : store.getters.userCurrency 
        }

        if (rooms > 0)
        {
          arr['checkin'] = store.getters.roomin
          arr['checkout'] = store.getters.roomout
          arr['adults'] = store.getters.roomadult
          arr['childreb'] = store.getters.roomchild
          arr['rooms'] = store.getters.roomroom
        }
        else
          arr['key'] = this.searchKey

        arr['promo'] = store.getters.promo
        console.log("VARS PASSED---- ")
        console.log(arr)
        this.isNoHotels = null
        console.log(arr)
        this.loaderActive = true;
        this.$urlRequest.post('fetch', 'hotelDetail', arr)    // {'text' : this.dest, 'type' : 'hotels'}
         .then((response) => {
           //   this.$nprogress.done()
           //this.loading = false
           console.log(response)
           this.amenityList = response.amenityList
           this.amenities = response.amenities
           this.loaderActive = false
           this.hotelname = response.name
           this.address = response.address
           this.stars = response.stars
           this.userRate = response.userRate
           this.userCount = response.userCount
           this.description = response.description
           this.rates = response.ratePlan
           if (this.rates.length > 0)
           {
             this.firstRate = this.rates[0]

             Object.keys(this.rates).forEach(key => {
               this.rates[key].pausedFor5seconds = false
             });
           }
           console.log(this.firstRate)
           this.displayCurrency = response.displayCurrency

           this.gallerySlides = []
           Object.keys(response.images).forEach(key => {
             this.gallerySlides.push( {'img' : response.images[key]})
           });

           this.$refs.mapContainer.addPin(response.latitude, response.longitude, 0, '','')
           this.$refs.mapContainer.updatePos(response.latitude, response.longitude, 12)
           this.$nextTick(() => {    
             this.mapChange = ((this.mapChange+1) & 511)
           })
           // lets see if we can clear the dates to do something
           this.$refs.formAccess.clearDate()
           // setup restore array in case we try to change dates and stuff, but it fails
           this.restoreArray = { 'roomin' : response.checkin,
             'roomout' : response.checkout,
             'roomadult' : parseInt(response.adults),
             'roomchild' : parseInt(response.children),
             'roomroom' : parseInt(response.rooms),
           }

           store.dispatch('UPDATE_USER', {
             hotelname: response.name,
             roomSite: this.site,
             roomSiteId: this.siteId,
             roomOtaId: this.otaId,
             roomKey: this.searchKey,
             roomin: response.checkin,
             roomout: response.checkout,
             roomadult: parseInt(response.adults),
             roomchild: parseInt(response.children),
             roomroom: parseInt(response.rooms),
              //  city : response.info.city,
              // cityName : response.info.cityName,
              // cityLat : response.info.latitude,
              // cityLong : response.info.longitude,
             userCurrency : store.getters.userCurrency,
             userLanguage : store.getters.userLanguage,
             displayCurrency : response.displayCurrency
           })
         })
          .catch((error) => {
            this.loaderActive = false;
            console.log(error)
            this.isNoHotels = (!error.title) ? error.code : error.title
            this.isNoHotelsMess = (!error.message) ? '' : error.message
          })

      },
      getRankCircle(rank){

        // Round down to get whole stars:
        var wStars = Math.floor(rank);
        // Check if whole is less than rank.
        // If less than rank, a half star is needed:
        var halfStars = (wStars < rank);

        var output="";
        //Loop through five stars:
        for(let i=1;i<=5;i++){
          //Less than or equal to stars, display a solid star:
          if(i<=wStars){
            output+="</i><i class='fas fa-circle' style='color:#007ca0'></i>";

            //If interation is after a whole star and a half star is needed, display half star:
          }else if( i==wStars+1 && halfStars==true ){
            output+="<i class='fas fa-adjust' style='color:#007ca0'></i>";

            //Otherwise, display a gray empty star:
          }else{
            output+="<i class='far fa-circle' style='color:#bfbfbf'></i>";
          }
        }
        return output;
      },

    getRankStars(rank){

      // Round down to get whole stars:
        var wStars = Math.floor(rank);
      // Check if whole is less than rank.
      // If less than rank, a half star is needed:
        var halfStars = (wStars < rank);
   
        var output="";
      //Loop through five stars:
        for(let i=1;i<=5;i++){
          //Less than or equal to stars, display a solid star:
          if(i<=wStars){
            output+="</i><i class='fas fa-star' style='color:#fbcc05'></i>";

            //If interation is after a whole star and a half star is needed, display half star:
          }else if( i==wStars+1 && halfStars==true ){
            output+="<i class='fas fa-star-half-alt' style='color:#fbcc05'></i>";

            //Otherwise, display a gray empty star:
          }else{
            output+="<i class='far fa-star' style='color:#bfbfbf'></i>";
          }
        }
        return output;
      },
        move(n) {
            if (this.gallerySlides.length <= this.slideIndex + n) {
                this.slideIndex = 0
            } else if (this.slideIndex + n < 0) {
                this.slideIndex = this.gallerySlides.length - 1
            } else {
                this.slideIndex += n
            }
        },
        currentSlide(index) {
            this.slideIndex = index
        },

    }
};
</script>