<template>

    <!-- Common Banner Area -->
    <ComingSoonBanner />

    <!-- Tour Booking Submission Areas -->
    <ComingSoon />

    <!-- Cta Area 
    <Cta />-->

</template>
<script>
import ComingSoonBanner from '@/components/pages/ComingSoonBanner.vue'
import ComingSoon from '@/components/pages/ComingSoon.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
    name: "ComingSoonView",
    components: {
      ComingSoonBanner, ComingSoon//, Cta
    }
};
</script>