<template>

    <!-- Common Banner Area -->
    <ReviewCancelBanner />

    <!-- Privacy Policy Areas -->
    <ReviewCancelContent />

    <!-- Cta Area 
    <Cta />-->

</template>
<script>
import ReviewCancelBanner from '@/components/pages/ReviewCancelBanner.vue'
import ReviewCancelContent from '@/components/pages/ReviewCancelContent.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
  name: "ReviewCancelView",
    components: {
      ReviewCancelBanner, ReviewCancelContent//, Cta
    }
};
</script>
