<template>
 <!-- <section id="theme_search_form"> 
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
           

            <div class="tab-content" id="myTabContent">-->

              <div class="tab-pane show active" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="tour_search_form">
                      <form v-on:submit.prevent="beginSearch">
                        <div class="row">
                         
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="flight_Search_boxed">
                              <div class="room_details_facilities">
                                <span class="Journey_date">
                                  <p>{{$t("checkin")}}&nbsp;&nbsp;-&nbsp;&nbsp;{{$t("checkout")}}</p>
                                 <!-- <input type="date" onclick="this.showPicker()" v-model="indate" v-on:change="changeDate">
                                   <span>Thursday</span> -->
                                </span>
                                <span class="Journey_date">
                                  <p>{{$t("resetDate")}}</p>
                                 <!-- <input type="date" onclick="this.showPicker()" v-model="outdate" v-on:change="changeDate"> --ref="outButton" --
                                   <span>Thursday</span> -->
                                </span>
                              </div>
                              <div>
                                <VueDatePicker onmousedown="return false" onselectstart="return false" v-model="date"
                                               :teleport-center="pickerPos"
                                               range
                                               multi-calendars
                                               auto-apply
                                               prevent-min-max-navigation
                                               hide-input-icon
                                               :enable-time-picker="false"
                                               format="MM/dd/yyyy"
                                               :min-date="getDate(-1)" />
                              </div>
                            </div>
                            <span class="error_red" v-show="isErrDate">{{$t("errorDate")}}</span>
                          </div>
                          <CountDropdownDetail ref="countDropdown"/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
     <!--        </div>
          </div> 
        </div>
      </div>
    </div>
  </section>-->
</template>
<script>

import CountDropdownDetail from '@/components/hotel/CountDropdownDetail.vue'
import store from '@/store'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: "Form",

    components: {

      CountDropdownDetail,
      VueDatePicker
    },


    data() {
      console.log("date " +store.getters.roomin + " " + store.getters.roomout) // https://vue3datepicker.com/installation/
      var ind = new Date(store.getters.roomin + " 00:00:00");
      var outd = new Date(store.getters.roomout + " 00:00:00");
      console.log(ind)
      console.log(outd)
      return {
        indate: store.getters.roomin,
        outdate: store.getters.roomout,
        date: [ind, outd], 
        destId : '',
        data :[],
        errors : [],
        pickerPos : false,
        isOpen: false,
        isErrLoc: false,
        isErrDate: false,
        inDatePrev : new Date().toISOString().substr(0,10),
        rowData: [{ name: '' }]
        };
    },
  watch: {
    '$store.getters.roomin' : function() {
      this.indate = store.getters.roomin
      console.log("IN DATE IS NOW VALID -- " + this.indate)
      var ind = new Date(store.getters.roomin + " 00:00:00");
      var outd = new Date(store.getters.roomout + " 00:00:00");
      this.date = [ind,outd]
    },
     '$store.getters.roomout' : function() {
       this.outdate = store.getters.roomout
       var ind = new Date(store.getters.roomin + " 00:00:00");
       var outd = new Date(store.getters.roomout + " 00:00:00");
       console.log("OUT DATE IS NOW VALID -- " + this.outdate)
       this.date = [ind,outd]
    }, 
    'date' : function() {

      console.log(this.date)
      if (this.date == null)
      {
        this.indate = store.getters.roomin;
        this.outdate = store.getters.roomout;

        this.date = [new Date(store.getters.roomin + " 00:00:00") , new Date(store.getters.roomout + " 00:00:00")]
      }
      else
      {
        var d = new Date(this.date[0].getTime() - this.date[0].getTimezoneOffset()*60000);
        this.indate = d.toISOString().substr(0,10)
        d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
        this.outdate = d.toISOString().substr(0,10)

        if(this.indate == this.outdate)
        {
          this.date[1] = new Date(this.date[1].setDate(this.date[1].getDate() + 1));
          d = new Date(this.date[1].getTime() - this.date[1].getTimezoneOffset()*60000);
          this.outdate = d.toISOString().substr(0,10)
        }
      }

      console.log("Dates ----")
      console.log(this.indate)
      console.log(this.outdate)
    },
   /* 'indate' : function () {
        
      let inDate = new Date(this.indate).getTime()
      let outDate = new Date(this.outdate).getTime()
      console.log("TESTING " + outDate + " " + inDate)
      if (outDate <= inDate)
      {
        let inDateStamp = new Date(this.indate)
        inDateStamp.setDate(inDateStamp.getDate() + 1)
        this.outdate = inDateStamp.toISOString().substr(0,10)
      }
      else if ((outDate > 4294967295 && outDate  > (inDate + 86400000*28)) ||
                (outDate < 4294967295 && outDate > (86400*28 + inDate)))
      {
        let inDateStamp = new Date(this.indate)
        inDateStamp.setDate(inDateStamp.getDate() + 28)
        this.outdate = inDateStamp.toISOString().substr(0,10)
      }
      console.log("IN DATE ========= " + this.indate)
      *    var newDate = this.indate.split('-')
      var oldDate = this.inDatePrev.split('-')
     if (newDate[2] != oldDate[2])
      {
        this.inDatePrev = this.indate
        const elem = this.$refs.outButton
        elem.click()
      }*
      
     
    }, */
  },
  methods: {
    getDate: function(adjDate) {
      var d = new Date()
      d.setHours(0,0,0,0); // make it midnight...
      if (adjDate != 0) d.setDate(d.getDate() + adjDate)

      var newd = new Date(d.getTime() - d.getTimezoneOffset()*60000);
      var outdate = newd.toISOString().substr(0,10)
      return outdate
    }, 
    getIndate: function() {
      return this.indate
    },
    getOutdate: function() {
      return this.outdate
    },
    clearDate() {
      console.log("RESET DATES")
      this.date = null
    },
    changeDate()
    {
      this.$refs.countDropdown.savedata() // save the adults/children/rooms
      store.dispatch('UPDATE_USER', {

        roomin: this.indate,
        roomout: this.outdate,
      })
    },
    
   
    beginSearch()
    {

  /*    var todayStr = new Date().toISOString().substr(0,10)
      var today = new Date(todayStr)
      let inDate = new Date(this.indate)
      let outDate = new Date(this.outdate)

      this.isErrLoc = (this.destId.length == 0) ? true : false
      this.isErrDate = (inDate >= today && outDate > inDate) ? false : true

      if (this.isErrLoc == false && this.isErrDate == false)
      {
        console.log("looks like we made it!!! ")

        store.dispatch('UPDATE_USER', {

          roomin: this.indate,
          roomout: this.outdate,
        })
        this.$router.push({ path: '/hotel-search' })
      }
      */
    },
 
   
 

    },
      mounted() {

        //this.items = data.hotelData
        let size = window.innerWidth 
        this.mapHeight = window.innerHeight
        this.pickerPos = (size >= 992) ? false : true

        window.addEventListener('resize', () => {
          let size = window.innerWidth 
          this.mapHeight = window.innerHeight
          this.pickerPos = (size >= 992) ? false : true
        })
      },
  expose: ['changeDate', 'getIndate', 'getOutdate', 'clearDate']
};
</script>