<template>

    <!-- Common Banner Area -->
    <ProBanner />

    <!--  Common Author Area 
    <CommonAuthorFour />-->
    <TopService />
    <About /> 
    <FindTravellPartner /> 
   
    <Partners />

</template>
<script>
import ProBanner from '@/components/user/ProBanner.vue'
//import CommonAuthorFour from '@/components/user/CommonAuthorFour.vue'
import Form from '@/components/home/Form.vue'
   import TopService from '@/components/hometwo/TopService.vue'
   import About from '@/components/hometwo/About.vue'
 import FindTravellPartner from '@/components/hometwo/FindTravellPartner.vue'

import Partners from '@/components/home/Partners.vue'

export default {
    name: "Pro",
    components: {
      ProBanner, Form, TopService,  About, FindTravellPartner,  Partners //, CommonAuthorFour
    }
};
</script>