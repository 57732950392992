<template>
  <div v-show="isShow">
    <footer id="footer_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="footer_heading_area">
              <h5>{{$t("footsupport")}}</h5>
            </div>
            <div class="footer_first_area">
              <div class="footer_inquery_area" v-if="isLogin!=null && isLogin!=''">
                <router-link to="/my-profile" ><i class="fas fa-user"></i> {{$t('userprof')}}</router-link>
              </div>

              <div class="footer_inquery_area">

                <p> {{$t("footsuptext")}}</p>
              </div>

              <div class="footer_inquery_area">
                <h5>{{$t("followus")}}</h5>
                <ul class="soical_icon_footer">
                  <li><a href="https://www.facebook.com/FlyStayGo/" v-bind:target="urlDirct()"><i class="fab fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/flystaygo" v-bind:target="urlDirct()"><i class="fab fa-twitter-square"></i></a></li>
                  <li><a href="https://www.instagram.com/flystaygo/" v-bind:target="urlDirct()"><i class="fab fa-instagram"></i></a></li>
                  <li><a href="https://www.youtube.com/@flystaygo3535" v-bind:target="urlDirct()"><i class="fab fa-youtube"></i></a></li>
                  &nbsp;
                  <li><a href="https://play.google.com/store/apps/details?id=com.MajorCoupLLC.FlyStayGo&hl=en_US" v-bind:target="urlDirct()"><i class="fab fa-android"></i></a></li>
                  <li><a href="https://apps.apple.com/us/app/flystaygo/id1505373358" v-bind:target="urlDirct()"><i class="fab fa-apple"></i></a></li>
                </ul>
              </div>
            </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-md-5  offset-md-1 col-sm-5  offset-sm-1 col-12">
          <div class="footer_heading_area">
            <h5>{{$t("company")}}</h5>
          </div>
            <div class="footer_link_area">
              <ul>
                <li><router-link to="/info/contact">{{$t("Contactus")}}</router-link></li>
                <li><router-link to="/info/faq">{{$t("faq")}}</router-link></li>
                <li><router-link to="/info/cancel">{{$t("review")}}</router-link></li>
                <li><router-link to="/info/terms">{{$t("terms")}}</router-link></li>
                <li><router-link to="/info/privacy">{{$t("privacy")}}</router-link></li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
               <div class="footer_heading_area">
                   <h5>Support</h5>
               </div>
               <div class="footer_link_area">
                   <ul>
                       <li><router-link to="/dashboard">Account</router-link></li>
                       <li><router-link to="/faq">Faq</router-link></li>
                       <li><router-link to="/testimonials">Legal</router-link></li>
                       <li><router-link to="/contact">Contact</router-link></li>
                       <li><router-link to="/top-destinations"> Affiliate Program</router-link></li>
                       <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                   </ul>
               </div>
           </div>
           <div class="col-lg-2 col-md-4 col-sm-6 col-12">
               <div class="footer_heading_area">
                   <h5>Other Services</h5>
               </div>
               <div class="footer_link_area">
                   <ul>
                       <li><router-link to="/top-destinations-details">Community program</router-link></li>
                       <li><router-link to="/top-destinations-details">Investor Relations</router-link></li>
                       <li><router-link to="/flight-search-result">Rewards Program</router-link></li>
                       <li><router-link to="/room-booking">PointsPLUS</router-link></li>
                       <li><router-link to="/testimonials">Partners</router-link></li>
                       <li><router-link to="/hotel-search">List My Hotel</router-link></li>
                   </ul>
               </div>
           </div>
           <div class="col-lg-2 col-md-4 col-sm-6 col-12">
               <div class="footer_heading_area">
                   <h5>Top cities</h5>
               </div>
               <div class="footer_link_area">
                   <ul>
                       <li><router-link to="/room-details">Chicago</router-link></li>
                       <li><router-link to="/hotel-details">New York</router-link></li>
                       <li><router-link to="/hotel-booking">San Francisco</router-link></li>
                       <li><router-link to="/tour-search">California</router-link></li>
                       <li><router-link to="/tour-booking">Ohio </router-link></li>
                       <li><router-link to="/tour-guides">Alaska</router-link></li>
                   </ul>
               </div>
           </div> -->
        </div>
      </div>
    </footer>
    <div class="copyright_area">
      <div class="container">
        <div class="row align-items-center">
          <div class="co-lg-6 col-md-6 col-sm-12 col-12">
            <div class="copyright_left">
              <p>Copyright © {{new Date().getFullYear()}}  {{$t("copyright")}} &nbsp;&nbsp;&nbsp;v:2.18</p>
            </div>
          </div>
          <div class="co-lg-6 col-md-6 col-sm-12 col-12">
            <div class="copyright_right">
              <img src="../assets/img/common/verified.png" alt="img">
              <img src="../assets/img/common/cc_lock.png" alt="img">
              <img src="../assets/img/common/pci.png" alt="img">
              <img src="../assets/img/common/ssl.png" alt="img">
            </div>
          </div>
        </div>
      </div>
    </div>
    <consent />
  </div>
</template>
<script>
  
import store from '@/store'
import consent from '@/components/consent'

export default {
  name: 'Footer',
  components: {
    consent
  },
  data() {
    return {
      isShow:true, 
      isMobile : false,
      isLogin : store.getters.fullname,
      permissions : store.getters.permissions,
    }
  },
  watch: {
    '$store.getters.fullname' : function () {
      this.isLogin = store.getters.fullname
    },
      '$store.getters.permissions' : function () {
        this.permissions = store.getters.permissions
      }
    },

    mounted() {  //position: sticky
      this.isShow = store.getters.isNotApp
      let size = window.innerWidth
      this.isMobile = (size < 992) ? true : false

      window.addEventListener('resize', () => {
        let size = window.innerWidth
        this.isMobile = (size < 992) ? true : false
      })
    },
  methods : {
    urlDirct()
    {
      return (this.isMobile) ? '_self' : '_blank'
    },
  }
}
</script>
