<template>
    <section id="dashboard_main_arae" class="section_padding">
      <Loader :active="loaderActive" />
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="dashboard_sidebar">
                        <div class="dashboard_sidebar_user">
                          <i class="fas fa-user-circle fa-9x"></i>
                            <h3>{{memberInfo.firstName}} {{memberInfo.lastName}}</h3>
                            <p>{{memberInfo.phone}}</p>
                            <p>{{memberInfo.email}}</p>
                        </div>
                        <div class="dashboard_menu_area">
                            <ul>
                              <li>
                                <router-link to="/my-profile" class="active">
                                  <i class="fas fa-user-circle"></i>{{$t('myprofile')}}
                                </router-link>
                              </li>
                                <li><router-link to="/dashboard"><i
                                            class="fas fa-tachometer-alt"></i>{{$t('hotelbook')}}</router-link></li>
                              <!--  <MyBookingOption /> -->
                               
                             <!--  <li><router-link to="/dashboard"><i class="fas fa-wallet"></i>Wallet</router-link>
                                </li>
                                <li><router-link to="/notification"><i
                                            class="fas fa-bell"></i>Notifications</router-link></li>--> 
                                <LogoutBtn />
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="dashboard_common_table">
                        <h3>{{$t('myprofile')}}</h3>
                        <div class="profile_update_form">
                            <form action="!#" id="profile_form_area">
                              <div class="row">
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="f-name">{{$t('firstname')}}</label>
                                    <input type="text" class="form-control" id="f-name"
                                           v-model="memberInfo.firstName">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="l-name">{{$t('lastname')}}</label>
                                    <input type="text" class="form-control" id="l-name" v-model="memberInfo.lastName">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="m-name">{{$t('middlename')}}</label>
                                    <input type="text" class="form-control" id="m-name"
                                           v-model="memberInfo.middleName">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mobil-number">{{$t('phone')}}</label>
                                    <input type="text" class="form-control" id="mobil-number"
                                           v-model="memberInfo.phone">
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                  <p>{{$t('addressbelow')}}</p>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-address">{{$t('address')}}</label>
                                    <input type="text" class="form-control" id="mail-address"
                                           v-model="memberInfo.address">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-city">{{$t('city')}}</label>
                                    <input type="text" class="form-control" id="mail-city"
                                           v-model="memberInfo.city">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-state">{{$t('state')}}</label>
                                    <input type="text" class="form-control" id="mail-state"
                                           v-model="memberInfo.state">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-zip">{{$t('zip')}}</label>
                                    <input type="text" class="form-control" id="mail-zip"
                                           v-model="memberInfo.zip">
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-country">{{$t('country')}}</label>
                                    <select v-model="memberInfo.country" class="form-control form-select" v-bind:key="memberInfo.countryList">
                                      <option v-for="(item,key) in memberInfo.countryList" v-bind:value="key">{{item}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="form-group">
                                    <label for="mail-lang">{{$t('language')}}</label>
                                    <select v-model="memberInfo.language" class="form-control form-select" v-bind:key="memberInfo.languageList">
                                      <option v-for="(item,key) in memberInfo.languageList" v-bind:value="key">{{item}}</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            

                                <p v-bind:class="[{error_red: !error.success}, {success_green: error.success}]">&nbsp;<span v-show="(error.message)">{{error.message}}</span></p>
                                <span v-if="!loaderActive" v-on:click="updateData()" class="btn btn_theme btn_sm">{{$t('submitchange')}}</span><p>&nbsp;</p>
                                <div class="change_password_input_boxed">
                                  <h3>{{$t('emailnpass')}}</h3>
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <div class="form-group">
                                        <label for="u-name">{{$t('currentemail')}}</label>
                                        <input type="email" class="form-control bg_input" id="u-name" v-model="memberInfo.email" readonly>
                                      </div>
                                    </div>
                                    <div v-show="changePass" class="col-lg-12">
                                      <div class="form-group">
                                        <label for="u-name">{{$t('currpassword')}}</label>
                                        <input type="password" v-bind:class="['form-control', {errorclass: error2flag.oldPass}]" id="c-pass" v-model="passemail.oldPassword" v-bind:placeholder="$t('required')">
                                      </div>
                                    </div>
                                    <div v-show="changePass" class="col-lg-6">
                                      <div class="form-group">
                                        <label for="u-name">{{$t('changepass')}}</label>
                                        <input type="password" v-bind:class="['form-control', {errorclass: error2flag.passmatch}]" id="c-npass" v-model="passemail.password" v-bind:placeholder="$t('minlen')">
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div v-show="changePass" class="form-group">
                                        <label for="u-name">{{$t('changepassa')}}</label>
                                        <input type="password" v-bind:class="['form-control', {errorclass: error2flag.passmatch}]" id="c-npass2" v-model="passemail.password2">
                                      </div>
                                    </div>
                                    <div v-show="changePass" class="col-lg-12">
                                      <div class="form-group">
                                       {{$t('andor')}}
                                      </div>
                                    </div>
                                    <div v-show="changePass" class="col-lg-12">
                                      <div class="form-group">
                                        <label for="u-name">{{$t('changeemail')}}</label>
                                        <input type="email" v-bind:class="['form-control', {errorclass: error2flag.email}]" id="c-emailc" v-model="passemail.emailc">
                                      </div>
                                    </div>
                                     
                                    
                                  </div>
                                  <p v-bind:class="[{error_red: !error2.success}, {success_green: error2.success}]">&nbsp;<span v-show="(error2.message)">{{error2.message}}</span></p>
                                  <span v-if="!changePass" v-on:click="togglePass()" class="btn btn_theme btn_sm">{{$t('changeemailpass')}}</span>
                                  <span v-if="changePass" v-on:click="togglePass()" class="btn btn_border btn_sm">{{$t('cancelup')}}</span>&nbsp;&nbsp;
                                  <span v-if="changePass" v-on:click="passUpdate()" class="btn btn_theme btn_sm">{{$t('updatepass')}}</span>
                                   
                                  <p>&nbsp;</p>
                                </div>
                              <div  class="change_password_input_boxed">
                                <h3>{{$t('memberStatus')}}</h3>
                                <div v-if="isCCpay != null ">                                  
                                  <p><b>{{$t('expireDate')}} :</b> {{memberInfo.expireDate}} (<span v-if="memberInfo.paymentActive!='yes'">{{$t('paymentNotActive')}}</span><span v-if="memberInfo.paymentActive=='yes'">{{$t('paymentActive')}}</span>) <br />
                                    <span  ><b>{{$t('memberDues')}} :</b> {{memberInfo.memberAmount}}&nbsp;-&nbsp;
                                    <span v-if="memberInfo.monthlyMember">{{$t('memberMonthly')}}</span><span v-else>{{$t('memberYearly')}}</span></span></p> 
                                  <br />
                                  <h4 v-show="changeMember">{{$t("areyousure")}}</h4>
                                  <p v-show="changeMember" ><mark>{{$t("memberDisableMess")}}</mark></p>
                                    
                                  <div v-if="!changeMember">
                                    <span v-if="isCCpay == 'renew'"> 
                                      <span v-on:click="ccUpdate()" class="btn btn_theme btn_sm">{{$t('renewmem')}}</span>&nbsp;&nbsp;
                                      <span v-if="memberInfo.paymentActive=='userNo'" v-on:click="areYouSure(true)" class="btn btn_border btn_sm">{{$t('enableAutoPay')}}</span>
                                    </span>      
                                    <span v-else>
                                      <span v-on:click="ccUpdate()" class="btn btn_theme btn_sm">{{$t('updateCC')}}</span>&nbsp;&nbsp;
                                      <span v-if="memberInfo.paymentActive=='yes'" v-on:click="areYouSure(false)" class="btn btn_border btn_sm">{{$t('disableAutoPay')}}</span>
                                      <span v-if="memberInfo.paymentActive=='userNo'" v-on:click="areYouSure(true)" class="btn btn_border btn_sm">{{$t('enableAutoPay')}}</span>
                                    </span>
                                  </div>
                                  <div v-else>
                                    <span v-on:click="autoPay()" class="btn btn_border btn_sm">{{$t('disableAutoPay')}}</span>&nbsp;&nbsp;
                                    <span v-on:click="cancelPayChange()" class="btn btn_theme btn_sm">{{$t('cancelup')}}</span>
                                  </div>
                                </div>
                                <div v-else>
                                  <p><b>{{$t('expireDate')}} :</b> {{$t('alwaysActive')}} </p>
                                </div>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import store from '@/store'
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'
import Loader from '@/components/Loader.vue'
import modal from '@/components/Modal.vue'
//import MyBookingOption from '@/components/dashboard/MyBookingOption.vue'
export default {
  name: "ProfileDashboard",
    components: {
      LogoutBtn, //MyBookingOption
      Loader,
      modal
    },
    data() {
      return {
        loaderActive : false,
        isCCpay : store.getters.updateCC,
        changePass : false,
        changeMember : false,
        checkMark : false,
        error : {
          message : '',
          success : false
        },
        error2 : {
          message : '',
          success : false
        },
        error2flag : {
          passmatch : false,
          oldpass :false,
          email : false
        },
        memberInfo : {
          'firstName': '',
          'middleName' : '',
          'lastName' :'',
          'address' : '',
          'city' : '',
          'state' : '',
          'zip' :'',
          'language' : '',
          'phone' : ''

        },
        passemail : {
          'oldPassword' : '',
          'password'  : '',
          'password2' : '',
          'emailc' : ''
        },
        isLogin : store.getters.fullname,
        permissions : store.getters.permissions,
      }
    },
  watch: {
    '$store.getters.fullname' : function () {
      this.isLogin = store.getters.fullname
    },
      '$store.getters.permissions' : function () {
        this.permissions = store.getters.permissions
      }
  },
    created() {
      this.loaderActive = true; 
      if (store.getters.memberInfo.hasOwnProperty('status'))
        this.memberInfo = store.getters.memberInfo
    },
    mounted() {

      // functions
      console.log(this.memberInfo)
      if (!this.memberInfo.hasOwnProperty('status'))
      {
        this.fetchData()
      }
      else
        this.loaderActive = false; 

    },
    
    methods : {
      togglePass ()
      {
        //var email = document.querySelector("#u-name")
        //navCollapse.classList.remove("show")
        this.changePass = (!this.changePass)
        this.error2flag = {
          passmatch : false,
          oldpass :false,
          email : false
        }
        this.passemail = {
          'oldPassword' : '',
          'password'  : '',
          'password2' : '',
          'emailc' : ''
        }
        this.error2.message = ''
        this.error2.success = false
      },
      updateData()
      {
        this.error.message = ''
        this.error.success = false

        this.loaderActive = true; 
        var arr = {}
        if (this.memberInfo.country!=null && this.memberInfo.country!='')
          arr['country'] = this.memberInfo.country
        if (this.memberInfo.language!=null && this.memberInfo.language!='')
          arr['language'] = this.memberInfo.language
        if (this.memberInfo.phone!=null && this.memberInfo.phone!='')
          arr['phone'] = this.memberInfo.phone
        if (this.memberInfo.firstName!=null && this.memberInfo.firstName!='')
          arr['firstName'] = this.memberInfo.firstName
        if (this.memberInfo.middleName!=null && this.memberInfo.middleName!='')
          arr['middleName'] = this.memberInfo.middleName
        if (this.memberInfo.lastName!=null && this.memberInfo.lastName!='')
          arr['lastName'] = this.memberInfo.lastName
        if (this.memberInfo.address!=null && this.memberInfo.address!='')
          arr['address'] = this.memberInfo.address
        if (this.memberInfo.city!=null && this.memberInfo.city!='')
          arr['city'] = this.memberInfo.city
        if (this.memberInfo.state!=null && this.memberInfo.state!='')
          arr['state'] = this.memberInfo.state
        if (this.memberInfo.zip!=null && this.memberInfo.zip!='')
          arr['zip'] = this.memberInfo.zip

        this.$urlRequest.post('accounts', 'updateInfo', arr) 
        .then((response) => {
          console.log(response)
          //this.loaderActive = false
          this.error.message = this.$t('success')
          this.error.success = true
          this.fetchData()

          //  this.loaderActive = false;
        })
         .catch((error) => {
           var status = error.status
           this.error = error
           this.error.success = false
           this.loaderActive = false
           console.log(error)
           if (status == 0x80000003)  /// not logged in
             this.$router.push('/')

         })
      },
      passUpdate() 
      {
        this.error2flag = {
            passmatch : false,
            oldpass :false,
            email : false
        }
        this.error2.message = ''
        this.error2.success = false
        
        var arr = { oldPassword : this.passemail.oldPassword }
        
        if (this.passemail.password != null && this.passemail.password != '')
        {
          if (this.passemail.password != this.passemail.password2)
          {
            this.error2.message = this.$t('passworderr')
            this.error2flag.passmatch = true
            return
          }
          else if(this.passemail.emailc != null && this.passemail.emailc != '')
          {
            this.error2.message = this.$t('changeonlyone')
            this.error2flag.email = true
            return
          }
          arr['password'] = this.passemail.password
        }
        else if (this.passemail.emailc == null || this.passemail.emailc == '')
        {
          console.log(this.passemail)
          this.error2.message = this.$t('changesomething')
          this.error2flag.passmatch = true
          this.error2flag.email = true
          return
        }
        if (this.passemail.emailc != null && this.passemail.emailc != '')
          arr['emailc'] = this.passemail.emailc 

        console.log(arr)
        this.loaderActive = true
        this.$urlRequest.post('accounts', 'changePassword', arr) 
         .then((response) => {
           console.log(response)
           this.loaderActive = false
            
           this.fetchData()
           this.error2.success = true
           this.error2.message = this.$t('success')

           this.passemail = {
             'oldPassword' : '',
             'password'  : '',
             'password2' : '',
             'emailc' : ''
           }

           //  this.loaderActive = false;
         })
         .catch((error) => {
           var status = error.status
           var output = ''
           this.error2 = error
           this.error2.success = false
           this.loaderActive = false
           console.log(error)
           switch( status )
           {
             case 0x8000000c: // ERROR_USER_EMAIL_REQ 
               output = this.$t('invalidemail')
               this.error2flag.email = true
               break;
             case 0x80000003:  /// ERROR_USER_NOT_LOGIN
               this.$router.push('/')
               break;
             case 0x80000102 : //ERROR_PASS_SAME Change password 
               output = this.$t('passoldnewerr')
               this.error2flag.passmatch = true
               this.error2flag.oldpass = true
               break;
             case 0x80000100 : // ERROR_PASS_HIGH Required
             case 0x80000103 : // ERROR_PASS_MEDIUM Required
             case 0x80000101 : // ERROR_PASS_SECURE
               output = this.$t('notsecure')
               this.error2flag.passmatch = true
               break;
             case 0x80000007 : // ERROR_USER_EMAIL_EXISTS
               output = this.$t('emailexists')
               this.error2flag.email = true
               break;
             case 0x80000002 : // ERROR_USER_PASS_INVALID
               output = this.$t('badpassword')
               this.error2flag.oldpass = true
               break;
           }
           this.error2.message = output
         })
      },
      ccUpdate()
      {
        if (this.isCCpay == 'renew')
          this.$router.push("pro#/login")
        else
          this.$router.push("pro#/login?update_cc=true")
        //this.$router.push({ path: 'pro#/login', params: 'updateCC=true' }) 
      },
      areYouSure(val)
      {
        if (val == true)
        {
          this.autoPay()
        }
        else
        {
          this.changeMember = true
        }
      },
      cancelPayChange()
      {
        this.changeMember = false
      },
      autoPay()
      {
        var arr = {}
        this.loaderActive = true
        this.$urlRequest.post('accounts', 'toggleMember', arr)    // {'text' : this.dest, 'type' : 'hotels'}
         .then((response) => {
           console.log(response)
           //this.loaderActive = false
           this.cancelPayChange()
           this.fetchData()
    
           
         })
         .catch((error) => {
           var status = error.status
           this.loaderActive = false

         })
      },
      fetchData()
      {
        var arr = {}
        this.$urlRequest.post('accounts', 'memberInfo', arr)    // {'text' : this.dest, 'type' : 'hotels'}
         .then((response) => {
           console.log(response)
           this.loaderActive = false
           this.memberInfo = response
           console.log("what is CC " + response.updateCC)
           this.isCCpay = null
           if (typeof response.updateCC !== 'undefined')
             this.isCCpay = response.updateCC
           
           store.dispatch('UPDATE_USER', {
             memberInfo : response,
             updateCC : this.isCCpay
           })

           //  this.loaderActive = false;
         })
         .catch((error) => {
           var status = error.status
           this.loaderActive = false
           console.log(error)
           if (status == 0x80000003)  /// not logged in
             this.$router.push('/')

         })
      }
    }
};
</script>