
<template>
    <section id="explore_area" class="section_padding">
        <Loader :active="loaderActive" message/>
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{numofItems}} {{$t("toursfound")}}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <div class="left_side_search_area">
                      <div class="left_side_search_heading">
                        <h5>{{$t("sortby")}}<a class="apply" @click="changeSort()" html="#">{{$t("change")}}</a></h5>
                      </div>
                        <div class="left_side_search_boxed">
                          
                          <div class="flight_Search_boxed">

                            <input type="text" v-on:input="delaySearch" :placeholder="searchName" v-model="dest">
                            <!-- <span>Where would you like to go?</span> -->

                            <ul class="dropdown-autosearch" v-show="isOpen">
                              <li v-for="(name, i) in data" :key="name.id" @click="select(name.name, name.id)">
                                <a class="dropdown-item">{{name.name}}</a>
                              </li>

                            </ul>

                          </div>  

                          <select v-model="sortby" class="dropdown-list">
                            <option value="T" selected>{{$t("featured")}}</option>
                            <option value="R">{{$t("toprated")}}</option>
                            <option value="P">{{$t("Lowprice")}}</option>
                          </select> 
                          <br />
                          <p style="font-size:12px">{{$t("occMsg")}}</p>
                        </div>
            


                      
                         
                      
                    </div>
                </div>
              <!-- "col-lg-9">  class="col-lg-4 col-md-6 col-sm-6 col-12">  -->
                <div class="col-lg-9 col-md-12 col-sm-12 col-12">
                    <div class="row">
                        <div v-for='(tourInfo, index) in items' class="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div class="theme_common_box_two">
                                <div class="theme_two_box_img  img_hover">
                                    <!-- <router-link to="/tour-details"> -->
                                        <img :src="`${tourInfo.thumbnailHiResURL}`" @click="enterLink(tourInfo.webURL, index)"
                                            alt="Tour Image" />
                                   <!-- </router-link> 
                                    <p><i class="fas fa-map-marker-alt"></i>{{ tourInfo.address }}</p>-->
                                </div>
                                <div class="theme_two_box_content">
                                  <p  > <span v-html="getRankStars(tourInfo.rating)"></span> <span class="review_count"> {{tourInfo.reviewCount}} {{$t("reviews")}}</span></p>
                                    <h4 @click="enterLink(tourInfo.webURL, index)"> {{ tourInfo.shortTitle }} </h4> <!--<img :src="`${tourInfo.rateImageURL}`" alt="Tripadvisor" />-->
                                    <p class="review_count"  ><i class="fas fa-clock-o"></i> {{tourInfo.duration}}</p>
                                  <p class="price_align" v-show="tourInfo.available"> <p @click="enterLink(tourInfo.webURL, index)" v-bind:class="['inbtnfull',{inbtnpause : tourInfo.pausedFor5seconds}]">{{$t("from")}} <span class="inprice">{{tourInfo.priceFormatted}}</span></p><br />  </p>
                           
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div v-show="isNotSearchBot" class="pagination_area">
                                <ul class="pagination"  >
                                  <li class="page-item"><a v-show="pagination[0]" class="page-link" href="#" @click="changePage(0)" aria-label="Previous"><span aria-hidden="true">&laquo;</span><span class="sr-only">Previous</span></a></li>

                                  <li class="page-item">
                                        <span v-show="(pagination[1]==curpage)" class="page-link-focus">{{pagination[1]}}</span>
                                        <a class="page-link" v-show="(pagination[1] && pagination[1]!=curpage)" href="#" @click="changePage(1)">{{pagination[1]}}</a>
                                  </li>
                                  <li class="page-item">
                                    <span v-show="(pagination[2]==curpage)" class="page-link-focus">{{pagination[2]}}</span>
                                    <a class="page-link" v-show="(pagination[2] && pagination[2]!=curpage)" href="#" @click="changePage(2)">{{pagination[2]}}</a>
                                  </li>  
                                  <li class="page-item">
                                    <span v-show="(pagination[3]==curpage)" class="page-link-focus">{{pagination[3]}}</span>
                                    <a class="page-link" v-show="(pagination[3] && pagination[3]!=curpage)" href="#" @click="changePage(3)">{{pagination[3]}}</a>
                                  </li>  
          

                                  <li class="page-item"><a v-show="pagination[4]" class="page-link" href="#" @click="changePage(4)" aria-label="Next"><span aria-hidden="true">&raquo;</span><span class="sr-only">Next</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </section>
</template>
<script>
//import Slider from '@vueform/slider'
//import data from '../../data'
import store from '@/store'
import Loader from '@/components/Loader.vue'

export default {
  name: "SearchResult",
  props : ['pageProp'],
    components: {
     // Slider,
      Loader
    },
    data() {
      return {
        dest: '',
        setDest: '',
        destId : '',
        ttlValue : 0,
        ttlLocal : 0,
        isOpen : false,
        fetchTimeout : null,
        searchName : store.getters.tsearchname,
        data : [],
        lettersType : 0,
        isNotSearchBot : false,
        destSelected : 0,
        newTab : true,
        numofItems : '',
        loaderActive: false,
        startpos : store.getters.searchpage,
        curpage : ((store.getters.searchpage-1) / 21) + 1,
        loaderActive: false,
        sortby : store.getters.searchsort,
        value: [0, 75],
        pagination: [0,0,0,0,0],
    /*    info: {
          
          'city' : store.getters.city,
          'cityName' : store.getters.cityName,
          'userCurrency' : store.getters.userCurrency,
          'userLanguage' : store.getters.userLanguage,
          'displayCurrency' : store.getters.displayCurrency,
              }, */
         items: [],
         filterItems: []
        }
    },
  watch: {
    '$store.getters.tsearchname' : function () {
      this.searchName = (store.getters.tsearchname != null) ? store.getters.tsearchname : this.searchName
    },
    '$store.getters.searchsort' : function () {
      this.sortby = (store.getters.searchsort != null) ? store.getters.searchsort : this.sortby
    } 
  },
  methods: {
    select(name, id)
    {
      console.log("what is ID " + id)
      this.dest = name
      this.setDest = name
      this.destId = id
      this.isOpen = false
      this.destSelected = this.lettersType
    },
    delaySearch()
    {
      this.lettersType ++
      
      // console.log("START TYPEING")
      let newDate = Date.now()
      let diffDate = newDate - this.ttlLocal
      if ((newDate > 4294967295 && diffDate < 600000) || (newDate <= 4294967295 && diffDate < 600))
      {
        //  console.log("CLEAR SEARCH")
        clearTimeout(this.fetchTimeout)
      }
      this.ttlLocal = newDate
      //console.log("BEGIN TIMEOUT")
      this.fetchTimeout = setTimeout(() => {
          console.log("--------------------->START SEARCH")
        this.fetchSearch() // method to call when user is done typing
      },600)

    },
    fetchSearch()
    {
      let arr = {'type' : 'tours'};

      this.lettersType ++

      if (this.dest.length > 2 || (this.lat != null && this.long != null))
      {
        if (this.dest.length > 2)
          arr['text'] = this.dest

        if (this.lat != null && this.long != null)
        {
          arr['lat'] = this.lat
          arr['long'] = this.long
        }


        this.$urlRequest.post('fetch', 'search', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            if (this.ttlValue <= response.ttl)
            {
              this.data = response.data
              this.ttlValue = response.ttl
            }
            if (response.data.length > 0 && this.lettersType > this.destSelected)
              this.isOpen = true;
            console.log(this.data)
            // this.$router.push('/login')
          })
          .catch((error) => {
            //this.loading = false
            // this.$nprogress.done()
            //this.$notification['warning']({
            // message: error.code,
            //  description: error.message
            //})
          })
      }
      else
        this.isOpen=false
    },
    setpages()
    {
      var curindex = (this.startpos==1) ? this.startpos : this.startpos-21
      var temppage = (this.startpos==1) ? 1 : ((curindex-1) / 21) + 1

      this.pagination[0] = (this.startpos==1) ? 0 : -1
      this.pagination[1] = (curindex < this.numofItems) ? temppage : 0
      curindex += 21
      temppage ++
      this.pagination[2] = (curindex < this.numofItems) ? temppage : 0
      curindex += 21
      temppage ++
      this.pagination[3] = (curindex < this.numofItems) ? temppage : 0
      curindex += 21
      temppage ++
 
 
      curindex += 21
      this.pagination[4] = (curindex < this.numofItems) ? -2 : 0
    },

    changePage(location)
    {

      if (location==0)
        this.startpos = this.startpos - 3 * 21
      else if (location==4)
        this.startpos = this.startpos + 3 * 21
      else if (location != 0)
      {
        var getPage = this.pagination[location]
        if (getPage != 0)
          this.startpos = (getPage-1) * 21 + 1
      }
      
      if (this.startpos >= this.numofItems) this.startpos -= 21
      else if (this.startpos < 1) this.startpos = 1

      this.curpage = ((this.startpos-1) / 21) + 1

      this.setpages()

      store.dispatch('UPDATE_USER', {
        searchpage: this.startpos
      })
      this.$urlRequest.cookies()
        .then((response) => {
          console.log("WE GOOD")
        })
        .catch((error) => {
          console.log("WE BAD " + error)
        })
      this.fetchList()

      console.log("We made it in here" + this.startpos)
    },
    
  
   

    getRankStars(rank){

      // Round down to get whole stars:
      var wStars = Math.floor(rank);
      // Check if whole is less than rank.
      // If less than rank, a half star is needed:
      var halfStars = (wStars < rank);

      var output="";
      //Loop through five stars:
      for(let i=1;i<=5;i++){
        //Less than or equal to stars, display a solid star:
        if(i<=wStars){
          output+="</i><i class='fas fa-star' style='color:#fbcc05'></i>";

          //If interation is after a whole star and a half star is needed, display half star:
        }else if( i==wStars+1 && halfStars==true ){
          output+="<i class='fas fa-star-half-alt' style='color:#fbcc05'></i>";

          //Otherwise, display a gray empty star:
        }else{
          output+="<i class='far fa-star' style='color:#bfbfbf'></i>";
        }
      }
      return output;
    },

    enterLink(url, key) {
      if (this.isNotSearchBot) // only if not a search bot
      {
        if (this.items[key].pausedFor5seconds == false)
        {
          this.items[key].pausedFor5seconds = true
          setTimeout(() => this.items[key].pausedFor5seconds = false, 5000);
          if (this.newTab)
            window.open(url, '_blank')
          else
            window.open(url, '_self')
        }
      } // endif not a search bot
    },

    changeSort()
    {
      if (this.isNotSearchBot) // only if not a search bot
      {
        this.startpos = 1
        this.curpage = 1
        this.loaderActive = true;
        store.dispatch('UPDATE_USER', {
          searchsort: this.sortby
        })

        if (this.destId != '' && this.dest != '')
        {
          store.dispatch('UPDATE_USER', {
            tsearchname: this.dest,
            tsearchid: this.destId,
            tsearchpagetitle : '',
          })

          this.searchName = this.dest
          this.destId = ''
          this.setDest = ''  
          this.dest = ''
          
        }

        this.$urlRequest.cookies()
          .then((response) => {
            console.log("WE GOOD")
          })
          .catch((error) => {
            console.log("WE BAD " + error)
          })
        if (this.pageProp.isInPage)
          this.$router.push({ path: '/tour-search' })
        else
          this.fetchList()
      }
    },

    fetchList()
    {
      let arr = {'count' : 21}

      // are we in a page...
      if (this.pageProp.isInPage && store.getters.tsearchid == null)
      {
        arr['destID'] = this.$route.params.modifierparam
        arr['head'] = this.$route.params.headparam
      }
      else  // otherwise we are on a normal page...
      { 
        this.sortby = store.getters.searchsort

        if (store.getters.tsearchid == null)
          this.$router.push('/tours')

        arr['sortOrder'] = this.sortby
        arr['destID'] = store.getters.tsearchid
        arr['startPos'] = this.startpos
        arr['userLanguage'] = store.getters.userLanguage
        arr['userCurrency'] = store.getters.userTourCurrency
      }
      

//      arr['userCurrency'] = store.getters.searchin;
      console.log(arr)
      this.loaderActive = true;
      //if (this.dest.length > 2)
     // {
     
      this.$urlRequest.post('fetch', 'tourList', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            this.items = response.data
            this.numofItems = response.totalCount
            this.loaderActive = false;
            if (response.data.length > 0)
            {
              Object.keys(this.items).forEach(key => {
                this.items[key].pausedFor5seconds = false
              });
            }

            // update infromation for the page...
            if (this.pageProp.isInPage && store.getters.tsearchid == null)
            {
              this.isNotSearchBot = !response.isSearchBot
              store.dispatch('UPDATE_USER', {
                tsearchname: response.titleName,
                tsearchid: response.destID,
                searchsort: response.sortOrder,
                tsearchpagetitle : response.titleHead,
                searchpage: 1
              })
              this.$urlRequest.cookies()
              .then((response) => {
                console.log("WE GOOD")
              })
              .catch((error) => {
                console.log("WE BAD " + error)
              })
            }

            
                      
            console.log(this.numofItems)
            console.log(this.items)
            this.setpages()
            // this.$router.push('/login')
          })
          .catch((error) => {
            this.loaderActive = false;
            //this.loading = false
            // this.$nprogress.done()
            //this.$notification['warning']({
            // message: error.code,
            //  description: error.message
            //})
          })
     // }
    //  else
     //   this.isOpen=false
    },
  },

    created(){
      if (store.getters.tsearchid)
        this.loaderActive = true;
    },


    mounted() {
      //this.items = data.hotelData
      let size = window.innerWidth 
      this.newTab = (size >= 992) ? true : false

      window.addEventListener('resize', () => {
         let size = window.innerWidth 
         this.newTab = (size >= 992) ? true : false
       })  
        this.$nextTick(() => {
           this.fetchList()
       })
    },

};
</script>
<style src="@vueform/slider/themes/default.css">

</style>