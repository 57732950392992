<template>

    <!-- Common Banner Area 
    <WalletBanner />-->

    <!-- Dashboard Area 
    <WalletDashboard />-->

     <!-- Cta Area 
     <Cta />-->

</template>
<script>


export default {
    name: "RedirectView",
    mounted () {
      var str = this.$route.path.substring(0,4)
      var uri = 'https:/' + str + '.flystaygo.com'
      window.location.href = uri
    }
};
</script>
