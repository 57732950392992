<template>
    <section id="about_us_top" class="section_padding">
      <Loader :active="loaderActive" />
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="about_us_left">
                        <h5>{{ $t("Contactus") }}</h5>
                        <h2>{{ $t("getintouch") }}</h2>
                        <p>{{ $t("touchtext") }} </p>
                     </div>
                </div>
                <div class="col-lg-8">
                    <div class="about_us_right">
                      <div class="comment_area_form">
                        <form  id="news_comment_form" v-on:submit.prevent="sendMessage"> 
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-froup">
                                <input type="text" class="form-control bg_input" v-model="name"
                                       :placeholder="$t('entername')">
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-froup">
                                <input type="text" class="form-control bg_input" v-model="email"
                                       :placeholder="$t('enteremail')" />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-froup">
                                <textarea rows="6" :placeholder="$t('entermess')" v-model="message"
                                          class="form-control bg_input"></textarea>
                              </div>
                              <p v-bind:class="[{error_red: !error.success}, {success_green: error.success}]">&nbsp;<span v-show="(error.message)">{{error.message}}</span></p>
                              <div class="comment_form_submit">
                                <button class="btn btn_theme btn_md">{{$t("sendmess")}}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>

            </div>
            <div  class="about_us_left">
              
              <h2>{{ $t("custserv") }}</h2> 
              <p v-html="$t('custservtext')"></p>
            </div>
        </div>
    </section>

</template>
<script>
import Loader from '@/components/Loader.vue'

export default {
  name: "AboutInfo",
  components: {
    Loader
  },
    data() {
      return {
        email : '',
        name : '',
        message : '',
        error : {
          message : '',
          success :false
        },
        loaderActive :false
      };
    },
  methods: {
    sendMessage()
    {
      this.error.message = ''
      this.error.success = false
      let arr = {'name' : this.name, 'email' : this.email, 'message' : this.message};
      if (this.name.length == 0 || this.email.length == 0 || this.message.length == 0)
        this.error.message = this.$t("mailFormErr")
      else
      {
        this.loaderActive = true
        this.$urlRequest.post('accounts', 'sendMailForm', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            this.error.success = true 
            this.error.message = this.$t("success")
            this.email = ''
            this.name = ''
            this.message = ''
            this.loaderActive = false
            // this.$router.push('/login')
          })
          .catch((error) => {
            this.error = error
            this.error.success = false
            this.loaderActive = false
          })
      }
    }
  }
};
</script>