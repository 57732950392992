<template>
     <section id="theme_search_form" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                 <!-- <img src="../../assets/img/common/poi.jpg" alt="img"> -->
                    <div class="terms_service_content">
                     <!-- <div class="terms_item" style="text-align:center!important;">
                        <br /><br />
                        <h2>Become a Member and Receive a Complimentary Vacation</h2>
                        <p>Choose one of the links below and become a member of <a href="https://flystaygopro.com">FlyStayGo Pro</a> for wholesale prices on hotel stays plus recieve a complimentary vacation of your choice. Details in the links.</p>
                        <a href="https://flystaygopro.com/freecancunvacation"><img style="width:28%; height:auto" src="../../assets/img/destination/cancun.jpg" alt="FREE Cancun Vacation"></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="https://flystaygopro.com/freeorlandovacation"><img style="width:28.5%; height:auto" src="../../assets/img/destination/orlando.jpg" alt="FREE Orlando Vacation"></a>
                        <br /><br />
                        <a href="https://flystaygopro.com/freelasvegasvacation"><img style="width:40%; height:auto" src="../../assets/img/destination/lasvegas.jpg" alt="FREE Vegas Vacation"></a>
                          
</div> --><br /><br />
                      <div class="terms_item">
                        <br />
                        <h2>Locations of Interest</h2>
                        <br /><br />
                        <h4>We Got You Covered</h4>
                        <p>Looking to explore the globe's most captivating destinations? Dive into our list of interesting locations worldwide! Whether you're drawn to iconic landmarks, natural wonders, or cultural hotspots, our selection caters to every traveler's taste. From the majestic peaks of the Himalayas to the vibrant streets of Tokyo, here is a list of must-visit spots to inspire your next adventure. Discover breathtaking vistas, immerse yourself in rich history, and create unforgettable memories at these renowned destinations. Start planning your journey today and embark on a thrilling exploration of the world's most remarkable locations!</p>
                        <p>
                          
                          <li v-for="(item,key) in xmlData" :value="key"><u><router-link :to="item.url">{{item.name}}</router-link></u></li>
                      
                        </p> 

                      </div>
                   </div>
                      
                </div>
            </div>
        </div>
    </section>

</template>
<script>


  export default {
  name: "POImainContent",
  data() {
  return {
  xmlData : [],
  }
  },
  methods : {
  //xml parsing


  },
  async mounted() {
  const parser = new DOMParser();

  var str = "../sitemap.xml"
  var last = this.$route.path.slice(-1)

  if (last == "/" || last == "\\")
    str = "../../sitemap.xml"

  console.log(str)

  var response = await fetch(str)
  var text = await response.text()

  console.log(text)
  var xmlDoc = await parser.parseFromString(text, "application/xml");

  var lNodes = xmlDoc.getElementsByTagName('sitemap')
  //var page = 1;
  for (let i = 0; i < lNodes.length; i++) {
      var loc = lNodes[i].getElementsByTagName('loc')[0].textContent
      var spl = loc.split('/')
      var pageSplit = spl[spl.length-1].split('.')
      var pageSplit2 = pageSplit[0].split('-')
      var page = (pageSplit2.length > 1) ? pageSplit2[1] : 1
      if (loc.includes("xml_") === true)
      {
        this.xmlData.push( {'name' : 'Locations Page #'+page, 'url' : '/info/poipage?l=' + spl[spl.length-1]/* + '&p=' + page*/} )
        //page ++
      }

    }

    /*
    for (let i = 0; i < lNodes.length; i++) {
      var loc = lNodes[i].getElementsByTagName('loc')[0].textContent
      console.log("count " + i)
       
      if (loc.includes("xml_") === true)
      {
        console.log("we are in " + loc.includes("xml_"))
        this.xmlData.push( {'name' : 'Top Page #'+page, 'url' : loc} )
        page ++
      }

    }*/
 
      console.log(this.xmlData)
 

  
    }
};
</script>