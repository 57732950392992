<template>
   <section id="tour_booking_submission" class="section_padding">
     <modal :active="modalVisible" @closing="(i) => modalVisible = i" :data="modalData" :confirm="modalConfirm"/>
     <Loader :active="loaderActive" />
        <div class="container">
          <div class="row" v-show="isNoRate!=null">
            <div class="col-lg-8">
              <div class="tou_booking_form_Wrapper">
                <div class="booking_tour_form">
                  <div class="tour_booking_form_box">
                    <h4 class="error_red">{{isNoRateTitle}}</h4>
                    <p>{{isNoRate}}</p>
                    <p>{{isNoRateMessage}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="tour_details_right_sidebar_wrapper">
                <div class="tour_detail_right_sidebar">
                  <div class="tour_details_right_boxed">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="isNoRate==null">
            <div class="col-lg-8">
              <div class="tou_booking_form_Wrapper">
                <div class="booking_tour_form">

                  <div class="tour_booking_form_box">
                    <h4>{{bookInfo.roomTitle}}</h4>
                    <p class="line">{{bookInfo.roomDesc}}</p>

                    <div class="row">
                      <div class="col-lg-6 col-12">

                        <div class="tour_package_bar_price">
                          <h5>&nbsp;&nbsp; {{bookInfo.subTotal}} <sub>{{$t("subtotal")}}</sub> </h5>
                        </div>
                        <div class="tour_package_bar_price">
                          <h5>&nbsp;&nbsp; {{bookInfo.taxNfees}} <sub>{{$t("taxes")}}</sub> </h5>
                        </div>
                        <div class="tour_package_bar_price">
                          <h3 v-show="total">{{total}} <sub>{{$t("total")}}</sub> </h3>
                        </div>
                        <div class="tour_package_bar_price" v-if="(memberPrice!=null)">
                          <h5 v-show="!memberIsMonthly">+&nbsp;{{memberPrice}} <sub>{{$t("yearmember")}}{{$t(memberType)}}</sub></h5>
                          <h5 v-show="memberIsMonthly">+&nbsp;{{memberMonthPrice}} <sub>{{$t("monthmember")}}{{$t(memberType)}}</sub></h5>
                          
                        </div>
                        <p v-show="(bookInfo.discount)" class="discount">&nbsp;&nbsp; {{bookInfo.discount}} {{$t("totalsavings")}}</p>

                      </div>
                      <div class="col-lg-6 col-12">
                        <p class="inbetween"><span>{{$t("checkin")}}:</span><span>{{bookInfo.checkin}}</span></p>
                        <p class="inbetween"><span>{{$t("checkout")}}:</span><span>{{bookInfo.checkout}}</span></p>
                        <p class="inbetween"><span>{{$t("nights")}} : {{bookInfo.nights}}</span><span>{{$t("adult")}} : {{bookInfo.adults}}</span></p>
                        <p class="inbetween"><span>{{$t("room")}} : {{bookInfo.rooms}}</span><span>{{$t("child")}} : {{bookInfo.children}}</span></p>
                      </div>
                      <p class="col-12" v-if="(memberPrice!=null)">&nbsp;&nbsp;&nbsp;<sub v-show="!memberIsMonthly">{{memberDesc}}</sub><sub v-show="memberIsMonthly">{{memberMonthDesc}}</sub></p>
                    </div>
                  </div>
                </div>
                <div class="booking_tour_form">
                  <h3 class="heading_theme">{{$t("yourinfo")}}</h3>
                  <div class="tour_booking_form_box">
                    <form id="tour_bookking_form_item">
                      <div class="row">
                        <!--  <div class="row" v-for="index in bookInfo.rooms" key="index"> -->
                        <div class="col-lg-6">
                          <div class="form-group">
                            <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.guestFirstName}]" v-model="populate.guestFirstName"
                                    v-bind:placeholder="$t('guestfirst')">
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.guestLastName}]" v-model="populate.guestLastName"
                                    v-bind:placeholder="$t('guestlast')">
                          </div>
                        </div>
                        <!--</div>-->


                        <div class="col-lg-12">
                          <div class="form-group">
                            <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.email}]" v-model="populate.email"
                                    v-bind:placeholder="$t('email')">
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.phone}]" v-model="populate.phone"
                                    v-bind:placeholder="$t('phone')">
                          </div>
                        </div>



                      </div>
                    </form>
                  </div>
                </div>
                <div class="booking_tour_form">
                  <h3 class="heading_theme">{{$t('ccInfo')}}</h3>
                  <div class="tour_booking_form_box">
                    <div class="booking_payment_boxed">

                      <ul class="tour_booking_top_bottom">
                        <li class="tour_booking_form_box_icon" v-show="validCC.vi">
                          <i class="fab fa-cc-visa fa-lg"></i>
                        </li>
                        <li class="tour_booking_form_box_icon" v-show="validCC.mc">
                          <i class="fab fa-cc-mastercard fa-lg"></i>
                        </li>
                        <li class="tour_booking_form_box_icon" v-show="validCC.ax">
                          <i class="fab fa-cc-amex fa-lg"></i>
                        </li>
                        <li class="tour_booking_form_box_icon" v-show="validCC.ds">
                          <i class="fab fa-cc-discover fa-lg"></i>
                        </li>
                        <li class="tour_booking_form_box_icon" v-show="validCC.dc">
                          <i class="fab fa-cc-diners-club fa-lg"></i>
                        </li>
                        <li class="tour_booking_form_box_icon" v-show="validCC.jc">
                          <i class="fab fa-cc-jcb fa-lg"></i>
                        </li>

                      </ul>

                      <form id="payment_checked">
                        <div class="payment_filed_wrapper">
                          <div class="payment_card  red">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.fullName}]" v-model="populate.fullName"
                                          v-bind:placeholder="$t('cardholder')">
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.address}]" v-model="populate.address"
                                          v-bind:placeholder="$t('billingaddr')">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.zip}]" v-model="populate.zip"
                                          v-bind:placeholder="$t('billpostal')">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.city}]" v-model="populate.city"
                                          v-bind:placeholder="$t('billcity')">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.state}]" v-model="populate.state"
                                          v-bind:placeholder="$t('state')">
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <select v-model="populate.country" class="form-control form-select bg_input" v-bind:key="countryList">
                                    <option v-for="(item,key) in countryList" v-bind:value="key">{{item}}</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-lg-12">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.card}]" v-model="populate.card"
                                          v-bind:placeholder="$t('cardnum')">
                                </div>
                              </div>

                              <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                  <input type="text" v-bind:class="['form-control', 'bg_input', {errorclass: error.ccv}]" v-model="populate.ccv"
                                          v-bind:placeholder="$t('security')">
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                  <img src="@/assets/img/icon/ccv.png" alt="ccv">
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-6">
                                <div class="form-group">
                                  <select v-model="populate.expMonth" v-bind:class="['form-control', form-select, 'bg_input', {errorclass: error.expMonth}]" v-bind:key="monthList">
                                    <option v-for="(item,key) in monthList" v-bind:value="key">{{item}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-6">
                                <div class="form-group">
                                  <select v-model="populate.expYear" v-bind:class="['form-control', form-select, 'bg_input', {errorclass: error.expYear}]" v-bind:key="yearList">
                                    <option v-for="(item,key) in yearList" v-bind:value="key">{{item}}</option>
                                  </select>
                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="booking_tour_form">
                  <h3 class="heading_theme">{{$t("confirm")}}</h3>
                  <div class="tour_booking_form_box">
                    <div class="booking_tour_form_submit">
                      <h5>{{bookInfo.cancelInfo.title}}</h5>
                      <div v-html="bookInfo.cancelInfo.text"></div><br />
                      <h5>{{bookInfo.feeInfo.title}}</h5>
                      <div v-html="bookInfo.feeInfo.text"></div><br />
                      <div class="form-check write_spical_check">
                        <input class="form-check-input" type="checkbox" v-model="checkMark" id="flexCheckDefaultf1">
                        <label class="form-check-label" for="flexCheckDefaultf1">
                          <span class="main_spical_check">
                            <span><mark v-if="isSameDay">{{$t('sameDayMsg')}}</mark>{{$t('agree')}}{{total}}{{$t('agree2')}}</span>
                            <span v-if="memberPrice!=null">&nbsp;{{$t('memberAlert')}}:</span>
                          </span>
                        </label>
                        <div v-if="memberPrice!=null && memberisRenew!=true" >
                          <input selected type="radio" v-model="memberMonthly" v-on:click="changePay(true)" id="radioMonth" value="true" />
                          <label for="radioMonth">&nbsp;{{memberMonthPrice}}{{$t('memberAlert2m')}}</label>
                          <br />
                          <input type="radio" v-model="memberMonthly" v-on:click="changePay(false)"  id="radioYear" value="false" />
                          <label for="radioYear">&nbsp;{{memberPrice}}{{$t('memberAlert2')}}</label>
                        </div>
                      </div>
                      <div v-if="memberPrice!=null && memberisRenew==true" >
                        <span v-if="memberIsMonthly">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{memberMonthPrice}}{{$t('memberAlert2m')}}</span>
                        <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{memberPrice}}{{$t('memberAlert2')}}</span>
                      </div>
                      <p class='error_red'>&nbsp;<span v-show="(error.message)">{{error.message}}</span></p>
                      <span v-if="!loaderActive" v-on:click="makeBook()" class="btn btn_theme btn_md">{{$t('confirm')}}</span>&nbsp;
                      <span v-else class="nobtn_theme btn_md">{{$t('confirm')}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="tour_details_right_sidebar_wrapper">
                <div class="tour_detail_right_sidebar">
                  <div class="tour_details_right_boxed">



                    <div class="theme_two_box_img">
                      <img v-bind:src="`${bookInfo.thumbnail}`"
                            alt="Hotel Image" />
                      <p><i class="fas fa-map-marker-alt"></i>{{ bookInfo.address }}</p>
                    </div><br /><p v-html="getRankStars(bookInfo.stars)"></p><br />

                    <h3 class="heading_theme">{{$t("policy")}}</h3>

                    <div class="booking_tour_form_submit">
                      <div v-for="(item,key) in bookInfo.policyInfo">
                        <h5>{{item.title}}</h5>
                        <div v-html="item.text"></div><br />
                      </div>
                    </div>


                  </div>
                </div>
                <!--   <div class="tour_detail_right_sidebar">
            <div class="tour_details_right_boxed">
              <div class="tour_details_right_box_heading">
                <h3>Travel date</h3>
              </div>
              <div class="edit_date_form">
                <div class="form-group">
                  <label for="dates">Edit Date</label>
                  <input type="date" id="dates" value="2022-05-05" class="form-control">
                </div>
              </div>
              <div class="tour_package_details_bar_list">
                <h5>Tourist</h5>
                <div class="select_person_item">
                  <div class="select_person_left">
                    <h6>Adult</h6>
                    <p>12y+</p>
                  </div>
                  <div class="select_person_right">
                    <h6>01</h6>
                  </div>
                </div>

                <div class="select_person_item">
                  <div class="select_person_left">
                    <h6>Children</h6>
                    <p>2 - 12 years</p>
                  </div>
                  <div class="select_person_right">
                    <h6>01</h6>
                  </div>
                </div>
                <div class="select_person_item">
                  <div class="select_person_left">
                    <h6>Infant</h6>
                    <p>Below 2 years</p>
                  </div>
                  <div class="select_person_right">
                    <h6>01</h6>
                  </div>
                </div>
              </div>
              <div class="edit_person">
                <p>Edit person</p>
              </div>
            </div>
          </div>
          <div class="tour_detail_right_sidebar">
            <div class="tour_details_right_boxed">
              <div class="tour_details_right_box_heading">
                <h3>Coupon code</h3>
              </div>
              <div class="coupon_code_area_booking">
                <form action="#!">
                  <div class="form-group">
                    <input type="text" class="form-control bg_input"
                            placeholder="Enter coupon code">
                  </div>
                  <div class="coupon_code_submit">
                    <button class="btn btn_theme btn_md">Apply voucher</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        <div class="tour_detail_right_sidebar">
            <div class="tour_details_right_boxed">
              <div class="tour_details_right_box_heading">
                <h3>Booking amount</h3>
              </div>

              <div class="tour_booking_amount_area">
                <ul>
                  <li>Adult Price x 1 <span>$40,000.00</span></li>
                  <li>Discount <span>-10%</span></li>
                  <li>Tax<span>5%</span></li>
                </ul>
                <div class="tour_bokking_subtotal_area">
                  <h6>Subtotal <span>$38,000.00</span></h6>
                </div>
                <div class="coupon_add_area">
                  <h6>
                    <span class="remove_coupon_tour">Remove</span> Coupon code (OFF 5000)
                    <span>$5,000.00</span>
                  </h6>
                </div>
                <div class="total_subtotal_booking">
                  <h6>Total Amount <span>$33,000.00</span> </h6>
                </div>
              </div>
            </div>
          </div> -->
              </div>
            </div>
          </div>
        </div>
</section>
</template>
<script>

import store from '@/store'
import Loader from '@/components/Loader.vue'
import modal from '@/components/Modal.vue'

export default {
  name: "TourBookingSubmission",
  components: {
    Loader,
    modal
  },
    data() {
      return {
        isLogin : store.getters.fullname,
        modalVisible: false,
        modalData: null,
        modalConfirm : null,
        watchRoute: null,
        loaderActive: false,
        isNoRate : null,
        isNoRateTitle: '',
        isNoRateMessage: '',
        total : null,
        memberMonthly : true,
        memberIsMonthly : true,
        memberPrice : null,
        memberDesc : null,
        memberMonthPrice : null,
        memberMonthDesc : null,
        memberType : null,
        memberisRenew : false,
        validCC : {},
        bookInfo : {
          roomTitle : '',
          address : '',
          roomDesc : '',
          nights : 0,
          discount  : '',
          adults : 0,
          children : 0,
          rooms : 1,
          checkin : '',
          checkout : '',
          feeInfo : {
            title : '',
            text : ''
          },
          cancelInfo : {
            title : '',
            text : ''
          },
          policyInfo : {},
          thumbnail : '',
          subTotal : '',
          taxNfees : '',
          stars : 0.0

        },
        checkMark : false,
        error : {
          message : '',
          guestFirstName:false,
          guestLastName : false,
          email : false,
          phone : false,
          fullName : false,
          address : false,
          city : false,
          zip : false,
          state : false,
          card : false,
          expMonth : false,
          expYear : false,
          ccv : false

        },
        populate : {   
          guestFirstName : '',
          guestLastName : '',
          email : '',
          phone : '',
          fullName : '',
          address : '',
          city : '',
          zip : '',
          state : '',
          country : 'US',
          card : '',  // not passed from server after this...
          expMonth : 0,
          expYear : 0,
          ccv : ''
        },
        countryList : {},
        yearList : {},
        monthList : {},
        isSameDay : false 
    
       /* guests : [
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
          { firstName : '', LastName : ''},
        ]*/

      }
    },
  created(){
    if (store.getters.bookRpCode)
      this.loaderActive = true; 
    },
  watch: {
    'modalVisible' : function() {
      if (!this.modalVisible && this.watchRoute != null)
         this.$router.push(this.watchRoute)
    },
    '$store.getters.fullname' : function () {
      this.isLogin = store.getters.fullname
      if (this.isLogin!=null && this.isLogin!='')
      {
        this.error.message = null
        this.onPageLoad()
      }
    },
  },
  mounted() {

    this.onPageLoad();

    
  },
  methods : {
  /*  placeName(index, name, req = '')
    {
      return this.$t('groom') + ' ' + index + ' ' + this.$t(name) + req
    },*/
    onPageLoad()
    {
      if (store.getters.isBookingComplete)
        this.$router.push('/hotel-details')

      // build years out...
      var year = parseInt(new Date().getFullYear())
      this.yearList[0] = this.$t('expireYear')
      for(var i = 0; i <= 10; i++)
      {
        this.yearList[year] = year.toString()
        year ++;
      }
      this.monthList[0] = this.$t('expireMonth')
      for(var i = 1; i <= 12; i++)
      {
        var n = i.toString()
        if (n.length == 1)
          n = '0' + n

        this.monthList[i] = n
      }
      
      this.fetchBooking()

      if (store.getters.bookPopulate.length > 0 && this.isLogin!=null && this.isLogin!='')
        this.populate = store.getters.bookPopulate
    },
    changePay(isMonth)
    {
      this.memberIsMonthly = isMonth
      console.log("check mark " + this.memberIsMonthly)
    },
    makeBook()
    {
      console.log("check mark " + this.memberIsMonthly)
      this.error.message = null
      if (!this.checkMark) this.error.message = this.$t('tickerr')
      var popMsg = false
      this.error.guestFirstName = (this.populate.guestFirstName == '') ? true : false
      popMsg |= this.error.guestFirstName
      this.error.guestLastName = (this.populate.guestLastName == '') ? true : false 
      popMsg |= this.error.guestLastName
      this.error.email = (this.populate.email == '') ? true : false
      popMsg |= this.populate.email
      this.error.phone = (this.populate.phone == '') ? true : false 
      popMsg |= this.error.phone
      this.error.fullName = (this.populate.fullName == '') ? true : false  
      popMsg |= this.error.fullName
      this.error.address = (this.populate.address == '') ? true : false   
      popMsg |= this.error.address
      this.error.city = (this.populate.city == '') ? true : false  
      popMsg |= this.error.city
      this.error.zip = (this.populate.zip == '') ? true : false   
      popMsg |= this.error.zip
      this.error.state = (this.populate.state == '') ? true : false   
      popMsg |= this.error.state
      this.error.card = (this.populate.card == '') ? true : false   
      popMsg |= this.error.card 
      this.error.expMonth = (this.populate.expMonth == 0) ? true : false   
      popMsg |= this.error.expMonth
      this.error.expYear = (this.populate.expYear == 0) ? true : false 
      popMsg |= this.error.expYear
      this.error.ccv = (this.populate.ccv == '') ? true : false 
      popMsg |= this.error.ccv

      if (popMsg) this.error.message = this.$t('missingerr')
      else if (this.error.message == null)  // continue and send info to server...
      {
        this.loaderActive = true

        let arr = {'site' : store.getters.roomSite}
        arr['siteId'] = store.getters.roomSiteId
        arr['otaId'] = store.getters.roomOtaId
        arr['rpCode'] = store.getters.bookRpCode
        arr['rtCode'] = store.getters.bookRtCode
        arr['misc'] = store.getters.bookMisc
        arr['checkin'] = store.getters.roomin
        arr['checkout'] = store.getters.roomout
        arr['childern'] = store.getters.roomchild
        arr['adults'] = store.getters.roomadult
        arr['rooms'] = store.getters.roomroom
        arr['userLanguage'] = store.getters.userLanguage
        arr['userCurrency'] = store.getters.userCurrency
        arr['firstName'] = this.populate.guestFirstName
        arr['lastName'] = this.populate.guestLastName
        arr['email'] = this.populate.email
        arr['phone'] = this.populate.phone
        arr['cardName'] = this.populate.fullName
        arr['billingAddress'] = this.populate.address
        arr['billingCity'] = this.populate.city
        arr['billingState'] = this.populate.state
        arr['billingCountry'] = this.populate.country // must be country code (etc.. US)
        arr['billingZip'] = this.populate.zip
        arr['card'] = this.populate.card
        arr['cvc'] = this.populate.ccv     // cvv2 cid cvc2 are all the same values
        arr['expireMonth'] = this.monthList[ this.populate.expMonth ] 
        arr['expireYear'] = this.yearList[ this.populate.expYear ]
        arr['promo'] = store.getters.promo
        arr['memberIsMonthly'] = this.memberIsMonthly;

        console.log(arr)
        this.$urlRequest.post('fetch', 'completeBooking', arr)    // {'text' : this.dest, 'type' : 'hotels'}
         .then((response) => {
           console.log(response)
          
           store.dispatch('UPDATE_USER', {
             bookComplete : response
           })

           this.$router.push('/booking-confirmation') // 
           //  this.loaderActive = false;
         })
         .catch((error) => {
           this.loaderActive = false;
           console.log(error)
           var status = error.status
           console.log("We are here one?")
           if (error.hasOwnProperty('code') || error.hasOwnProperty('title') || error.hasOwnProperty('message'))
           {
             var output = ''
             var goBack = false
             var button = this.$t('ok')
             switch(status)
             {
               case 0x80040003 : // ERROR_USER_EMAIL_EXISTS
                 console.log("We are here?")
                 output = this.$t('emailExists')
                 this.populate.cvv = ''
                 store.dispatch('UPDATE_USER', {
                   bookPopulate : this.populate
                 })
                 break;
               case 0x80050005:  // ERROR_CC_BAD_NAME 
               case 0x80060002:  // ERROR_HOTEL_BAD_NAME 
                 output = this.$t('nameerr')
                 this.error.guestFirstName = this.error.guestLastName = this.error.fullName = true
                 break;
               case 0x80060001: // ERROR_HOTEL_SERVICE_ERROR 
                 output = this.$t('errerr')
                 break;
               case 0x80060005 : // ERROR_HOTEL_BAD_EMAIL
                 output = this.$t('emailerr')
                 this.error.email = true
                 break;
               case 0x80050001: // ERROR_CC_NOT_SUPPORTED
                 output = this.$t('nosupperr')
                 this.error.card = true
                 break;
               case 0x80050000: // ERROR_CC_NOT_VALID 
                 output = this.$t('carderr')
                 this.error.card = true
                 break;
               case 0x80050003:// ERROR_CC_INVALID_EXPIRE 
                 output = this.$t('experr') 
                 this.error.expMonth = this.error.expYear = true
                 break;
               case 0x8005000B: // ERROR_CC_BAD_CVC
                 output = this.$t('cvverr') 
                 this.error.ccv = true
                 break;
               case 0x80050007 : //ERROR_CC_BAD_ADDRESS
                 output = this.$t('addrerr') 
                 this.error.address = true
                 break;
               case 0x80050008: // ERROR_CC_BAD_CITY
                 output = this.$t('ciryerr') 
                 this.error.address = true
                 break;
               case 0x80050006: // ERROR_CC_BAD_ZIP 
                 output = this.$t('ziperr') 
                 this.error.address = true
                 break;
               case 0x80060007: //ERROR_HOTEL_BAD_PHONE
                 output = this.$t('phoneerr') 
                 this.error.phone = true
                 break;
               case 0x80050009: //ERROR_CC_BAD_COUNTRY
                 output = this.$t('conterr') 
                 break;
               case 0x80050002: //ERROR_CC_DECLINED 
                 output = this.$t('carddecline') 
                 this.error.cvv = this.error.card = this.error.expMonth = this.error.expYear = true
                 break;
               case  0x80060008: // ERROR_HOTEL_DUPLICATE_BOOK
                 output = this.$t('bookdup') 
                 break;
               case 0x8005000C: //ERROR_CC_3D_SECURE
                 output = this.$t('sec3derr') 
                 break;
               case 0x80060000: //ERROR_HOTEL_PARTNER 
                 output = this.$t('genericerr') 
                 break;
               case 0x80060003: // ERROR_HOTEL_PRICE_CHANGE
                 output = this.$t('ratechange') 
                 button = this.$t('goback')
                 goBack = true
                 break;
               case 0x80060004: //ERROR_HOTEL_RATE_UNAVAILABLE 
                 output = this.$t('rateunavail') 
                 button = this.$t('goback')
                 goBack = true
                 break;
               case 0x80060006: //ERROR_HOTEL_BAD_DATES 
                 output = this.$t('badbookdates') 
                 button = this.$t('goback')
                 goBack = true
                 break;
               
             }
             this.error.message = output
             var html = "<h5  class='discount'>" + output + "</h5>"
             if (error.title != undefined) html += "<p><b>" + error.title + "</b></p>"
             if (error.message != undefined) html += "<p>" + error.message + "</p>"

             if (goBack == true)
             { 
               this.watchRoute = "/hotel-details"
               this.populate.cvv = ''
               store.dispatch('UPDATE_USER', {
                 bookPopulate : this.populate
               })
             }

             this.openModal(html,button)
           }
           else
           {
             switch(status)
             {
               
               case '0x80050000': // ERROR_CC_NOT_VALID 
                 this.error.message = this.$t('carderr') 
                 this.error.card = true
                 break;
               case '0x80050001': // ERROR_CC_NOT_SUPPORTED
                 this.error.message = this.$t('nosupperr') 
                 this.error.card = true
                 break;
               case '0x80050003':// ERROR_CC_INVALID_EXPIRE 
                 this.error.message = this.$t('experr') 
                 this.error.expMonth = this.error.expYear = true
                 break;
             }
           }

          // this.isNoRate = this.$t('errorate')
          // this.isNoRateTitle = (!error.title) ? error.code : error.title
          // this.isNoRateMessage = (!error.message) ? '' : error.message
      
         })

      }
    },
    getRankStars(rank){

      // Round down to get whole stars:
      var wStars = Math.floor(rank);
      // Check if whole is less than rank.
      // If less than rank, a half star is needed:
      var halfStars = (wStars < rank);
   
      var output="";
      //Loop through five stars:
      for(let i=1;i<=5;i++){
        //Less than or equal to stars, display a solid star:
        if(i<=wStars){
          output+="</i><i class='fas fa-star' style='color:#fbcc05'></i>";

          //If interation is after a whole star and a half star is needed, display half star:
        }else if( i==wStars+1 && halfStars==true ){
          output+="<i class='fas fa-star-half-alt' style='color:#fbcc05'></i>";

          //Otherwise, display a gray empty star:
        }else{
          output+="<i class='far fa-star' style='color:#bfbfbf'></i>";
        }
      }
      return output;
    },
    openModal(data, button) {   
      
      //data += "<div class='btn btn_theme btn_sm'>" + this.$t(button) + "</div>"
      // make sure there is a button added to the bottom of this...
      this.modalData = data
      this.modalConfirm = this.$t(button)
      this.modalVisible = true    
    },
    fetchBooking()
    {
      let arr = {'site' : store.getters.roomSite}
      arr['siteId'] = store.getters.roomSiteId
      arr['otaId'] = store.getters.roomOtaId
      arr['rpCode'] = store.getters.bookRpCode
      arr['rtCode'] = store.getters.bookRtCode
      arr['misc'] = store.getters.bookMisc
      arr['checkin'] = store.getters.roomin
      arr['checkout'] = store.getters.roomout
      arr['childern'] = store.getters.roomchild
      arr['adults'] = store.getters.roomadult
      arr['rooms'] = store.getters.roomroom
      arr['userLanguage'] = store.getters.userLanguage
      arr['userCurrency'] = store.getters.userCurrency
      arr['promo'] = store.getters.promo

      console.log(arr)
      this.loaderActive = true;

      //if (this.dest.length > 2)
      // {
     
      this.$urlRequest.post('fetch', 'hotelBooking', arr)    // {'text' : this.dest, 'type' : 'hotels'}
        .then((response) => {
          //   this.$nprogress.done()
          //this.loading = false
          console.log(response)
          this.total = (response.hasOwnProperty('details')) ? response.details.total : null
          if (this.total == null)  // we have an error, notify the user of it...
          {
            this.isNoRate = this.$t('errorate')
            this.isNoRateTitle = ''
            this.isNoRateMessage = ''
          }
          else
          {
            this.bookInfo = response.details 
            this.isSameDay = this.bookInfo.isSameDay
            this.populate = response.populate
            // add addtional fields here...
            this.populate['card'] = ''
            this.populate['expMonth'] = 0
            this.populate['expYear'] = 0
            this.populate['ccv'] = ''
            this.countryList = response.countryList


            Object.keys(response.details.validCC).forEach(key => {
              this.validCC[response.details.validCC[key]] = true
            })
          /*  this.guests[1].firstName = this.populate.guestFirstName
            this.guests[1].lastName = this.populate.guestLastName*/
          }   

          // if we have a booking with a membership to update...
          if (response.hasOwnProperty('member'))
          {
            console.log("did we get in here???")
            console.log(response.member)
            this.memberPrice = response.member.price
            this.memberDesc = response.member.description
            this.memberMonthPrice = response.member.priceMonth
            this.memberMonthDesc = response.member.descMonth
            this.memberType = response.member.type
            if (this.memberType == 'renewmembership')
            {
              this.memberisRenew = true
              this.memberIsMonthly = response.member.memberIsMonthly 
            }
          }
          else
            this.memberPrice = null

          this.loaderActive = false;
        })
        .catch((error) => {
          this.loaderActive = false;
          console.log(error)
          this.isNoRate = this.$t('errorate')
          this.isNoRateTitle = (!error.title) ? error.code : error.title
          this.isNoRateMessage = (!error.message) ? '' : error.message
      
        })
    },
  },
 
};
</script>