<template>
    <section id="faqs_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>{{$t("faq")}}</h2>
                    </div>
                </div>
            </div>
            <div class="faqs_area_top">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="faqs_three_area_wrapper">
                            <!-- Item One -->
                            <div class="faqs_item_wrapper">
                                {{$t("onlyenglish")}}<br /><br />

                                <h3>All Products</h3>
                                <div class="faqs_main_item">
                                    <div class="accordion" id="accordionExample">

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="false"
                                                    aria-controls="collapseOne">
                                                  How do I know that my reservation is confirmed?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                      You will receive a confirmation to your email address immediately following your reservation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                  How can I be sure my credit card information is secure?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                      We use secure-socket-layer (SSL) encryption, the standard encryption on the Internet. This encryption allows our users to feel confident and secure about supplying their credit card and personal information. SSL provides assurance that you are really doing business with us (and not an impostor) and that the information you are sending to us can not be intercepted or decrypted by anyone other than the intended recipient.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                  What credit cards do you accept?
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>
                                                      We accept Visa, Mastercard, American Express, and Discover.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <!-- Item Two -->
                            <div class="faqs_item_wrapper">
                                <h3>FlyStayGo Member 'PRO' Hotel Questions</h3>
                                <div class="faqs_main_item">
                                    <div class="accordion" id="accordionExampleTwo">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOnef1">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOnef1" aria-expanded="false"
                                                    aria-controls="collapseOnef1">
                                                  What does instant confirmation mean?
                                                </button>
                                            </h2>
                                            <div id="collapseOnef1" class="accordion-collapse collapse"
                                                aria-labelledby="headingOnef1" data-bs-parent="#accordionExampleTwo">
                                                <div class="accordion-body">
                                                    <p>
                                                      We have provided you an actual reservation with an instant confirmation number, so there is nothing else that needs to be done. Behind the scenes we cause the provider to re-validate the reservation and acknowledge they are prepared for your arrival.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwof1">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwof1"
                                                    aria-expanded="false" aria-controls="collapseTwof1">
                                                  What are the "Internet hot deals"?
                                                </button>
                                            </h2>
                                            <div id="collapseTwof1" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwof1" data-bs-parent="#accordionExampleTwo">
                                                <div class="accordion-body">
                                                    <p>
                                                      We work closely with providers to deliver the lowest available rates for our members anywhere on the Internet, anywhere in the world. In most cases, Internet Hot Deals are significantly lower than other online hotel booking websites. Internet-hot-deals are always displayed first for any city hotel search and are designated with a hot-deals link.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- Item Three -->
                            <div class="faqs_item_wrapper">
                                <h3>General Hotel Questions</h3>
                                <div class="faqs_main_item">
                                    <div class="accordion" id="accordionExampleThree">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOnef2">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOnef2" aria-expanded="false"
                                                    aria-controls="collapseOnef2">
                                                  How do I cancel my reservation?
                                                </button>
                                            </h2>
                                            <div id="collapseOnef2" class="accordion-collapse collapse"
                                                aria-labelledby="headingOnef2" data-bs-parent="#accordionExampleThree">
                                                <div class="accordion-body">
                                                    <p>
                                                      Please fill out a cancel reservation form from the links at the bottom of the page. Include your travel number / confirmation number along with your request. Please check the confirmation and the provider's cancellation policy regarding refunds of reservation and/or deposit amounts. The policies are clearly detailed.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwof2">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwof2"
                                                    aria-expanded="false" aria-controls="collapseTwof2">
                                                  Can I book a room for tonight?
                                                </button>
                                            </h2>
                                            <div id="collapseTwof2" class="accordion-collapse collapse"
                                                aria-labelledby="headingTwof2" data-bs-parent="#accordionExampleThree">
                                                <div class="accordion-body">
                                                    <p>
                                                      Some hotels allow us to book their rooms until 3:00pm on the day of arrival. Only those hotels that follow this policy will be displayed to you if your arrival date is the same day.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThreef2">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseThreef2"
                                                    aria-expanded="false" aria-controls="collapseThreef2">
                                                  Is there a free shuttle from the airport to the hotel?
                                                </button>
                                            </h2>
                                            <div id="collapseThreef2" class="accordion-collapse collapse"
                                                aria-labelledby="headingThreef2"
                                                data-bs-parent="#accordionExampleThree">
                                                <div class="accordion-body">
                                                    <p>
                                                      Some hotels do provide free shuttle services from the airport. This information can be found on the property information page for the specific hotel.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFourf2">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFourf2"
                                                    aria-expanded="false" aria-controls="collapseFourf2">
                                                    It is a long established fact that a reader will be distracted by
                                                    the readable content?
                                                </button>
                                            </h2>
                                            <div id="collapseFourf2" class="accordion-collapse collapse"
                                                aria-labelledby="headingFourf2" data-bs-parent="#accordionExampleThree">
                                                <div class="accordion-body">
                                                    <p>
                                                        There are many variations of passages of Lorem Ipsum available,
                                                        but the majority have suffered alteration in some form, by
                                                        injected humour, or randomised words which don't look even
                                                        slightly believable.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour2f2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseFour2f2"
                                                  aria-expanded="false" aria-controls="collapseFour2f2">
                                            Will I get a free breakfast?
                                          </button>
                                        </h2>
                                        <div id="collapseFour2f2" class="accordion-collapse collapse"
                                             aria-labelledby="headingFour2f2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Some hotels include a free breakfast with each room night booked. That information can be found on the property information page for the specific hotel.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFivef2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseFivef2"
                                                  aria-expanded="false" aria-controls="collapseFivef2">
                                            What about parking at the hotel?
                                          </button>
                                        </h2>
                                        <div id="collapseFivef2" class="accordion-collapse collapse"
                                             aria-labelledby="headingFivef2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Free parking is provided at many hotels. Check the property information pages for information on parking.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingSixf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseSixf2"
                                                  aria-expanded="false" aria-controls="collapseSixf2">
                                            How do I get driving directions to the hotel?
                                          </button>
                                        </h2>
                                        <div id="collapseSixf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingSixf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Click the map link on the hotel property page. This map will give you the main cross streets and address of the hotel.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingSevenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseSevenf2"
                                                  aria-expanded="false" aria-controls="collapseSevenf2">
                                            Do hotels have minimum age requirements to book a room?
                                          </button>
                                        </h2>
                                        <div id="collapseSevenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingSevenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Most hotels require a minimum age of 18 in order to book a room. Some require the guest(s) be 21. You can see requirements and other hotel amenities on the hotel's property information pages.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingEightf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseEightf2"
                                                  aria-expanded="false" aria-controls="collapseEightf2">
                                            How accurate is your hotel amenities information?
                                          </button>
                                        </h2>
                                        <div id="collapseEightf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingEightf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              The hotel information is actually updated by the hotel or hotel chain a number of times per year. Additionally, we strive to have the most accurate and complete hotel information available but we have to rely on the personnel at the hotel property to update and change information when appropriate. If you have specific requirements we recommend you contact the hotel to validate the amenity or service(s).
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingNinef2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseNinef2"
                                                  aria-expanded="false" aria-controls="collapseNinef2">
                                            Who can I contact to help me?
                                          </button>
                                        </h2>
                                        <div id="collapseNinef2" class="accordion-collapse collapse"
                                             aria-labelledby="headingNinef2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Hopefully, you'll find the answer to your questions or concerns by reading through our most frequently asked questions list. If you don't find the answer to your question; please contact either Reservations or Customer Service. You can find links to an online form or a number to call at the bottom of the page.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseTenf2"
                                                  aria-expanded="false" aria-controls="collapseTenf2">
                                            What are additional fees and taxes?
                                          </button>
                                        </h2>
                                        <div id="collapseTenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingTenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Every city has their own tax rates. In some cases there are additional fees imposed by providers, local, city, state and federal agencies. Taxes are computed on the hotel reservation amount and fees charged as predicated by the provider. Non-refundable deposits are periodically required to hold rooms for your arrival and to provide you the lowest rates. Resort Fees may also be required at time of arival to the Hotel.  All fees and informtion ca ben found on the property bookng page for ythe specific hotel.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingElevenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseElevenf2"
                                                  aria-expanded="false" aria-controls="collapseElevenf2">
                                            Do you include taxes in the total rate?
                                          </button>
                                        </h2>
                                        <div id="collapseElevenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingElevenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Generally yes, we do provide rates and breakout the taxes for your convenience; however, occasionally a hotel will not provide us with their tax information and therefore we are unable to show that information to you. If you find a hotel that doesn't provide tax rate information, please call the property to find out specific tax information.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwelvef2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseTwelvef2"
                                                  aria-expanded="false" aria-controls="collapseTwelvef2">
                                            Can I make reservations for someone else?
                                          </button>
                                        </h2>
                                        <div id="collapseTwelvef2" class="accordion-collapse collapse"
                                             aria-labelledby="headingTwelvef2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              You can make a reservation for someone else using our system. Just make sure you include that person's name and information as the guest. You can have a different name and address for the guest and for the person paying with their credit card.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThirteenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseThirteenf2"
                                                  aria-expanded="false" aria-controls="collapseThirteenf2">
                                            Can I switch hotels?
                                          </button>
                                        </h2>
                                        <div id="collapseThirteenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingThirteenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              Yes. You can switch hotels by canceling your previous reservation and making a new reservation. Please review your existing reservation to avoid any cancellation or modification penalties.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFourteenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseFourteenf2"
                                                  aria-expanded="false" aria-controls="collapseFourteenf2">
                                            Can I add nights or rooms to my existing hotel reservation?
                                          </button>
                                        </h2>
                                        <div id="collapseFourteenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingFourteenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              You will need to make another reservation in order to add rooms or nights to your stay. Please note, when you make the new reservation, you may not be given the same room for the additional nights.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFifteenf2">
                                          <button class="accordion-button collapsed" type="button"
                                                  data-bs-toggle="collapse" data-bs-target="#collapseFifteenf2"
                                                  aria-expanded="false" aria-controls="collapseFifteenf2">
                                            Can I bring my pet?
                                          </button>
                                        </h2>
                                        <div id="collapseFifteenf2" class="accordion-collapse collapse"
                                             aria-labelledby="headingFifteenf2" data-bs-parent="#accordionExampleThree">
                                          <div class="accordion-body">
                                            <p>
                                              You can sort by specific amenities to include pet-friendly hotels. Some pet-friendly hotels may indicate that they allow pets in the hotel description. However, there may be additional restrictions. To best determine if the hotel you are selecting allows pets and what restrictions may apply, you should contact the hotel directly.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>

                          <div class="faqs_item_wrapper">
                            <h3>Car Rental Questions</h3>
                            <div class="faqs_main_item">
                              <div class="accordion" id="accordionExampleFour">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOnef3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOnef3" aria-expanded="false"
                                            aria-controls="collapseOnef3">
                                      Can I cancel/change my reservation/itinerary?
                                    </button>
                                  </h2>
                                  <div id="collapseOnef3" class="accordion-collapse collapse"
                                       aria-labelledby="headingOnef3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        If your reservation is pre-paid then no, you will have to contact the rental company to make any changes to your reservation. If your reservation is being paid on arrival, then simply visit the Review/Cancel Reservation section of our website you will need your Rental Car Itinerary Number and the email address you entered when you reserved your car.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwof3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwof3"
                                            aria-expanded="false" aria-controls="collapseTwof3">
                                      What do I need to bring when I pick up my rental car?
                                    </button>
                                  </h2>
                                  <div id="collapseTwof3" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwof3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        You should have three things with you when you arrive to pick-up your rental car: A valid U.S. driver's license or a valid driver's license issued from your country of residence and an International Driver's Permit -- you'll need to present it at the rental counter to prove your identity, verify your birth-date and, if necessary, for a driving record check. Your Rental Car Itinerary/Receipt - which is available on the homepage of our website at the top right-hand corner of the page. Enter your Request Number and your receipt and full itinerary will be right there for you to print. A valid major credit card with available credit. A debit/check card that has a Mastercard or Visa logo is accepted but also needs to have available credit as the rental car companies require this as a security deposit on the car. The driver will also need to have this available credit if they choose to purchase optional items at the rental counter. Cash: The driver will not be allowed to leave a cash deposit in lieu of a credit card or debit/check card.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThreef3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThreef3"
                                            aria-expanded="false" aria-controls="collapseThreef3">
                                      Can I reserve a car for someone else?
                                    </button>
                                  </h2>
                                  <div id="collapseThreef3" class="accordion-collapse collapse"
                                       aria-labelledby="headingThreef3"
                                       data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        You can submit a request for a rental car that someone else will be driving as long as you enter the driver's full name and age and the driver meets the following requirements: Has a valid driver's license issued in his or her full name - the rental company will verify age and may check the driver's driving record. Non-U.S. residents must present a valid international driver's license from his/her home country. Has a valid credit card with a minimum of $200 or more of available credit. Most locations also accept debit cards. Meets the minimum age requirements. Keep in mind that when you initial the request on our Web site you are indicating that the DRIVER accepts all of Flystaygo's Terms and Conditions and that the driver will be denied rental if any of the above criteria are not met.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFourf3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFourf3"
                                            aria-expanded="false" aria-controls="collapseFourf3">
                                      How much does Collision Damage Coverage cost?
                                    </button>
                                  </h2>
                                  <div id="collapseFourf3" class="accordion-collapse collapse"
                                       aria-labelledby="headingFourf3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        Collision Damage Coverage can be purchased during checkout for either $9/calendar day for standard rentals (with $40,000 in coverage), or $12/calendar day for premium rentals (with $75,000 in coverage). Please remember, while no payment is required for your rental car reservation until you pick up the vehicle, Collision Damage Coverage is charged immediately to your credit card at the time you book your rental reservation.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFivef3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFivef3"
                                            aria-expanded="false" aria-controls="collapseFivef3">
                                      When I cancel my rental car reservation, can I also cancel my Collision Damage Coverage?
                                    </button>
                                  </h2>
                                  <div id="collapseFivef3" class="accordion-collapse collapse"
                                       aria-labelledby="headingFivef3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        Cancellations with 100% refund will be permitted for Collision Damage Insurance up until your scheduled pick-up time. If you choose to cancel your reservation, please visit www.etravelprotection.com/ppn/home or call 1 (866) 909-4101 to cancel insurance.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSixf3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSixf3"
                                            aria-expanded="false" aria-controls="collapseSixf3">
                                      Can I get a one-way rental?
                                    </button>
                                  </h2>
                                  <div id="collapseSixf3" class="accordion-collapse collapse"
                                       aria-labelledby="headingSixf3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        Yes. You can book a rental car reservation with a different pick-up and drop-off location (also known as a "one-way" rental). For example, you can enter a pick-up location in Orlando and a drop-off location in Miami if that is where you will end your trip. One-way reservations are currently limited to airport locations only (for example, pick-up at Orlando International Airport and drop-off at Miami International Airport.) There may also be a "drop-off" fee charged by the rental car company.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSevenf3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSevenf3"
                                            aria-expanded="false" aria-controls="collapseFourf3">
                                      Can I drive my rental car outside the United States?
                                    </button>
                                  </h2>
                                  <div id="collapseSevenf3" class="accordion-collapse collapse"
                                       aria-labelledby="headingSevenf3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        While your rental car comes with unlimited mileage, the rental car company that is providing the car may have restrictions about driving your rental car into another country. If you plan on taking your rental car outside the U.S., we suggest you ask at the rental counter about its company's policy prior to driving off the rental car lot. This includes inquiries regarding driving into Canada and Mexico.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingEightf3">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseEightf3"
                                            aria-expanded="false" aria-controls="collapseEightf3">
                                      Can I add a driver to my rental car reservation?
                                    </button>
                                  </h2>
                                  <div id="collapseEightf3" class="accordion-collapse collapse"
                                       aria-labelledby="headingEightf3" data-bs-parent="#accordionExampleFour">
                                    <div class="accordion-body">
                                      <p>
                                        You can add a driver to your rental car reservation at the rental counter. There may be additional fees which will be disclosed at the rental counter. The primary driver on the reservation must arrive at the counter to pick up the car and add the additional driver to the rental agreement. We are unable to add a driver to your rental reservation or change the name of the primary driver originally entered on our web site.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="faqs_item_wrapper">
                            <h3>Flight Questions</h3>
                            <div class="faqs_main_item">
                              <div class="accordion" id="accordionExampleFive">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOnef4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOnef4" aria-expanded="false"
                                            aria-controls="collapseOnef4">
                                      Do you sell special fares such as military fares, bereavement fares, senior fares, child fares, infant fares or group discounts?
                                    </button>
                                  </h2>
                                  <div id="collapseOnef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingOnef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        We do not offer or sell special fares such as military fares, bereavement fares, senior fares, child fares, infant fares or group discounts.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwof4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwof4"
                                            aria-expanded="false" aria-controls="collapseTwof4">
                                      Things to know before you buy!
                                    </button>
                                  </h2>
                                  <div id="collapseTwof4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwof4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Below are some things to consider in preparation for placing your request: For security, all reservations must be made in the exact name of the person traveling. The name on the ticket must match your government-issued photo ID - nicknames cannot be used. If you use a suffix such as Sr., Jr., III etc. please be aware that this extension will be added immediately after your last name on your confirmation, e.g. Smith III. For international travel, the name on the reservation must be exactly as it appears on the traveler's passport. If you're traveling outside the U.S., all travelers will need a valid passport and you may also need to show additional documentation at your destination and/or in connecting countries. In the unfortunate event that you need to change or cancel your reservation, airline policies vary. If changes are permitted, they must be made prior to the original date of departure, and will incur a carrier-imposed $100 to $150 USD change fee per ticket for domestic flights and $200 to $300 USD or more per ticket on international flights. In addition there is a service fee of $30 USD per ticket and the airfare cost may increase due to decreased flight ability.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThreef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThreef4"
                                            aria-expanded="false" aria-controls="collapseThreef4">
                                      What is Travel Insurance (Trip Protection) and how do I get it?
                                    </button>
                                  </h2>
                                  <div id="collapseThreef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingThreef4"
                                       data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Trip Protection is an optional plan that insures you and your travel companions against unforeseen sickness, injury, job loss, and more. Trip Protection from Allianz Global Assistance is available for purchase during the flight reservation process, or directly after you�ve booked your flight via the confirmation page. Plans are only available to US residents. Airline reservations may be restricted and requests to change or cancel reservations are often denied based on airline policy. However, with Travel Insurance provided by Allianz Global Assistance, if you have to cancel or interrupt your trip for a covered reason, you can be reimbursed up to the total cost of your original reservation. Some examples of covered emergencies include covered illness, injury or death of insured passengers and/or immediate family members.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFourf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFourf4"
                                            aria-expanded="false" aria-controls="collapseFourf4">
                                      What are my ticketing options?
                                    </button>
                                  </h2>
                                  <div id="collapseFourf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingFourf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        We issue e-tickets (when available) for domestic travel -that is travel within the 48 contiguous (connected) states as well as Hawaii, Alaska, Puerto Rico, Guam, and the U.S. Virgin Islands. Some international trips (travel outside of the United States) or multi-destination trips require paper tickets as specified by the airline.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFivef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFivef4"
                                            aria-expanded="false" aria-controls="collapseFivef4">
                                      Special needs and requests?
                                    </button>
                                  </h2>
                                  <div id="collapseFivef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingFivef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        We understand that some travelers will have special needs such as oxygen tanks, wheelchairs, service animals, special meals or baggage handling. Since each airline has different requirements for these requests, please contact the airline directly. Special requests may require airline approval and be subject to an additional charge payable directly to the airline. We have no control over an airline's ability to fulfill customer�s special needs and can not guarantee that such requests will be met by the airline.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSixf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSixf4"
                                            aria-expanded="false" aria-controls="collapseSixf4">
                                      Can I make reservations for my pet?
                                    </button>
                                  </h2>
                                  <div id="collapseSixf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingSixf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        No. flystaygo does not have the ability to book reservations for pets - whether traveling in the cargo area, the passenger cabin (in a kennel under the seat) or in a reserved seat. If you are traveling with a pet, please contact your airline directly for information on their policies and fees associated with pet travel.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSevenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSevenf4"
                                            aria-expanded="false" aria-controls="collapseSevenf4">
                                      Can I fly one-way or to multiple cities?
                                    </button>
                                  </h2>
                                  <div id="collapseSevenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingSevenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Yes! We offer both one-way and multi-destination travel. If you are requesting a multi-destination itinerary, you will be able to enter a maximum of five flights for up to eight passengers. Your multi-destination trip can either be an open-jaw or a circle trip. You can select one-way or multi-destination travel by using one of the links below.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingEightf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseEightf4"
                                            aria-expanded="false" aria-controls="collapseEightf4">
                                      Can I purchase tickets for children traveling without an adult?
                                    </button>
                                  </h2>
                                  <div id="collapseEightf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingEightf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        We can book tickets for passengers 15 years and older without an accompanying adult. We recommend that where possible, unaccompanied minors avoid traveling on the last flight of the day or on connecting flights. For children under 15 years old traveling alone, please contact your desired airline directly. Keep in mind that unaccompanied minor policies and fees vary by airline. Once booked, we recommend calling your airline directly to inquire about unaccompanied minor requirements, fees, services, or travel restrictions for your specific itinerary. Most reservations can be cancelled within 24-hours of purchase if your confirmed airline will not allow unaccompanied minor travel on your confirmed itinerary.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingNinef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseNinef4"
                                            aria-expanded="false" aria-controls="collapseNinef4">
                                      Frequent Flyer Miles?
                                    </button>
                                  </h2>
                                  <div id="collapseNinef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingNinef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Earning Miles: You can enter your frequent traveler information in the Passenger Names and Flight Preferences section when purchasing your reservation however, because frequent flyer programs vary by airline and are subject to change there is no guarantee you will be able to accumulate miles. To confirm eligibility or to add your frequent flier number post purchase, please contact your carrier directly. Redeeming Miles: Tickets cannot be purchased using frequent traveler miles. If you would like to redeem miles for a ticket, please contact the appropriate carrier directly.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTenf4"
                                            aria-expanded="false" aria-controls="collapseTenf4">
                                      Can I make a reservation if I am not a U.S. resident?
                                    </button>
                                  </h2>
                                  <div id="collapseTenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Yes. U.S. residency is not a requirement for purchase.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingElevenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseElevenf4"
                                            aria-expanded="false" aria-controls="collapseElevenf4">
                                      Children Under 2 Years Old?
                                    </button>
                                  </h2>
                                  <div id="collapseElevenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingElevenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        For Domestic Travel (flights within the US or Puerto Rico), most airlines allow adult passengers to carry one infant (child under age 2) on the lap at no charge. If you are planning to carry an infant, please call your confirmed airline post purchase to let them know. On international flights (all flights outside of the U.S.), an infant must have a ticket, even if the infant sits on an adult's lap. Flystaygo does not sell infant tickets; you will need to purchase an "infant ticket" directly with your airline. Please note that all persons, including newborn infants, are required to obtain passports in their own name.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwelvef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwelvef4"
                                            aria-expanded="false" aria-controls="collapseTwelvef4">
                                      Children Under 15 Years Old?
                                    </button>
                                  </h2>
                                  <div id="collapseTwelvef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwelvef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Because airline policies vary, we do not book tickets for passengers under the age of 15 unless they are accompanied by an adult, 18 years of age or older. Children under 15 traveling alone on a ticket purchased through us may be denied boarding by the airline.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThirteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThirteenf4"
                                            aria-expanded="false" aria-controls="collapseThirteenf4">
                                      Persons 15 and older?
                                    </button>
                                  </h2>
                                  <div id="collapseThirteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingThirteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        We can book tickets for passengers 15 years and older without an accompanying adult. We recommend that where possible, unaccompanied minors avoid traveling on the last flight of the day or on connecting flights. Keep in mind that unaccompanied minor policies and fees vary by airline. Once booked, we recommend calling your airline directly to inquire about unaccompanied minor requirements, fees, services, or travel restrictions for your specific itinerary. Most reservations can be cancelled within 24-hours of purchase if your confirmed airline will not allow unaccompanied minor travel on your confirmed itinerary.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFourteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFourteenf4"
                                            aria-expanded="false" aria-controls="collapseFourteenf4">
                                      What's a non-jet airplane?
                                    </button>
                                  </h2>
                                  <div id="collapseFourteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingFourteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Non-jet airplanes are turbo-propeller driven or 'commuter' planes. These quick, quiet and comfortable planes offer features usually associated with larger aircraft, including in-flight service and lavatory facilities. Most major airlines include non-jet aircraft in their fleets, since many local or regional airports can only be serviced by these smaller non-jet planes. We will not search for flights on non-jet aircraft unless you specifically tell us to do so and we will always try to place you on a jet plane before searching alternatives using non-jet aircraft.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingFifteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseFifteenf4"
                                            aria-expanded="false" aria-controls="collapseFifteenf4">
                                      Can I make a reservation for someone other than myself?
                                    </button>
                                  </h2>
                                  <div id="collapseFifteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingFifteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Yes! You can purchase up to eight tickets, regardless of whether or not you are traveling with the passengers. You must enter each passenger's name exactly as it appears on his or her valid government-issued photo ID. If the name that appears in the airline's reservation system does not match the passenger's identification, the airline may not allow the passenger to board.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSixteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSixteenf4"
                                            aria-expanded="false" aria-controls="collapseSixteenf4">
                                      Can I get a refund?
                                    </button>
                                  </h2>
                                  <div id="collapseSixteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingSixteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Your options to change or cancel are dependent upon the airline's fare rules and restrictions. Itinerary changes, if permitted by the fare, may have a change fee charged by the airline typically ranging between $100 to $250 USD per ticket that will vary by market, carrier and specific fare rule. In addition, any changes to your itinerary will incur a $30 USD per ticket service fee.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingSeventeenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseSeventeenf4"
                                            aria-expanded="false" aria-controls="collapseSeventeenf4">
                                      Fare Rules and Restrictions!
                                    </button>
                                  </h2>
                                  <div id="collapseSeventeenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingSeventeenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        If your purchase is non-refundable, non-transferable and non-changeable. Your ticket cannot be changed or cancelled. You must fly on the flights assigned to you. Upgrades and standbys are not available and if any part of the ticket(s) is unused, it has no value after ticketed departure time.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingEighteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseEighteenf4"
                                            aria-expanded="false" aria-controls="collapseEighteenf4">
                                      Can I use an airline voucher?
                                    </button>
                                  </h2>
                                  <div id="collapseEighteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingEighteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Vouchers are only good when you're buying tickets directly from the airline and not a travel agent or other provider. Some vouchers also have restrictions such as blackout dates and almost none are good in conjunction with other discounts, promotions, or coupons. Most vouchers will expire so don't put off using them for too long. Check the small print on the voucher for details of where and when the voucher can be used.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingNinteenf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseNinteenf4"
                                            aria-expanded="false" aria-controls="collapseNinteenf4">
                                      Pregnant or sick?
                                    </button>
                                  </h2>
                                  <div id="collapseNinteenf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingNinteenf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Airlines may require some type of certification that you are safe to fly, depending upon the stage of your pregnancy or the severity of your illness. Consult your physician and contact your airline for details on when flying should be avoided.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwentyf4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwentyf4"
                                            aria-expanded="false" aria-controls="collapseTwentyf4">
                                      What if I miss my flight?
                                    </button>
                                  </h2>
                                  <div id="collapseTwentyf4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwentyf4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        Airlines have different policies regarding passengers with non-refundable tickets who miss flights. If you make it to the airport, but miss your flight, go directly to the main ticket counter or ask the nearest gate agent for assistance. If you cannot make it to the airport, you will need to contact the airline directly. If you need to contact your airline about your reservation, review your itinerary closely to determine which airline operates your flight. Requests to change or cancel your reservation may be denied based on airline policy. Credit will not be given for any unused tickets or reservations.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwentyOnef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwentyOnef4"
                                            aria-expanded="false" aria-controls="collapseTwentyOnef4">
                                      What if the airline cancels my flight?
                                    </button>
                                  </h2>
                                  <div id="collapseTwentyOnef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwentyOnef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        If you are at the airport and are bumped from the flight you are ticketed on or the airline cancels your flight(s) for any reason, including airline strikes or severe weather, you are entitled to the same rights as any other passenger. Speak to the gate agent or an airline representative to learn what your options are.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwentyTwof4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwof4"
                                            aria-expanded="false" aria-controls="collapseTwentyTwof4">
                                      What if I suspect unauthorized charges on my credit card?
                                    </button>
                                  </h2>
                                  <div id="collapseTwentyTwof4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwentyTwof4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        In the unlikely event that an unauthorized use of your credit card occurs, immediately notify your credit card issuer. The appropriate 800 number is usually located on the back of the card. It is critical that you contact your credit card issuer directly; they will explain the specific steps required to report suspected fraud, which generally include signing an affidavit attesting to the unauthorized charges and/or canceling the credit card in question. Your credit card issuer is also responsible for initiating the process to validate and refund or reverse the unauthorized charges on your credit card. Most credit card companies either cover all charges that result from unauthorized use of your credit card or limit your liability to $50.00 USD - the maximum liability allowed under the Fair Credit Billing Act.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwentyThreef4">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwentyThreef4"
                                            aria-expanded="false" aria-controls="collapseTwentyThreef4">
                                      TSA Privacy Notice.
                                    </button>
                                  </h2>
                                  <div id="collapseTwentyThreef4" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwentyThreef4" data-bs-parent="#accordionExampleFive">
                                    <div class="accordion-body">
                                      <p>
                                        The Transportation Security Administration (TSA) of the U.S. Department of Homeland Security requires us to collect information from you for purposes of watch list screening, under the authority of 49 U.S.C. section 114, and the Intelligence Reform and Terrorism Prevention Act of 2004. Providing this information is voluntary however, if it is not provided, you may be subject to additional screening or denied transport or authorization to enter a sterile area. TSA may share information you provide with law enforcement or intelligence agencies or others under its published system of records notice. For more on TSA Privacy policies, or to view the system of records notice and the privacy impact assessment, please visit the TSA Website.
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="faqs_item_wrapper">
                            <h3>Tour Questions</h3>
                            <div class="faqs_main_item">
                              <div class="accordion" id="accordionExampleSix">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOnef5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOnef5" aria-expanded="false"
                                            aria-controls="collapseOnef5">
                                      How do your tours work?
                                    </button>
                                  </h2>
                                  <div id="collapseOnef5" class="accordion-collapse collapse"
                                       aria-labelledby="headingOnef5" data-bs-parent="#accordionExampleSix">
                                    <div class="accordion-body">
                                      <p>
                                        It's easy.  Just head to tour's section on our App or website and browse the many tours and attractions available. 
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwof5">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwof5"
                                            aria-expanded="false" aria-controls="collapseTwof5">
                                      Who supplies your tours?
                                    </button>
                                  </h2>
                                  <div id="collapseTwof5" class="accordion-collapse collapse"
                                       aria-labelledby="headingTwof5" data-bs-parent="#accordionExampleSix">
                                    <div class="accordion-body">
                                      <p>
                                        We have partnered with Viator.com to supply you with amazing tours and attractions around the world.  We want to be your one stop shop for your travel needs.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThreef5">
                                    <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThreef5"
                                            aria-expanded="false" aria-controls="collapseThreef5">
                                      What do I do if I want a refund?
                                    </button>
                                  </h2>
                                  <div id="collapseThreef5" class="accordion-collapse collapse"
                                       aria-labelledby="headingThreef5"
                                       data-bs-parent="#accordionExampleSix">
                                    <div class="accordion-body">
                                      <p>
                                        You can check your confirmation email you received for details about your tour, or you can contact Viator.com directly.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                          </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "FaqArea"
};
</script>