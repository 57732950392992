<template>
    <li>
        <span v-show="isShow" v-on:click="modalToggle"> <!--data-bs-toggle="modal" data-bs-target="#exampleModal" -->
            <i class="fas fa-sign-out-alt"></i>{{$t('logout')}}
        </span>
    </li>

    <div v-if="active && isShow" class="modal">
            <div class="modal-content">
                    <div class="btn_modal_closed">
                        <button type="button" v-on:click="modalToggle"><i
                                class="fas fa-times"></i></button>
                    </div>
                    <h3>
                        {{$t('areyou')}}<br>
                        {{$t('youwantout')}}
                    </h3>
                    <div class="logout_approve_button">
                        <button class="btn btn_theme btn_sm" v-on:click="logout">{{$t('yes')}}</button>
                        <button class="btn btn_border btn_sm" v-on:click="modalToggle">{{$t('no')}}</button>
                    </div>
            </div>
    </div>
</template>
<script>
  import store from '@/store'
export default {
    name: "LogoutBtn",
    data() {
      return {
            isShow : true,
            active: false,
        }
    },
    mounted ()  
    {
      // determine if we are in an app or not.
      this.isShow = store.getters.isNotApp
    },
    methods: {
        modalToggle() {
            // const body = document.querySelector("body")
             this.active = !this.active
          //   this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
        },
        logout() {
          this.$urlRequest.logout()
           .then(() => {               
            this.$router.push('/')
           })
           .catch((error) => {
             this.$router.push('/')
           })
        }
    },
};
</script>
<style scoped>
  /* The Modal (background) */
  .modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999999999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content {
    text-align: center;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 380px; /* Could be more or less, depending on screen size */
  }

  /* The Close Button */
  .closemod {
    color: #333;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

    .closemod:hover,
    .closemod:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
</style>