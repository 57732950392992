<template>
     <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                      <div class="terms_item">
                        <h2>{{$t("privacy")}}</h2>
                        <p>{{$t("onlyenglish")}}</p><br />

                        <h4>1.  Introduction</h4>
                        <p>
                          Thank you for visiting flystaygo and, if applicable, choosing to download and/or install our App. Please read this Privacy Policy and our Terms of Service carefully as you must agree to both in order to have permission to use our Service.
                        </p> 

                      </div>
                      <div class="terms_item">
                        <h4>2.  Definitions</h4>
                        <p>
                          Throughout this document, we may use certain words or phrases, and it is important that you understand the meaning of them. The following is a non-exhaustive list of definitions of words and phrases found in this document:
                          <br>
                          <li>
                            "App" refers to our mobile software application;
                            <br>
                          </li>
                          <li>
                            Flystaygo and Flystaygo-pro refers to our brand and is trademarked by "Major Coup LLC"; our Site; our App; our Service; or a combination of all or some of the preceding definitions, depending on the context in which the word is used;
                            <br>
                          </li>
                          <li>
                            "Service" refers to the services that we provide, including our App and our Site;
                            <br>
                          </li>
                          <li>
                            "Site" refers to our website, www.flystaygo.com;
                            <br>
                          </li>
                          <li>
                            "User" refers to anyone who uses our Service, including users of our App and general visitors to our Site;
                            <br>
                          </li>
                          <li>
                            "You" refers to you, the person who is governed by this Privacy Policy.
                          </li>
                        </p>


                      </div>
                        <div class="terms_item">
                         
                            <h4>3.  Information Collected</h4>
                           
                          <p  >  <b>Identifying Information</b><br />
                          We collect certain personal information from you when you sign up to our Service that can be used to identify you, such as your name, e-mail address, mailing address, telephone number, unique device ID, mobile telephone number, mobile carrier information, Facebook profile information, booking information, and any other information that we may deem necessary for the purpose of providing you with our Service or which you may provide to us voluntarily. The information we collect from you, to the extent that it is private, is disclosed only in accordance with our Terms of Service and/or this Privacy Policy.
                          <br><br />
                          <b >Non-Identifying Information</b><br />
                          Whenever you visit our Site or use our app, we may collect non-identifying information from you, such as your IP address, referring URL, browser, operating system, cookie information, and Internet Service Provider. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, this information alone cannot usually be used to identify you.
                          <br /><br />
                          <b >In addition, we may receive certain categories of Personal Data listed above from sources other than you, specifically from the following categories of sources:</b><br />
                          Our affiliates, subsidiaries, or business partners;  Third party data providers; and individuals purchasing services on your behalf.
                          <br /><br />
                          If you purchase services for other travelers or travel companions, you must make sure that you have the right to provide their Personal Data to us and that those other travelers have accepted how Priceline uses their information as stated in this policy. Personal Data of other travelers is collected only for the purpose of providing such Personal Data to the appropriate travel supplier to fulfill the requested service, or as otherwise may be required or permitted by applicable law.

                          </p>
                        </div>
                        
                        <div class="terms_item">
                          
                            <h4 >4.  Accessing, Editing, and Removing Your Information</h4>
                          <p>
                             
                           <b>Request to Remove/Delete Data</b> <br />
                            At any point you may opt-out from us collecting and retaining data. Data we retain, except for reservtions and purchases made which we must retain by law, can be requested to be deleted/removed by emailing us at info@flystaygo.com along with your email to remove.
                          <br><br />

                            Users may in some cases be able to review and edit the personal information they have provided to us by logging into your account on the Site and editing their account. Although most changes may occur immediately, information may still be stored in a web browser's cache. We take no responsibility for stored information in your cache, or in other devices that may store information, and disclaim all liability of such. In addition, we may, from time to time, retain residual information about you in our backup and/or database.
                          </p>
                        </div>
                      <div class="terms_item">

                        <h4>5.  Use of Your Information</h4>
                        <p>
                          We may use your information to:
                          <li>Enhance or improve User experience, our Site, or our Service.</li>
                          <li>Process transactions and sell, transfer, or exchange with a third party for their marketing purposes with your consent (this may include transferring your information to a hotel at your request to facilitate transactions and bookings or the provision of more information about that hotel to you).</li>
                          <li>Send e-mails about our Site or respond to inquiries.</li>
                          <li>Send e-mails and updates about flystaygo, including our e-mail newsletter.</li>
                          <li>Perform any other function that we believe in good faith is necessary to protect the security or proper functioning of our Site or Service.</li>
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>6.  Third Parties</h4><br />
                        <p >
                          <b>6.1  Third Party Websites</b>
                          <br>Flystaygo may post links to third party websites on its Site or within its App. These third party websites are not screened for privacy or security issues by flystaygo, and you release us from any liability for the conduct of these third party websites. Please be aware that this Privacy Policy, and any other policies in place, in addition to any amendments, does not create rights enforceable by third parties or require disclosure of any personal information relating to members of the Service or Site. flystaygo bears no responsibility for the information collected or used by any advertiser or third party website. Please review the privacy policy and terms of service for each site you visit through third party links.
                        </p>
                        <p>
                          <b>6.2  Third Party Accounts</b>
                          <br>In the course accommodate reservations, flystaygo permits the creation of accounts with our partners for the use of allowing access to rates and reservations.
                        </p>
                         
                      </div>
                      <div class="terms_item">
                        
                          <h4 >7.  Third Party Access to Your Information</h4>
                        <p>
                          Although you are entering into an Agreement with flystaygo to disclose your information to us, we do use third party individuals and organizations to assist us, including contractors, web hosts, and others. Throughout the course of our provision of our Service to you, we may delegate our authority to collect, access, use, and disseminate your information. For example, our web host stores the information that you provide us, and we may hire outside contractors to perform maintenance or assist us in securing our website.
                          <br>It is therefore necessary that you grant the third parties we may use in the course of our business the same rights that you afford us under this Privacy Policy. For this reason, you hereby agree that for every authorization which you grant to us in this Privacy Policy, you also grant to any third party that we may hire, contract, or otherwise retain the services of for the purpose of operating, maintaining, repairing, or otherwise improving or preserving our website or its underlying files or systems. You agree not to hold us liable for the actions of any of these third parties, even if we would normally be held vicariously liable for their actions, and that you must take legal action against them directly should they commit any tort or other actionable wrong against you. Without limiting the generality of the foregoing, you authorize us to collect, share, store, exchange, and otherwise use your information in conjunction with these third parties.

                        </p>
                      </div>
                      <div class="terms_item">
                       
                          <h4 >8. Our Policy On Cookies and Other Tracking Technologies</h4>
                        <p >
                          Cookies and similar tracking technologies, such as beacons, scripts, and tags, are small bits of code, usually stored on a user's computer hard drive or device, which enable a website to "personalize" itself for each user by remembering information about the user's visit to the website.
                          <br /><br />
                          The site uses cookies to store your preferences, display content based upon what you view to personalize your visit, analyze trends, administer the site, track users' movements around the site, serve targeted advertising and gather demographic information about our user base as a whole. On mobile websites and mobile apps, we may use Anonymous Device IDs and/or Advertiser Identifiers in a manner similar to our use of cookies on our websites. Although these activities involve developing an understanding of you as a user, we use this information for the purposes described. Given the nature of our site and services as a travel provider, and the limited amount of Personal Data we obtain about you, this activity does not produce legal effects for you nor otherwise does it significantly affect you. More detailed information about our use of cookies and how to opt-out of advertising and certain other cookies is provided below.
                          <br /><br />
                          <b>Flystaygo Cookies (First Party Cookies):</b><br /> We use our cookies to improve your web-browsing experience. For example, we use a cookie to maintain access to our Pro site without re-entering your credentials. Flystaygo cookies are associated with your Personal Data. However, no third party may use the information we collect through flystaygo cookies for their own purposes.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>9. Release of Your Information for Legal Purposes</h4>
                        <p>
                           At times it may become necessary or desirable to flystaygo, for legal purposes, to release your information in response to a request from a government agency or a private litigant. You agree that we may disclose your information to a third party where we believe, in good faith, that it is desirable to do so for the purposes of a civil action, criminal investigation, or other legal matter. In the event that we receive a subpoena affecting your privacy, we may elect to notify you to give you an opportunity to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either. We may also proactively report you, and release your information to, third parties where we believe that it is prudent to do so for legal reasons, such as our belief that you have engaged in fraudulent activities. You release us from any damages that may arise from or relate to the release of your information to a request from law enforcement agencies or private litigants.
                        </p>
                      </div>
                      <div class="terms_item">
                       
                          <h4>10. Commercial and Non-Commercial Communications</h4>
                        <p>
                          By providing information to the Site that forms the basis of communication with you, such as contact information, you waive all rights to file complaints concerning unsolicited e-mails from flystaygo since, by providing such information, you agree to receive communications from us or anyone else covered under this Privacy Policy. However, you may unsubscribe from certain communications by notifying flystaygo that you no longer wish to receive solicitations or information and we will endeavor to remove you from the database.
                        </p>
                      </div>
                      <div class="terms_item">
                       
                          <h4>11. Security Measures</h4>
                        <p>
                           We take certain measures to enhance the security of our Site and Service, including using SSL certificates and vulnerability scanning. However, we make no representations as to the security or privacy of your information. It is in our interest to keep our website secure, but we recommend that you use anti-virus software, firewalls, and other precautions to protect yourself from security threats.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>12. Your California Online Privacy Rights</h4>
                        <p>
                          Flystaygo permits residents of the State of California to use its Service. Therefore, it is the intent of flystaygo to comply with the California Business and Professions Code: 22575-22579. If you are a California resident you may request certain information regarding our disclosure of personal information to any third parties for their direct marketing purposes. Various provisions throughout this Privacy Policy address requirements of the Californian privacy statutes. In summary, you must presume that we collect electronic information from all visitors. You may contact us at info@flystaygo.com with any questions.
                        </p>
                      </div>
                      <div class="terms_item">
                        
                          <h4>13. International Transfer</h4>
                        <p>
                           Your information may be transferred to-and maintained on-computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to provide information to us, flystaygo transfers Personal Information to the United States and processes it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                        </p>
                      </div>
                      <div class="terms_item">
                       
                          <h4>14. Amendments</h4>
                        <p>
                          Like our Terms of Service, we may amend this Privacy Policy from time to time. When we amend this Agreement, we will place a note on our Site as to when this Privacy Policy was last modified, and we will also require your agreement to the amendments as a condition of your continued use of our Service. Failure to agree to the amendments will result in your inability to use our Service.
                        </p><br>
                        <p>Last Modified: April 4, 2024</p>

                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
    name: "PrivacyPolicyContent"
};
</script>