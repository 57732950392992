<template>
  <div  v-if="active" class="modal">
    <div class="modal-content"><div  v-html="data"></div>
      <div v-on:click="$emit('closing', false)" class='btn btn_theme btn_sm'>{{confirm}}</div>
     </div> 
  </div>
</template>

<script>

  export default {
  name: 'Modal',
  props: {
    active : Boolean,
    data: String,
    confirm : String
   
    } 

  }
</script>

<style scoped>
   /* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  text-align : center;
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 380px; /* Could be more or less, depending on screen size */

}

/* The Close Button */
.closemod {
  color: #333;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closemod:hover,
.closemod:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} 
</style>