import store from '@/store'
//import router from '@/router'

const config = {
  'dev': {
    serverURL: 'https://serverfsg.jvgames.com:63000/webaccess/',
    flystaygoImage: 'https://extranetfsg.jvgames.com:63000/resources/images/',
    cors: '*'
  },
  'live': {
    serverURL: 'https://server.flystaygo.com/webaccess/',
    flystaygoImage: 'https://portal.flystaygo.com/resources/images/',
    cors: 'https://flystaygo.com'
  }
}

function generateUUID () { // Public Domain/MIT
  var d = new Date().getTime()// Timestamp
  var d2 = (performance && performance.now && (performance.now() * 1000)) || 0// Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else { // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

async function logOut () {
  // router.push('/login')

  await store.dispatch('UPDATE_USER', { 
    fullname: null,
    user: null,
    guid: null,
    username: null,
    permissions : 'fsg',
    priceAccess : 'fsg',
    company: 0
  })
}

async function redirectFunc(data)
{
  /*--  if(data.redirect == 'PaymentRequired')
  {
    var stripe = Stripe(data.pk);
    stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: data.pkid
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      console.log(result.error.message)

      this.$notification['warning']({
        message: 'A Payment is required to continue, but',
        description: result.error.message
      })
                
    });
  }
  else*/
  this.$router.push(data);
  //--  window.location.href = data.redirect + store.getters.uriSearch
}

async function setCookies() {
  var user = { fullname: store.getters.fullname, guid: store.getters.guid, username: store.getters.username, company: store.getters.company }
  $cookies.set('flystaygo_user', user, '120d')
  
  
  var  data = {isNotApp: store.getters.isNotApp, permissions: store.getters.permissions, priceAccess: store.getters.priceAccess,
    userCurrency : store.getters.userCurrency, userTourCurrency : store.getters.userTourCurrency, userLanguage : store.getters.userLanguage,
    searchname: store.getters.searchname, searchlat : store.getters.searchlat, searchlong : store.getters.searchlong, searchid: store.getters.searchid, hotdest : store.getters.hotdest, sortby : store.getters.sortby,
    stars: store.getters.stars, rating: store.getters.rating, ammen: store.getters.ammen, searchin: store.getters.searchin, searchout: store.getters.searchout,
    searchadult: store.getters.searchadult, searchchild: store.getters.searchchild, searchroom: store.getters.searchroom, searchsort: store.getters.searchsort,
    searchpage: store.getters.searchpage, tsearchname: store.getters.tsearchname, tsearchid: store.getters.tsearchid, city: store.getters.city, cityName: store.getters.cityName,
    promo: store.getters.promo}

  $cookies.set('flystaygo_data', data, '1d')
}

async function fetchData (url = '', type = 'GET', data = {}) {
  // Default options are marked with *
  let response
  var cors = (location.port == 443 || location.port == 80 || location.port.length === 0 || location.port === null || typeof( location.port) === 'undefined' ) ? config.live.cors : config.dev.cors
  if (type == 'GET') {
    response = await fetch(url)
  } else // post
  {
    /* var formData = new FormData();

    for (var k in data) {
      formData.append(k, data[k]);
    } */
    data['userAgent'] = navigator.userAgent // this.$userAgent

    console.log("USER AGENT = " + data['userAgent'])

    var sjson = JSON.stringify(data)
    console.log(sjson)
    response = await fetch(url, {
      method: 'POST',
 /*     mode: 'no-cors',*/
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': cors
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: sjson // formData
    })
  }

  if (!response.ok) { // if HTTP-status is NOT 200-299
    return { 'status': 2147483648, 'error': { 'message' : 'HTTP-Error: ' + response.status }}
  }
  const contentType = response.headers.get('content-type')
  if (!contentType || !contentType.includes('application/json')) {
    return { 'status': 2147483648, 'error': { 'message' : 'No JSON data returned.' }}
  }

  let retdata = await response.json()

  if (retdata.status >= 0x80000000) { return { 'status': retdata.status, 'error': retdata.error } }

  return retdata

  /* let response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  }); */
}

// cookie info
// https://www.npmjs.com/package/vue-cookies

export default {
  name: 'urlRequest',
  install: (Vue, options) => {
    
    const { $cookies } = Vue.config.globalProperties

    $cookies.config('120d')

    var url = (location.port == 443 || location.port == 80 || location.port.length === 0 || location.port === null || typeof( location.port) === 'undefined' ) ? config.live.serverURL : config.dev.serverURL;
    var imageurl = (location.port == 443 || location.port == 80 || location.port.length === 0 || location.port === null || typeof( location.port) === 'undefined' ) ? config.live.flystaygoImage : config.dev.flystaygoImage
    var _user = $cookies.get('flystaygo_user')
    var _data = $cookies.get('flystaygo_data')
    var ccpk = '';
    var ccid = '';

    console.log('port:  ' + location.port)

    if (_user !== undefined && _user !== null) {
      store.dispatch('UPDATE_USER', {
        //user: _user.fullname,
        fullname: _user.fullname,
        guid: _user.guid,
        username: _user.username,
        company: _user.company,
        cookie: true
      })
    }

    if (_data !== undefined && _data !== null) {
      store.dispatch('UPDATE_USER', {
        isNotApp: _data.isNotApp,
        userCurrency : _data.userCurrency,
        userTourCurrency : _data.userTourCurrency, 
        userLanguage : _data.userLanguage,
        searchname: _data.searchname,
        searchlat: _data.searchlat,
        searchlong: _data.searchlong,
        searchid: _data.searchid,
        hotdest : _data.hotdest,
        sortby : _data.sortby,
        stars : _data.stars,
        rating : _data.rating,
        ammen : _data.ammen,
        searchin: _data.searchin,
        searchout: _data.searchout,
        searchadult: _data.searchadult,
        searchchild: _data.searchchild,
        searchroom: _data.searchroom,
        searchsort: _data.searchsort,
        searchpage: _data.searchpage,
        city: _data.city,
        cityName: _data.cityName,
        promo: _data.promo,
        cookie: true
      })
    }

    const urlRequest = {

       
      post: async (route, action, payload) => {
       
        var query = url + route + '?noStr=true&action=' + action;
        query += (store.getters.guid === undefined || store.getters.guid == null) ? '' : '&guid=' + store.getters.guid      
        query += (store.getters.username === undefined || store.getters.username == null) ? '' : '&username=' + store.getters.username
        query += (store.getters.company === undefined || store.getters.company == null || store.getters.company == 0) ? '' : '&company=' + store.getters.company
        console.log("WE ARE FUCKING HERE")
        let data = await fetchData(query, 'POST', payload)

        if ((data.status & 0x80000000) == 0) {
          if (data.priceAccess != store.getters.priceAccess || data.accountType != store.getters.permissions)
          {
            await store.dispatch('UPDATE_USER', {
              permissions: data.accountType,
              priceAccess: data.priceAccess,
            })

            await setCookies()
          }
         /*-- switch (route + '.' + action) {
            case 'accounts.changePassword': // add data to allow an imediate login...
              await store.dispatch('UPDATE_STATE', { expire: data.expire })
              await redirectFunc(data)
              //window.location.href = data.redirect
              break
            case 'accounts.updateCC':
              // make it a "payment reqired" but its just a change CC data..
              data.redirect = 'PaymentRequired'
              await redirectFunc(data)
              break
          }*/
        } else {
          switch (data.status) {
            case 0x80000003: // if we are in a logout mode, then log out....
              await logOut()
              throw ({ 'code': 'Access Error:', 'status': data.status, 'message': 'You are not signed in! ' })
              break
            case 0x80000002:
              throw ({ 'code': 'Password Error:', 'status': data.status, 'message': 'You have entered an invalid password, try again! ' })
              break
            case 0x80000102:
              throw ({ 'code': 'Password Error:', 'status': data.status, 'message': 'This password is the same as the old password, it must be different!' })
              break
            case 0x80000100:
            case 0x80000103:
              throw ({ 'code': 'Password Error:', 'status': data.status, 'message': 'You have not met the security level of the password!' })
              break
            case 0x80000009:
              throw ({ 'code': 'Account Restriction:', 'status': data.status, 'message': 'This Organization must contain at least one Parter Manager account.' })
              break
            case 0x8000000d:
              throw ({ 'code': 'Not Found Error:', 'status': data.status, 'message': 'This Account was not found.' })
              break
            case 0x8000000f:
              throw ({'code':'User Name Error:', 'status':data.status, 'message':"This Account requires at least a first and last name."});
              break;
            case 0x80000000:
              throw ({ 'code': 'Generic Error:', 'status': data.status, 'message': (data.error == '') ? 'A Generic error occurered on the server!' : data.error })
              break
            case 0x80000007:
              throw ({ 'code': 'Duplicate Email Error:', 'status': data.status, 'message': 'Another account exists with this email address!' })
              break
            case 0x8000000c:
              throw ({ 'code': 'Email Required:', 'status': data.status, 'message': 'A valid email is required for this account!' })
              break
            case 0x80040000:
              throw ({ 'code': 'Stripe Error:', 'status': data.status, 'message':  'There was an error accessing the payment system, try later!' })
              break
            case 0x80040002:
              throw ({ 'code': 'Promo Code Error:', 'status': data.status, 'message': 'Promo code expired or unknown!' })
              break
             case 0x80060007:
               throw ({ 'code': 'Invalid Phone Number:', 'status': data.status, 'message': 'The number supplied did not seem to be a phone number.' })
               break;
            default: // all booking errors 
              console.log(data)
              throw ({ 'code': data.error.code, 'status': data.status, 'message': data.error.message, 'title' : data.error.title })
              break
          }
        }

        return data
      },
      cookies: async () => {
        await setCookies()
        console.log("Just after ")
        return true
      },  
      forgot: async (account, email) => {
        var query = url + 'accounts?noStr=true&action=forgotReset&email=' + email
        query += (account === undefined || account == null || account.length <= 0) ? '' : '&company=' + account

        let data = await fetchData(query)

        if (data.status >= 0x80000000) { throw ({ 'code': 'Reset Failed:', 'status': data.status, 'message': (data.error == '') ? 'Reset password failed, try again' : data.error }) }

        return true
      },
      login: async (account, username, pass, isChange) => {
        var guid = generateUUID()
        var query = url + 'accounts?noStr=true&action=login&guid=' + guid // + '&loginuser=' + username + '&password=' + pass
        query += (account === undefined || account == null || account.length <= 0) ? '' : '&company=' + account
        if (store.getters.uriSearch != '') query += '&skipPass=true'

        let arr = {loginuser : username, password : pass}
        let data = await fetchData(query, 'POST', arr)

        if (data.status >= 0x80000000) { throw ({ 'code': 'Login Failed:', 'status': data.status, 'message': (data.error == '') ? 'Login attempt failed, try again' : data.error }) }
        // store login user data here....
        await store.dispatch('UPDATE_USER', {
          user: (data.status == 2 || isChange) ? data.fullName : null,
          fullname: data.fullName,
          // companyname: data.compName,
          // title: data.title,
          guid: guid,
          username: data.username,
          permissions: data.accountType,
          priceAccess: data.priceAccess,
          company: data.company
          // level: data.lvl,
          // access: data.access,
          // create: data.create,
        })
        await setCookies()
       // var user = { fullname: store.getters.fullname, guid: store.getters.guid, username: store.getters.username, permissions: store.getters.permissions ,company: store.getters.company }
      //  Vue.$cookies.set('flystaygo_user', user, '120d')

        if (data.status == 2 || isChange == true) {
         //- var tpass = (isChange) ? '' : pass;
          
            await redirectFunc('/pro#/auth/change-password');  // force a password change...

       //   await store.dispatch('UPDATE_STATE', { isChangePass: isChange, expire: data.expire, tempPass: tpass  })
         /*--    if (data.redirect == 'PaymentRequired')
            await redirectFunc(data)   */
         // router.push('auth/change-password').catch(err => {})
        } /* --   else if (data.status == 1 && data.redirect.length > 0) {
          await redirectFunc(data)
          //window.location.href = data.redirect
          // window.open(url);
        }*/

        // get what is in the store

        return true
      },
      checkLogin: async (promo, search) => {

        search = (search != null) ? atob(search) : '';
        promo = (promo != null) ? promo : '';
        console.log('USERNAME:  ' + store.getters.username)
     //--   await store.dispatch('UPDATE_STATE', { promo: promo, uriSearch: search, urlRedirect: '' , isRedirect: false   })
        if (store.getters.guid !== undefined && store.getters.guid !== null && store.getters.username !== undefined && store.getters.username !== null) {

          var query = url + 'accounts?noStr=true&action=checkLogin&guid=' + store.getters.guid + '&username=' + store.getters.username
          query += (store.getters.company === undefined || store.getters.company == null || store.getters.company == 0) ? '' : '&company=' + store.getters.company

          let data = await fetchData(query)

          var redirect = ''
          if (data.status < 0x80000000 /*&& data.redirect.length > 0*/) {

            await store.dispatch('UPDATE_USER', {
              permissions: data.accountType,
              priceAccess: data.priceAccess,
            })

            await setCookies()
            //var user = { fullname: store.getters.fullname, guid: store.getters.guid, username: store.getters.username, permissions: store.getters.permissions, company: store.getters.company }
            //Vue.$cookies.set('flystaygo_user', user, '120d')
           // redirect = data.redirect
            // setup the encoded string to jump to if we are logging in...
            
         /*--   if (data.redirect == 'PaymentRequired')
              await redirectFunc(data)*/
            // window.location.href = data.redirect;
          }
  
          // if user is NOT found, pass to register
     /*--     if (redirect.length > 0 && search != '') {
            await store.dispatch('UPDATE_STATE', { urlRedirect: redirect, expire: data.expire, isRedirect: false})
            await redirectFunc(data)
          }
          else if (data.status == 0x8000000d) // if User is not found...
            await store.dispatch('UPDATE_STATE', { urlRedirect: redirect, expire: data.expire, isRedirect: true})
          else
            await store.dispatch('UPDATE_STATE', { urlRedirect: redirect, expire: data.expire, isRedirect: false})
            */

          //  window.location.href = '/#/register'
          //this.$router.push('/register');
        } /*--- else if (store.getters.cookie == null && promo != '') {
          await store.dispatch('UPDATE_STATE', { isRedirect: true })
            //this.$router.push('/register');
           // window.location.href = '/#/register'
        }*/
      },
      logout: async () => {
        var query = url + 'accounts?noStr=true&action=logout&guid=' + store.getters.guid + '&username=' + store.getters.username
        query += (store.getters.company === undefined || store.getters.company == null || store.getters.company == 0) ? '' : '&company=' + store.getters.company

        let data = await fetchData(query)

        await logOut()
    /*    await store.dispatch('UPDATE_USER', {
          user: null,
          fullname: null,
          guid: null,
          username: null,
          permissions: "fsg",
          company: null
        })*/
 //--       await store.dispatch('UPDATE_STATE', { urlRedirect: '' })

        await setCookies()
        //var user = { fullname: store.getters.fullname, guid: store.getters.guid, username: store.getters.username, permissions: store.getters.permissions, company: store.getters.company }
        //Vue.$cookies.set('flystaygo_user', user, '120d')
      },
      returnImageUrl: () => {
        return imageurl
      }

    }

 

    return urlRequest

    /* auth.onAuthStateChanged(user => {
      store.commit('UPDATE_USER', { user })
    }) */
  }
}
