<template>
    <section id="our_partners" class="section_padding">
      <div class="container">
        <!-- Section Heading -->
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section_heading_center">
              <h2>{{$t("partners")}}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="partner_slider_area owl-theme owl-carousel">
              <swiper :slides-per-view="slidesPerView">
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/1.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/2.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/3.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/4.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/5.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>

                  <div class="partner_logo">
                    <img src="../../assets/img/partner/6.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/7.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/8.png" alt="logo">
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="partner_logo">
                    <img src="../../assets/img/partner/9.png" alt="logo">
                  </div>
                </swiper-slide>

              </swiper>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="marquee marquee--hover-pause">
              <p class="marquee__content"><span  v-for="(item,key) in xmlPages" value="key"> <a :href="item.url">{{item.name}}</a>&nbsp;&nbsp; &middot; &nbsp;&nbsp;</span></p>
            </div>
          </div>
        </div>
      </div>
</section>
</template>

<script>

// import Swiper core and required components
import SwiperCore, { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";


// install Swiper components
SwiperCore.use([Pagination,]);

export default {
    name: "Partners",

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        return {
          swiper: null,
          xmlData : [],
          xmlPages : [],
          slidesPerView: 9,
        };
    },
 
    async mounted() {
      //this.items = data.hotelData
      let size = window.innerWidth 
      this.slidesPerView = (size >= 992) ? 9 : 4

      window.addEventListener('resize', () => {
        let size = window.innerWidth 
        this.slidesPerView = (size >= 992) ? 9 : 4
      })

      // handle offset
      var path = this.$route.path
      var offset = 0;
      if (path.length >= 4)
      {
        if (path.substring(0,4) == '/pro') offset = 1
        else if (path.substring(0,4) == '/tou') offset = 2
      }


      this.xmlData = []
      this.xmlPages = []

      const parser = new DOMParser();

      var str = "../"
      var last = this.$route.path.slice(-1)

      if (last == "/" || last == "\\")
        str = "../../"

      console.log(str)

      var response = await fetch(str+'sitemap.xml')
      var text = await response.text()

      //console.log(text)
      var xmlDoc = await parser.parseFromString(text, "application/xml");

      var lNodes = xmlDoc.getElementsByTagName('sitemap')
      var page = 0;
      for (let i = 0; i < lNodes.length; i++) {
        var loc = lNodes[i].getElementsByTagName('loc')[0].textContent
        var spl = loc.split('/')
        if (loc.includes("xml_") === true)
        {
          this.xmlData.push(  str + spl[spl.length-1] )
          page++
        }

      }
      //console.log(this.xmlData)

      // fetch date
      let newDate = Date.now()
      if (newDate > 4294967295)
        newDate /= 86400*1000
      else
        newDate /= 86400

      newDate = Math.floor(newDate)
      var remainder = (newDate+offset) % page
      console.log("Date Number " + newDate + " " + remainder)

      // now get the correct value... this.xmlData
      response = await fetch(this.xmlData[remainder])
      text = await response.text()
      xmlDoc = await parser.parseFromString(text, "application/xml");

      lNodes = xmlDoc.getElementsByTagName('url')
      console.log("Node Count " + lNodes.length)
      for (let i = 0; i < lNodes.length; i++) {
        var loc = lNodes[i].getElementsByTagName('loc')[0].textContent   
      
        if (loc.indexOf("poipage") == -1)
        {
          var spl = loc.split('/')
          var end = spl[spl.length-1].split('-')
          var name = ""
          for (let j = 1; j < end.length; j++)
            name += (end[j]!='p') ? end[j] + ' ' : ''
          this.xmlPages.push( {'name' : name, 'url' : loc} )
          
        }

      }
    } 
};
</script>