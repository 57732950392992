<template>
     <section id="tour_details_main" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="terms_service_content">
                      
                      <div class="terms_item">
                        <h2>Page {{pageNumber}} Locations of Interest</h2>
                        <br /><br />
                        <h4>List of locations</h4>
                        <p>To access the links to these destinations, simply click or tap on each destination's name listed below. You'll be directed to a page where you can delve deeper into the wonders of each location. Whether you're planning your next adventure or simply daydreaming about far-off lands, these links will transport you to the heart of each destination, offering insights into accommodations. So, grab your passport and embark on a virtual journey around the world by clicking on the links provided. Happy exploring!</p>
                        <br />
                        <div class="row">

                          <li class="col-lg-4" v-for="(item,key) in xmlData" value="key"><u><a :href="item.url">{{item.name}}</a></u></li>
                        </div>

                      </div>



                      </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
  name: "POIpageContent",
  data() {
    return {
      xmlData : [],
      pageNumber : 0,
    }
  },
 
  async mounted() {
    const parser = new DOMParser();
    var sitemap = '../' + this.$route.query.l

    var spl = this.$route.query.l.split('/')
    var pageSplit = spl[spl.length-1].split('.')
    var pageSplit2 = pageSplit[0].split('-')
    this.pageNumber = (pageSplit2.length > 1) ? pageSplit2[1] : 1

    //this.pageNumber = //this.$route.query.p
    
    console.log("sitemap " + sitemap + this.pageNumber)
    var response = await fetch(sitemap)
    var text = await response.text()

    console.log(text)
    var xmlDoc = await parser.parseFromString(text, "application/xml");
    
    var lNodes = xmlDoc.getElementsByTagName('url') 
    var page = 1;
    for (let i = 0; i < lNodes.length; i++) {
      var loc = lNodes[i].getElementsByTagName('loc')[0].textContent   
      
      if (loc.indexOf("poipage") == -1)
      {
        var spl = loc.split('/')

        this.xmlData.push( {'name' : spl[spl.length-2] + ' ' +spl[spl.length-1], 'url' : loc} )
        page ++
      }

    }

    /*
    for (let i = 0; i < lNodes.length; i++) {
      var loc = lNodes[i].getElementsByTagName('loc')[0].textContent
      console.log("count " + i)
       
      if (loc.includes("xml_") === true)
      {
        console.log("we are in " + loc.includes("xml_"))
        this.xmlData.push( {'name' : 'Top Page #'+page, 'url' : loc} )
        page ++
      }

    }*/
 
    console.log(this.xmlData)
 

  
  }
};
</script>