<template>

    <!-- Common Banner Area -->
    <TermsServiceBanner />

    <!-- Terms Service Areas -->
    <TermsServiceContent />

    <!-- Cta Area 
    <Cta />-->

</template>
<script>
import TermsServiceBanner from '@/components/pages/TermsServiceBanner.vue'
import TermsServiceContent from '@/components/pages/TermsServiceContent.vue'
//import Cta from '@/components/home/Cta.vue'

export default {
  name: "TermsServiceView",
    components: {
      TermsServiceBanner, TermsServiceContent//, Cta
    }
};
</script>
