import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TourView from '../views/TourView.vue'
import HotelPageView from '../views/HotelPageView.vue'
import TourPageView from '../views/TourPageView.vue'
//import HomeTwoView from '../views/HomeTwoView.vue'
import TourSearchView from '../views/TourSearchView.vue'
//import TourDetailsView from '../views/TourDetailsView.vue'
//import TourBookingSubmissionView from '../views/TourBookingSubmissionView.vue'
//import TopDestinationsView from '../views/TopDestinationsView.vue'
//import FlightSearchResultView from '../views/FlightSearchResultView.vue'
//import FlightBookingSubmission from '../views/FlightBookingSubmission.vue'
import HotelSearchView from '../views/HotelSearchView.vue'
import HotelDetailsView from '../views/HotelDetailsView.vue'
//import RoomDetailsView from '../views/RoomDetailsView.vue'
import RoomBookingView from '../views/RoomBookingView.vue'
import AboutView from '../views/AboutView.vue'
//import TourGuidesView from '../views/TourGuidesView.vue'
//import TestimonialsView from '../views/TestimonialsView.vue'
import FaqsView from '../views/FaqsView.vue'   
import BookingConfirmationView from '../views/BookingConfirmationView.vue'
import ComingSoonView from '../views/ComingSoonView.vue'
//import NewsView from '../views/NewsView.vue'
//import NewsV2View from '../views/NewsV2View.vue'
//import NewsDetailsView from '../views/NewsDetailsView.vue'
//import LoginView from '../views/LoginView.vue'
import ProView from '../views/ProView.vue'
//import RegisterView from '../views/RegisterView.vue'
//import ResetPasswordView from '../views/ResetPasswordView.vue'
//import ForgotPasswordView from '../views/ForgotPasswordView.vue'
//import VerifyOtpView from '../views/VerifyOtpView.vue'
import DashboardView from '../views/DashboardView.vue'
//import HotelBookingView from '../views/HotelBookingView.vue'
//import FlightBookingView from '../views/FlightBookingView.vue'
//import TourBookingView from '../views/TourBookingView.vue'
//import BookingHistoryView from '../views/BookingHistoryView.vue'
import MyProfileView from '../views/MyProfileView.vue'
//import WalletView from '../views/WalletView.vue'
import RedirectView from '../views/RedirectView.vue'
//import NotificationView from '../views/NotificationView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import ReviewCancelView from '../views/ReviewCancelView.vue'
import POIpageView from '../views/POIpageView.vue'
import POImainView from '../views/POImainView.vue'
import TermsServiceView from '../views/TermsServiceView.vue'
import ErrorView from '../views/ErrorView.vue'
//import ContactView from '../views/ContactView.vue'
//import BecomeVendorView from '../views/BecomeVendorView.vue'
import NotFound from '../components/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tours',
    name: 'tours',
    component: TourView
  },
  {
    path: '/pages/hotels/:headparam/:modifierparam',
    name: 'hotelPage',
    component: HotelPageView
  },
  {
    path: '/pages/tours/:headparam/:modifierparam',
    name: 'tourPage',
    component: TourPageView
  },

/*  {
    path: '/home-two',
    name: 'home-two',
    component: HomeTwoView
  },*/
  {
    path: '/tour-search',
    name: 'tour-search',
    component: TourSearchView
  },
 /*  {
    path: '/tour-details',
    name: 'tour-details',
    component: TourDetailsView
  },
  {
    path: '/tour-booking-submission',
    name: 'tour-booking-submission',
    component: TourBookingSubmissionView
  },
  {
    path: '/top-destinations',
    name: 'top-destinations',
    component: TopDestinationsView
  },
  {
    path: '/flight-search-result',
    name: 'flight-search-result',
    component: FlightSearchResultView
  },
  {
    path: '/flight-booking-submission',
    name: 'flight-booking-submission',
    component: FlightBookingSubmission
  }, */
  {
    path: '/hotel-search',
    name: 'hotel-search',
    component: HotelSearchView
  },
  {
    path: '/hotel-details',
    name: 'hotel-details',
    component: HotelDetailsView
  },
 /* {
    path: '/room-details',
    name: 'room-details',
    component: RoomDetailsView
  },*/
  {
    path: '/room-booking',
    name: 'room-booking',
    component: RoomBookingView
  },
  {
    path: '/pro/:headparam?',
    name: 'pro',
    component: ProView
  },
  {
    path: '/info/contact',
    name: 'about',
    component: AboutView
  },
/*  {
    path: '/tour-guides',
    name: 'tour-guides',
    component: TourGuidesView
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: TestimonialsView
  },*/
  {
    path: '/info/faq',
    name: 'faqs',
    component: FaqsView
  },
  {
    path: '/booking-confirmation',
    name: 'booking-confirmation',
    component: BookingConfirmationView
  },
  {
    path: '/coming-soon',
    name: 'coming=soon',
    component: ComingSoonView
  },
 /* {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/news-v2',
    name: 'news-v2',
    component: NewsV2View
  },
  {
    path: '/news-details',
    name: 'news-details',
    component: NewsDetailsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/verify-otp',
    name: 'verify-otp',
    component: VerifyOtpView
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordView
  },*/
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
/*  {
    path: '/hotel-booking',
    name: 'hotel-booking',
    component: HotelBookingView
  },
  {
    path: '/flight-booking',
    name: 'flight-booking',
    component: FlightBookingView
  },
  {
    path: '/tour-booking',
    name: 'tour-booking',
    component: TourBookingView
  },
  {
    path: '/booking-history',
    name: 'booking-history',
    component: BookingHistoryView
  },*/
  {
    path: '/my-profile',
    name: 'my-profile',
    component: MyProfileView
  },
/*  {
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },*/
  {
    path: '/air',
    name: 'air',
    component: RedirectView
  },
  {
    path: '/car',
    name: 'car',
    component: RedirectView
  },
/*  {
    path: '/notification',
    name: 'notification',
    component: NotificationView,
  },*/
  {
    path: '/info/privacy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  },
  {
    path: '/info/cancel',
    name: 'review-cancel',
    component: ReviewCancelView
  },
  {
    path: '/info/terms',
    name: 'terms-service',
    component: TermsServiceView
  },
  {
    path: '/info/poi',
    name: 'poi-toplists',
    component: POImainView
  },
  {
    path: '/info/poipage',
    name: 'poi-page',
    component: POIpageView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
/*  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/become-vendor',
    name: 'become-vendor',
    component: BecomeVendorView
  },*/
  {
    path: '/:cathAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]


const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  console.log('window.scrollY:', window.scrollY)
  from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY)
  console.log('from:\t', from.name, '\t', from.meta?.scrollPos)
  console.log('to:\t\t', to.name, '\t', to.meta?.scrollPos)
  return next()
})

export default router