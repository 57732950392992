<template>
     <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h1 class="honeoverride">{{location}}</h1>
                        <ul>
                            <li>{{$t("hotelresult")}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import store from '@/store'

export default {
  name: "Banner",
  data() {
    return {
      location : store.getters.searchname,
    }
  },
  watch: {
    '$store.getters.searchname' : function () {
      this.location = (store.getters.searchname != null) ? store.getters.searchname : location
    }
  }
};
</script>