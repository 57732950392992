<template>
    <section id="partner_area" class="section_padding_top">
        <div class="container">
            <div class="partner_area_bg">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="partner_area_left">

                        <h2>{{$t("moveworks")}}</h2>
                     <!--   <p>
                        </p>
                        <div class="partner_video_area">

                          <span class="video_btn video_play_area" title="Youtube Video" v-on:click="modalToggle" >
                             
                            <i class="fas fa-play"></i>
                          </span>

                          <div v-show="active" class="modal fade"
                               tabindex="-1" role="dialog">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                 <div class="modal-body logout_modal_content">
                                 <div class="btn_modal_closed">
                                    <button type="button" @click="modalToggle"
                                            aria-label="Close">
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </div>

                                <div id="yt-player">
                                      <iframe width="420" height="315"
                                          src="https://www.youtube.com/embed/3uJC5hHNaKg?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>
                                  </div>  
                                </div>  
                              </div> 
                            </div>
                          </div>
                        </div>  -->
                        <p>
                          &nbsp;
                        </p>
                        <h5>{{$t("movnumbers")}}</h5>

                      

                            <ul class="local_counter_area">
                              <li class="local_counter_box">
                                <h5>&nbsp;</h5>
                                <h2>11</h2>
                                <h5>{{$t("lang")}}</h5>
                              </li>
                              <li class="local_counter_box">
                                <h5>&nbsp;</h5>
                                <h2>600K+</h2>
                                <h5>{{$t("hotels")}}</h5>
                              </li>
                              <li class="local_counter_box">
                                <h5>{{$t("upto")}}</h5>
                                <h2>75%</h2>
                                <h5>{{$t("offhotels")}}</h5>
                              </li>
                            </ul>
                      </div>
                    </div>
                  <div class="col-lg-6 col-md-12">
                    <p class="partner_area_image">
                      <!--<img   src="../../assets/img/common/fsg_how.png" alt="img"> -->
                      <div id="yt-player">
                        <iframe style="border: 0; width:95%; height: 400px; overflow: auto;" src="https://www.youtube.com/embed/3uJC5hHNaKg?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>
                      </div>  
                    </p>
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "FindTravellPartner",
    data() {
        return {
            active: false,
        }
    },

    methods: {
        modalToggle() {
           const body = document.querySelector("body")
            this.active = !this.active
            //this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")

            var videos = document.querySelectorAll('iframe, video');
            Array.prototype.forEach.call(videos, function (video) {
              console.log(video.tagName)
                if (video.tagName.toLowerCase() === 'video') {
                    video.pause();
                } else {
                    var src = video.src;
                    video.src = src;
                }
            });  

        },
    },
};
</script>