<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="tou_booking_form_Wrapper">
                      <center>
                        We are constructing new Flights and Car Rental pages.  We look forward to showing you our new improved pages soon.  In the meantime, shop our fantastic hotels and tours products.
                        <img src="@/assets/img/ComingSoon.png">
                      </center>
                      
                    </div>
                </div>
                 
             </div>
        </div>
       
    </section>

</template>
<script>

export default {
  name: "ComingSoon" 
};
</script>