<template>
    <section id="theme_search_form">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="theme_search_form_tabbtn">
                            <ul class="nav nav-tabs" role="tablist">
                            
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="tours-tab" data-bs-toggle="tab"
                                        data-bs-target="#tours" type="button" role="tab" aria-controls="hotels"
                                        aria-selected="false"><i class="fas fa-globe"></i>{{$t("tours")}}</button>
                                </li>
                            </ul>
                        </div>

                        <div class="tab-content" id="myTabContent">
                        
                          
                            <div class="tab-pane show active" id="hotels" role="tabpanel" aria-labelledby="hotels-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form v-on:submit.prevent="beginSearch($event)"> 
                                                <div class="row">
                                                    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                                                      
                                                        <div class="flight_Search_boxed">
                                                            <p>{{$t("whattodo")}}</p>
                                                            <input type="text"  v-on:input="delaySearch" :placeholder="$t('startsearch')" v-model="dest">
                                                           <!-- <span>Where would you like to go?</span> -->

                                                          <ul class="dropdown-autosearch"  v-show="isOpen">
                                                            <li v-for="(name, i) in data" :key="i" @click="select(name.name, name.id)" >
                                                              <a class="dropdown-item">{{name.name}}</a>
                                                            </li>
                                                            
                                                          </ul>

                                                        </div>
                                                      <span class="error_red" v-show="isErrLoc">{{$t("errorLocation")}}</span>
                                                    </div>
                                                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12">
                                                       <!-- <div class="form_search_date"> -->
                                                               <div class="flight_Search_boxed">
                                                             <!-- <div class="Journey_date"> -->
                                                                    <p>{{$t("sortby")}}</p>


                                                                    <select v-model="sortby"  class="dropdown-list drop-adj-z">
                                                                      <option value="T" selected>{{$t("featured")}}</option>
                                                                      <option value="R">{{$t("toprated")}}</option>
                                                                      <option value="P">{{$t("Lowprice")}}</option>
                                                                    </select>
                                                            
                                                                   <!--  <input type="date" v-model="indate">
                                                                  <span>Thursday</span> 
                                                               // </div>
                                                               
                                                            </div>-->
                                                        </div>
                                                      
                                                    </div>
                                                  
                                                    <div class="top_form_search_button">
                                                        <button :class="['btn', 'btn_theme', 'btn_md', {inbtnpause : isButtonPressed}]"> {{$t("search")}} </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

//import CountDropdown from '@/components/home/CountDropdown.vue'
//import CountDropdownRound from '@/components/home/CountDropdownRound.vue'
//import CountDropdownMulti from '@/components/home/CountDropdownMulti.vue'
//import CountDropdownTour from '@/components/home/CountDropdownTour.vue'
//import CountDropdownHotel from '@/components/home/CountDropdownHotel.vue'
import store from '@/store'

export default {
    name: "Form",

    components: {
      //      CountDropdown, CountDropdownRound, CountDropdownMulti, CountDropdownTour, 
      //CountDropdownHotel
    },

   
    data() {   
      return {
    //    indate: new Date().toISOString().substr(0,10),
       // outdate: new Date().toISOString().substr(0,10),
        dest: '',
        ttlLocal : 0,
        fetchTimeout : null,
        ttlValue : 0,
        lettersType : 0,
        destSelected : 0,
        lat: null,
        long: null,
        setDest: '',
        destId : '',
        sortby : 'T',
        data : [],

        order:[],
        errors : [],
        isButtonPressed : false,
        isOpen: false,
        isErrLoc: false,
  //      isErrDate: false,
        rowData: [{ name: '' }]
        };
    },
    mounted () 
    {
      if (this.$route.query.lat && this.$route.query.long)
      {
        this.lat = this.$route.query.lat
        this.long = this.$route.query.long
        this.lettersType ++
        this.fetchSearch()
      }
      else if (store.getters.isNotApp && navigator.geolocation)
      {
        navigator.geolocation.getCurrentPosition(pos => {
          this.onPositionUpdate(pos)
        }, err => {
          console.log("NO GEO")
        })
        //navigator.geolocation.getCurrentPosition(this.onPositionUpdate,onNoPos,{timeout:10000});
      }

    }, 
  methods: {
   /* nextDate: function() {
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.outdate = tomorrow.toISOString().substr(0,10)
        console.log(outdate)
    },*/

    onPositionUpdate(position)
    {
      this.lat = position.coords.latitude
      this.long = position.coords.longitude;
      console.log(position)
      this.lettersType ++
      this.fetchSearch()
    },
    beginSearch(event)
    {
   
      var todayStr = new Date().toISOString().substr(0,10)

 
      this.isErrLoc = (this.destId.length == 0) ? true : false
    //  this.isErrDate = (inDate >= today && outDate > inDate) ? false : true

      if (this.isErrLoc == false )
      {
        console.log("looks like we made it!!! ")
        event.target.disabled = true
        this.isButtonPressed = true
        store.dispatch('UPDATE_USER', {
          tsearchname: this.setDest,
          tsearchid: this.destId,
          searchsort: this.sortby,
          searchpage: 1
        })
        this.$urlRequest.cookies()
          .then((response) => {
            console.log("WE GOOD")
          })
          .catch((error) => {
            console.log("WE BAD " + error)
        })
        this.$router.push({ path: '/tour-search' })
      }
 
    },

    select(name, id)
    {
      console.log("what is ID " + id)
      this.dest = name
      this.setDest = name
      this.destId = id
      this.isOpen = false
      this.destSelected = this.lettersType
    },
    delaySearch()
    {
      this.lettersType ++
      
      // console.log("START TYPEING")
      let newDate = Date.now()
      let diffDate = newDate - this.ttlLocal
      if ((newDate > 4294967295 && diffDate < 600000) || (newDate <= 4294967295 && diffDate < 600))
      {
        //  console.log("CLEAR SEARCH")
        clearTimeout(this.fetchTimeout)
      }
      this.ttlLocal = newDate
     // console.log("BEGIN TIMEOUT")
      this.fetchTimeout = setTimeout(() => {
          console.log("--------------------->START SEARCH")
        this.fetchSearch() // method to call when user is done typing
      },600)

    },
    fetchSearch()
    {
      let arr = {'type' : 'tours'};

    //  this.lettersType ++

      if (this.dest.length > 2 || (this.lat != null && this.long != null))
      {
        if (this.dest.length > 2)
          arr['text'] = this.dest

        if (this.lat != null && this.long != null)
        {
          arr['lat'] = this.lat
          arr['long'] = this.long
        }


        this.$urlRequest.post('fetch', 'search', arr)    // {'text' : this.dest, 'type' : 'hotels'}
          .then((response) => {
            //   this.$nprogress.done()
            //this.loading = false
            if (this.ttlValue <= response.ttl)
            {
              this.data = response.data
              this.ttlValue = response.ttl
            }
            if (response.data.length > 0 && this.lettersType > this.destSelected)
              this.isOpen = true;
            console.log(this.data)
            // this.$router.push('/login')
          })
          .catch((error) => {
            //this.loading = false
            // this.$nprogress.done()
            //this.$notification['warning']({
            // message: error.code,
            //  description: error.message
            //})
          })
      }
      else
        this.isOpen=false
    },
   /*     addRow: function () {

            let a = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

            if (document.querySelectorAll('.multi_city_form').length === 5) {
                alert("Max Citry Limit Reached!!")
                return;
            }

            this.isHidden = true

            this.rowData.push({ name: '' })
        },
        deleteRow(index) {
            this.rowData.splice(index, 1);
        }*/

    }
};
</script>