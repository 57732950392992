<template>
  <div ref="maproot"
       style="width: 100%; height: 100%">
  </div>

 <div id="mymap"> 
    <div v-show="innerShow" v-on:click="Shutoff()" id="popup" :class="['ol-popup', {inbtnpause : pausedFor5seconds}]" :key="innerShow">
     <!-- <a href="#" id="popup-click"> -->
        <div v-html="innerHtml" id="popup-content"></div>
     <!--  </a> -->
    </div>
 </div>
</template>

<script>
  import store from '@/store'
  import View from 'ol/View'
  import Map from 'ol/Map'
  import TileLayer from 'ol/layer/Tile'
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import Overlay from 'ol/Overlay.js'
  import Style from 'ol/style/Style.js'
  import Icon from 'ol/style/Icon.js'
  import Text from 'ol/style/Text.js'
  import Fill from 'ol/style/Fill.js'
  import Stroke from 'ol/style/Stroke.js'
  import OSM from 'ol/source/OSM'
  import Feature from 'ol/Feature.js'
  import Point from 'ol/geom/Point.js'
  import Proj from 'ol/proj.js'
  import {toLonLat} from 'ol/proj.js'
  //import MarkerPng from '@/assets/img/map-pin.png'
 

  // importing the OpenLayers stylesheet is required for having
  // good looking buttons!
  import 'ol/ol.css'

  export default {
    name: 'MapContainer',
    components: {},
    props: {},
    data: () => ({
      // store OL objects on the component instance
      olMap: null,
      olPins: null,
      click: null,
      markers: [],
      innerHtml : '',
      innerShow : false,
      innerKey : null,
      innerPopup : null,
      mapPoints: store.getters.mapPoints,
      mapFeatures : {},
      lat: store.getters.mapLat,
      long: store.getters.mapLong,
      zoom: store.getters.mapZoom,
      zmin : store.getters.mapMinZoom,
      zmax : store.getters.mapMaxZoom,
      pausedFor5seconds : false
    }),
    
    mounted() {

       this.SetupMap();
    },
    
     
    
  methods :{
      SetupMap()
      {
        this.markers = []
      //  console.log("REDO MAP PINS ......... " + this.mapPoints.length)
        Object.keys(this.mapPoints).forEach(key => {
        //  console.log("EACH KEY -- " + key)
          this.mapFeatures[key] = {'feature' : null}
          //   this.mapFeatures.length ++
          this.mapFeatures[key].feature = new Feature({geometry: new Point(this.convertCoordinates(this.mapPoints[key].y , this.mapPoints[key].x) ),
            projection: 'EPSG:3857',
            name : key,
            html : this.mapPoints[key].html}) // html: 

          this.mapFeatures[key].feature.setStyle( this.styleFunction( this.mapPoints[key].price , false ) )
          this.markers.push (this.mapFeatures[key].feature)     
          //  markers.push(new Feature({geometry: new Point(  [this.mapPoints[key].y , this.mapPoints[key].x]  ),
          //   projection: 'EPSG:4326',
       
          // }) )
        })  


        this.innerPopup = document.getElementById('popup')
        this.olPins = new Overlay({
          container : this.innerPopup,
          /*   positioning: 'bottom-center',
             stopEvent: false,*/
          autoPan: true,
          autoPanAnimation: {
            duration: 250
          }
        })

        // this is where we create the OpenLayers map
        this.olMap = new Map({
          // the map will be created using the 'map-root' ref
          target: this.$refs['maproot'],
          layers: [
            // adding a background tiled layer
            new TileLayer({
              source: new OSM({
                url: 'https://tile.jvgames.com:4430/hot/{z}/{x}/{y}.png',
                crossOrigin: null
              }) // tiles are served by OpenStreetMap OR https://tile.openstreetmap.org/{z}/{x}/{y}.png
            }),
             new VectorLayer ({      
               source: new VectorSource({
                 features: this.markers
               })
             }),
           
          ],
          overlays : [this.olPins],
          // the map view will initially show the whole world
          view: new View({
            zoom: parseFloat(this.zoom),
            minZoom : parseFloat(this.zmin),
            maxZoom: parseFloat(this.zmax),
            center: this.convertCoordinates(parseFloat(this.long), parseFloat(this.lat)),
            constrainResolution: false,
            projection :'EPSG:3857',    // EPSG:3857=default     EPSG:4326
            visible: true 
          }),
        })
      
        //this.olMap.addOverlay(this.olPins);
        var self = this
        /*  

        view.setMaxZoom(parseFloat(zmax))
        view.setMinZoom(parseFloat(zmin))

        
           this.click = document.getElementById('popup-click')
    
          this.click.onClick = function() {
            self.olPins.setPostion = undefined 
            self.closer.blur()
            return false
          }*/
      
        this.olMap.on('singleclick', function(event) 
        {
          console.log('start click' )
          // console.log(event.coordinate)
          var features = event.map.getFeaturesAtPixel(event.pixel, {})  
          // console.log(features)
          if (features.length > 0)
          {
            //  console.log(self.olPins )
            self.innerHtml = features[0].getProperties().html
            //  console.log(features[0].getProperties().name)
            self.setMarker(features[0].getProperties().name, false)
            self.innerShow = true
            self.innerKey = features[0].getProperties().name
            store.dispatch('UPDATE_USER', {
              fullMapId: self.innerKey
            })
            self.olPins.setPosition = [0,0] //event.coordinate //features[0].getGeometry().getCoordinates()
            //self.olPins.setPosition(features[0].getGeometry().getCoordinates())
            console.log(self.olPins)
         
          }     
          else
          {
            console.log('click' )
            if (self.innerKey !== null)
              self.unsetMarker(self.innerKey)
            self.innerKey = null
            //          self.olPins.setPostion = undefined 
            self.innerShow = false
          }
        })

        // console.log(this.olPins)
        //  console.log(this.innerPopup)
      
        /*       this.olPins = new VectorLayer ({
               source: new VectorSource(),
               style: new Style({
                 image: new  Icon({
                   anchor: [0.5,1],
                   src: '../assets/img/map-pin.svg'
                 })
               })
             })
       
             var markers = []
             
             Object.keys(this.mapPoints).forEach(key => {
               markers.push (new Feature(new Point(this.mapPoints[key].y , this.mapPoints[key].x )))
               
             })  
             
             this.olPins.getSource().addFeatures(markers)
             this.olMap.addLayer(this.olPins)
             const view = this.olMap.getView()  
             view.setCenter(this.long,this.lat)
          */  
      },
      showMapHtml(key)
      {
        this.innerHtml = this.mapPoints[key].html
        //  console.log(features[0].getProperties().name)
       // this.setMarker(this.mapPoints[key].name)
        this.innerShow = true
        this.innerKey = key // this.mapPoints[key].name
        this.olPins.setPosition = [0,0]       
        console.log(this.olPins)
      },
      Shutoff()
      {
        if (this.pausedFor5seconds == false)
        {
          this.pausedFor5seconds = true
          setTimeout(() => this.pausedFor5seconds = false, 3000);
        }
      },
      getCurrentLongLat()
      {
        return toLonLat( this.olMap.getView().getCenter() );
      },
      clearPins()
      {
        this.mapPoints = []
        this.mapFeatures = {}
      },
      addPin(x,y,key, html, price = '')
      {
        //console.log("KEY : " + key + "  X:" + x + "  Y:" + y + "  html:" + html)

        this.mapPoints[key] = {'x':parseFloat(x),'y':parseFloat(y),'html' : html, 'price' : price}
     //   this.mapFeatures[key] = {'feature' : null}
        this.mapPoints.length ++ 
      },
      convertCoordinates(lon, lat) {
        var x = (lon * 20037508.34) / 180;
        var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return [x, y];
      },
      setMarker(key, setInnerKey)
      {
        console.log("What about the old key " + this.innerKey)
        if (this.innerKey !== null)
          this.unsetMarker(this.innerKey)

        if (this.mapFeatures[key]===undefined)
          return;
        
        if (setInnerKey) this.innerKey = key
        this.mapFeatures[key].feature.setStyle( this.styleFunction(this.mapPoints[key].price, true) )
      },
      unsetMarker(key)
      {
        this.innerKey = null
        this.innerShow = false
        this.pausedFor5seconds = false

        if (this.mapFeatures[key]===undefined)
          return;
        console.log("Now unset old key " + this.innerKey)
        this.mapFeatures[key].feature.setStyle( this.styleFunction( this.mapPoints[key].price, false ) )
      },
     
      updatePos(x,y,z)
      {
        this.lat = x
        this.long = y
        if (z >= this.zmin && z <= this.zmax)
        {
          this.zoom = z
        }

        store.dispatch('UPDATE_USER', {
          mapLat : this.lat,
          mapLong : this.long,
          mapZoom : this.zoom,
          mapPoints: this.mapPoints
        })
        if (z == -1)
        {
          const view = this.olMap.getView()  //'EPSG:4326' this.olMap.
          view.setCenter(this.convertCoordinates(parseFloat(this.long),parseFloat(this.lat)))
        }
        //view.setZoom(z)
      },
      styleFunction(val, isSelect)
      {
        var file = (isSelect) ? '/map-select.png' : '/map-pin.png'
        var scale = (isSelect) ? 0.75 : 0.5
        var z = (isSelect) ? 100 : 1
        if (val == "xxx")
        {
          isSelect = false
          file = '/map-target.png'
          val = ""
          scale = 0.75
        }
        
        
        var z = (isSelect) ? 100 : 1
        
        console.log("Are we here ???" + file) 
        return [
          new Style({
            image: new  Icon({      
              anchor: [0.5,1.2],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction', 
              scale : scale,
              src: file
            }),
            zIndex:z,
            text: new Text({
            /*  anchor: [0.5,1.0],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction', */
              font: '16px Calibri,sans-serif',
              /*stroke: new Stroke({ color: '#ff0', width: 6 }),*/
              backgroundFill: new Fill({color: '#ff0'}),
              fill: new Fill({ color: '#000' }),
              text: val
            })  
          })
        ]
        
        
      }
    },
  expose: ['updatePos', 'addPin', 'clearPins','setMarker', 'unsetMarker', 'getCurrentLongLat','showMapHtml' ]
  }
</script>
<style>
  #mymap {
  height: 0%;
}

  .ol-theme_common_box_two {
    margin-bottom: 0px;
  }
   
  .ol-theme_two_box_img{
    position: relative;
    overflow: hidden;
    aspect-ratio: 8/5;

}


.ol-img_hover {
    overflow: hidden;
}

.ol-img_hover img {
    transition: var(--transition);
    width: 100%;
}

.ol-img_hover:hover img {
    transform: scale(1.2);
}



.ol-theme_two_box_content {
    background: #fff;
    padding: 0px 5px;
    z-index: 1;
}
 

.ol-theme_two_box_content h4 {
    color: var(--black-color);
    text-align: center;
}

.ol-theme_two_box_content h4:hover {
    color: var(--hover-color);
}

.ol-popup {
  position: absolute;
  overflow: hidden;
  background-color: white;
  /*--webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));*/
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 0px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: 50%;
  min-width: 280px;
  max-width: 330px;
  transform: translateX(-50%);
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
 /*  border: solid transparent;
  content: " ";*/
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
</style>
